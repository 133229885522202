import { NavLink } from "react-router-dom";
import Separator from "../SlideForm/Separator/Separator";

interface ITab {
    
    name: string;
    path: string;
    isLink: boolean;

}

interface IProps {
    items: ITab[];
}

const Tabs = ( props: IProps ) => {

    const items = props.items;
    const jsx   = [];

    for( let item of items ){

        if( item.isLink )
            jsx.push( <NavLink className='text-gray-500 font-semibold' key={ 'tab-'+item.name } to={ item.path } >{ item.name }</NavLink> );
        else
            jsx.push( <span className='text-indigo-600 font-semibold' key={ 'tab-'+item.name }>{ item.name }</span> );

    }

    return (

        <>
            <div className="flex gap-6 px-4 pb-4 sm:px-6 lg:px-8">
                { jsx }
            </div>
            <Separator />
        </>
    
    )

}

export default Tabs;