import launch from '../../../../Images/launch.png';

interface IProps {
    text: string;
}

const EmptyAI = ( props: IProps ) => {

    const text = props.text;

    return(
        <div className="px-4 py-6 sm:px-6 lg:px-8">
            <div className="flex flex-col justify-center items-center py-40 gap-4">
                <img className="h-24" alt="Create elements" src={ launch } />
                <h2 className="text-center text-xl md:text-xl text-gray-500">{ text }</h2>
            </div>
        </div>
    );

}

export default EmptyAI;