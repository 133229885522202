import React from 'react';

export interface IContext {

    token: string | null;
    project: string | null;
    firstName: string | null;
    isOwner: boolean | null;
    isTrial: boolean | null;
    trialEnd: Date | null;
    monthEnd: Date | null;
    operators: number | null;
    isOwnerAccount: boolean | null;

}

const contextValues: IContext = {

    token: null,
    project: null,
    firstName: null,
    isOwner: null,
    isTrial: null,
    trialEnd: null,
    monthEnd: null,
    operators: null,
    isOwnerAccount: null

}

const Context = React.createContext( contextValues );

export default Context