import '../../Styles/loader.css';

interface IProps {

    open?: boolean;
    fullScreen?: boolean;
    
}

const Loader = ( props: IProps ) => {

    const open       = props.open;
    const fullScreen = props.fullScreen;

    let className = '';

    if( open )
        className = 'fixed bg-white/50 z-50 top-0 left-0 w-full';
    else
        className = 'fixed bg-white/50 z-50 hidden top-0 w-full';

    if( fullScreen )
        return(

            <div className={ className }>
                <div className='flex flex-col h-screen items-center justify-center'>
                    <div className="politicsoft-loader"></div>
                </div>
            </div>

        );
    else
        return(
            <div className='flex flex-col items-center justify-center'>
                <div className="politicsoft-loader"></div>
            </div>
        );

}

export default Loader;