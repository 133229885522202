interface IProps {
    text: string;
}

const Badge = ( props: IProps ) => {

    const text = props.text;

    return(

        <span className="border-gray-400 border inline-flex items-center rounded-md bg-gray-50 px-4 py-2 text-gray-900 text-md">
            { text }
        </span>

    );

}

export default Badge;