import ReactDOM from 'react-dom/client';
import './Styles/Tailwind.css';
import App from './Components/App/App';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <App />
);
