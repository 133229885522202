import { useContext } from "react";
import TableFeature, { IFeatureSettings, ITableSettings } from "../../../Components/Features/TableFeature/TableFeature";
import Context from "../../../Components/Context/Context";
import { IState, IValidationField } from "../../../CustomHooks/useForm";
import { createMomentDate } from "../../../Utils/dates";
import { IFormFields } from "../../../Utils/forms";
import { IFieldView } from "../../../Components/Forms/FormView";

const Events = () => {

    // Context
    const contextData = useContext( Context );

    // Feature settings
    const featureSettings: IFeatureSettings = {

        featureHelp: `Diseñado para que organices tus eventos políticos con precisión. 
            Visualiza tus actividades en un calendario intuitivo en la vista "Calendario" o examina una lista detallada para un control completo en la vista "Listado". 
            Desde mítines hasta encuentros, gestiona cada aspecto de tu agenda política con facilidad y destaca en cada evento.
        `,
        featureName: 'Eventos',
        createButton: 'Crear evento',
        slideTitle: 'Información de Evento'

    };

    // Initial State
    const initialState = { 

        id: { value: '', isValid: true },
        title: { value: '', isValid: true },
        description: { value: '', isValid: true },
        address: { value: '', isValid: true },
        date: { value: '', isValid: true },
        campaign_profile: { value: '-1', isValid: true },
        campaign_profile_label: { value: '', isValid: true },
        category: { value: '-1', isValid: true },
        category_label: { value: '', isValid: true },
        is_completed: { value: '-1', isValid: true },
        created_at: { value: '', isValid: true },
        created_by: { value: '', isValid: true },
        updated_at: { value: '', isValid: true },
        updated_by: { value: '', isValid: true }

    };

    // Form configuration
    const formFields: IFormFields[] = [
        {
            fields: [
                {
                    type: 'text',
                    name: 'title',
                    label: 'Titulo',
                    placeholder: 'Titulo',
                    formElement: 'input',
                    options: null
                }
            ]
        },
        {
            fields: [
                {
                    type: 'text',
                    name: 'description',
                    label: 'Descripción',
                    placeholder: 'Descripción',
                    formElement: 'textarea',
                    options: null
                }
            ]
        },
        {
            fields: [
                {
                    type: 'text',
                    name: 'address',
                    label: 'Dirección',
                    placeholder: 'Dirección',
                    formElement: 'input',
                    options: null
                },
            ]
        },
        {
            fields:[ 
                {
                    type: 'date',
                    name: 'date',
                    label: 'Fecha',
                    placeholder: 'Fecha',
                    formElement: 'input',
                    options: null
                },
            ]
        }
    ];

    // Table settings
    const tableSettings: ITableSettings = {

        headers: [ 

            { label: 'Titulo', sortable: true, key: 'title' },
            { label: 'Fecha', sortable: true, key: 'date' },
            { label: 'Categoría', sortable: false, key: 'category' },
            { label: 'Perfil', sortable: false, key: 'campaign_profile' },
            { label: 'Estado', sortable: false, key: 'is_completed' }

        ],
        keys: [

            { key: 'title', type: 'text' },
            { key: 'date', type: 'date' },
            { key: 'category', type: 'category' },
            { key: 'campaign_profile', type: 'profile' },
            { key: 'is_completed', type: 'status' }
            
        ]

    };

    // Funtions
    const getRequiredFields = () => {

        const requiredFields: IValidationField[] = [

            { label: 'Titulo', name: 'title', type: 'text' },
            { label: 'Descripción', name: 'description', type: 'text' },
            { label: 'Dirección', name: 'address', type: 'text' },
            { label: 'Fecha', name: 'date', type: 'text' },
            { label: 'Estado del objetivo', name: 'is_completed', type: 'dropdown' }

        ];

        return requiredFields;

    }

    const getFieldView = ( values: IState ) => {

        const formView: IFieldView[] = [ 

            {
               title: 'Titulo',
               content: values.title.value,
               type: 'text',
               separator: true
            },
            {
               title: 'Descripción',
               content: values.description.value,
               type: 'text',
               separator: true
            },
            {
               title: 'Dirección',
               content: values.address.value, 
               type: 'text',
               separator: true
            },
            {
               title: 'Fecha',
               content: values.date.value,
               type: 'date',
               separator: true
            },
            {
               title: 'Perfil',
               content: values.campaign_profile_label.value,
               type: 'text',
               separator: true
            },
            {
               title: 'Categoría',
               content: values.category_label.value,
               type: 'badge',
               separator: true
            },
            {
               title: 'Estado',
               content: values.is_completed.value,
               type: 'status',
               separator: true
            },
            {
               title: 'Creado',
               content: values.created_at.value,
               type: 'date',
               separator: true
            },
            {
               title: 'Creado por',
               content: values.created_by.value,
               type: 'text',
               separator: true
            },
            {
               title: 'Actualizado',
               content: values.updated_at.value,
               type: 'date',
               separator: true
            },
            {
               title: 'Actualizado por',
               content: values.updated_by.value,
               type: 'text',
               separator: false
            }
   
       ];

       return formView;

    }

    const saveHandler = ( values: IState ) => {

        const date          = createMomentDate( values.date.value, 'YYYY-MM-DD' );
        const isCompleted   = ( values.is_completed.value === 'true' ) ? true : false;
        const categoryValue = ( values.category.value === '-1' || values.category.value === '' ) ? null : values.category.value;
        const profileValue  = ( values.campaign_profile.value === '-1' || values.campaign_profile.value === '' ) ? null : values.campaign_profile.value;

        const newElement = {

            title: values.title.value,
            description: values.description.value,
            date: date,
            address: values.address.value,
            campaign_profile: profileValue,
            category: categoryValue,
            is_completed: isCompleted,
            account: contextData.project,
            is_active: true

        }

        return newElement;

    }

    const openShowHandler = ( copyValues: IState, data: any ) => {

        copyValues.id.value                     = data.id;
        copyValues.title.value                  = data.title;
        copyValues.description.value            = data.description;
        copyValues.date.value                   = data.date;
        copyValues.address.value                = data.address;
        copyValues.campaign_profile.value       = ( data.campaign_profile !== null ) ? data.campaign_profile.id : '-1';
        copyValues.category.value               = ( data.category !== null ) ? data.category.id : '-1';
        copyValues.category_label.value         = ( data.category !== null ) ? data.category.title : '-';
        copyValues.campaign_profile_label.value = ( data.campaign_profile !== null ) ? `${data.campaign_profile.first_name} ${data.campaign_profile.last_name}` : '-';
        copyValues.is_completed.value           = String( data.is_completed );
        copyValues.created_at.value             = data.created_at;
        copyValues.created_by.value             = `${data.created_by.first_name} ${data.created_by.last_name}`;
        copyValues.updated_at.value             = data.updated_at;
        copyValues.updated_by.value             = `${data.updated_by.first_name} ${data.updated_by.last_name}`;

        return copyValues;

    }

    const updateHandler = ( values: IState ) => {

        const date          = createMomentDate( values.date.value, 'YYYY-MM-DD' );
        const isCompleted   = ( values.is_completed.value === 'true' ) ? true : false;
        const categoryValue = ( values.category.value === '-1' || values.category.value === '' ) ? null : values.category.value;
        const profileValue  = ( values.campaign_profile.value === '-1' || values.campaign_profile.value === '' ) ? null : values.campaign_profile.value;

        const updateElement = {

            title: values.title.value,
            description: values.description.value,
            date: date,
            address: values.address.value,
            campaign_profile: profileValue,
            category: categoryValue,
            is_completed: isCompleted,
            account: contextData.project,
            is_active: true

        }

        return updateElement;

    }

    // Return the Table Feature Module
    return (

        <TableFeature
            featureSettings={ featureSettings }
            editAction={ true }
            elements={ null }
            endPoints={
                {
                    get: `/events/${contextData.project}/`,
                    retrieve: `/events/${contextData.project}/`,
                    post: `/events/${contextData.project}/`,
                    put: `/events/${contextData.project}/`,
                    delete: `/events/${contextData.project}/`
                }
            }
            initialState={ initialState }
            getRequiredFields={ getRequiredFields }
            getFieldView={ getFieldView }
            saveHanlder={ saveHandler }
            openShowHandler={ openShowHandler }
            updateHandler={ updateHandler }
            formFields={ formFields }
            settings={{
                campaign_profile: true,
                category: true,
                is_completed: true
            }}
            table={ tableSettings }
        />
        
    );

};

export default Events;