import { createdAtFormatDate } from "../../../../Utils/dates";
import Badge from "../../../Badge/Badge";
import Status from "../../../Status/Status";

export type TFieldType = 'text' | 'badge' | 'status' | 'date' | 'status' | 'link';

interface IProps {

    title: string;
    content: string;
    type: TFieldType;

} 

const Field = ( props: IProps ) => {

    const title   = props.title;
    const content = props.content;
    const type    = props.type;

    let renderValue;

    if( type === 'text' ){
        renderValue = content;
    }
    else if( type === 'badge' ){
        renderValue = <Badge text={ content } />
    }
    else if( type === 'date' ){
        const formattedDate = createdAtFormatDate( content );
        renderValue         = formattedDate;
    }
    else if( type === 'status' ){
        let isComplete = ( content === 'true' ) ? true : false;
        renderValue = <Status status={ Boolean( isComplete ) } />
    }
    else if( type === 'link' ){
        renderValue = <a className="px-4 py-3 text-center rounded-md bg-indigo-600 text-sm text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" href={ content } target="_blank" rel="noreferrer"> Abrir </a>
    }

    return(

        <div className="flex flex-col gap-1 md:flex-row">
            <div className="text-gray-500 text-left text-md w-full md:w-3/12">{ title }</div>
            <div className="text-left text-md w-full md:w-9/12">
                { renderValue }
            </div>
        </div>
        
    );
    
}

export default Field;