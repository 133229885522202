import { useContext } from "react";
import Context from "../../../Components/Context/Context";
import TableFeature, { IFeatureSettings, ITableSettings } from "../../../Components/Features/TableFeature/TableFeature";
import { IFormFields } from "../../../Utils/forms";
import { IState, IValidationField } from "../../../CustomHooks/useForm";
import { IFieldView } from "../../../Components/Forms/FormView";

const Dropbox = () => {

    // Context
    const contextData = useContext( Context );

    // Feature settings
    const featureSettings: IFeatureSettings = {

        featureHelp: `Libera el potencial de almacenamiento en la nube con el módulo de Archivos. 
            Guarda, organiza y accede a tus documentos políticos desde cualquier lugar. 
            Ya sean imágenes, audios, videos o documentos, optimiza tu espacio y 
            colabora eficientemente gracias a nuestro almacenamiento seguro en la nube.
        `,
        featureName: 'Archivos',
        createButton: 'Crear archivo',
        slideTitle: 'Información de Archivo'

    };

    // Initial State
    const initialState = { 
        
        id: { value: '', isValid: true, file: null },
        file: { value: '', isValid: true, file: null },
        name: { value: '', isValid: true, file: null },
        size: { value: '', isValid: true, file: null },
        link: { value: '', isValid: true, file: null }

    };

    // Form configuration
    const formFields: IFormFields[] = [
        {
            fields: [
                {
                    type: 'file',
                    name: 'file',
                    label: 'Archivo',
                    placeholder: 'Archivo',
                    formElement: 'input',
                    options: null
                }
            ]
        }
    ];

    // Table settings
    const tableSettings: ITableSettings = {

        headers: [ 

            { label: 'Nombre', sortable: false, key: 'name' },
            { label: 'Peso', sortable: false, key: 'size' }

        ],
        keys: [
            { key: 'name', type: 'text' },
            { key: 'size', type: 'text' }
        ]

    };

    // Funtions
    const getRequiredFields = () => {

        const requiredFields: IValidationField[] = [

            { label: 'Archivo', name: 'file', type: 'file' },

        ];

        return requiredFields;

    }

    const getFieldView = ( values: IState ) => {

        const formView: IFieldView[] = [ 

            {
                title: 'Nombre',
                content: values.name.value,
                type: 'text',
                separator: true
            },
            {
               title: 'Archivo',
               content: values.link.value,
               type: 'link',
               separator: true
            }
   
       ];

       return formView;

    }

    const saveHandler = ( values: IState ) => {
        
        const formData = new FormData();
        formData.append( 'file', values.file.file as any );

        return formData;

    }

    const openShowHandler = ( copyValues: IState, data: any ) => {

        copyValues.id.value   = data.id;
        copyValues.name.value = data.name;
        copyValues.link.value = data.link;
        return copyValues;

    }

    const updateHandler = ( values: IState ) => {
        return false;
    }

    // Return the Table Feature Module
    return (

        <TableFeature
            featureSettings={ featureSettings }
            editAction={ false }
            elements={ null }
            endPoints={
                {
                    get: `/account_dropbox/${contextData.project}/`,
                    retrieve: `/account_dropbox/retrieve/${contextData.project}/`,
                    post: `/account_dropbox/upload_file/${contextData.project}`,
                    put: 'dropbox',
                    delete: `/account_dropbox/delete/${contextData.project}/`
                }
            }
            initialState={ initialState }
            getRequiredFields={ getRequiredFields }
            getFieldView={ getFieldView }
            saveHanlder={ saveHandler }
            openShowHandler={ openShowHandler }
            updateHandler={ updateHandler }
            formFields={ formFields }
            settings={{
                campaign_profile: false,
                category: false,
                is_completed: false
            }}
            table={ tableSettings }
        />
        
    );

}

export default Dropbox;