import { ChangeEventHandler } from "react";

interface IProps {

    name: string;
    label: string;
    placeholder: string;
    state: string;
    isValid: boolean;
    formChangeHandler: ChangeEventHandler< HTMLTextAreaElement >;

}

const TextArea = ( props: IProps ) => {

    const name              = props.name;
    const label             = props.label;
    const placeholder       = props.placeholder;
    const state             = props.state;
    const isValid           = props.isValid;
    const formChangeHandler = props.formChangeHandler;

    let className = 'block w-full rounded-md mb-3 border-gray-200 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6';

    if( !isValid )
        className = 'block w-full rounded-md mb-3 border-red-400 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6';

    return (

        <div>
            <label
                htmlFor={ name }
                className="block text-sm leading-6 text-gray-900"
            >
                { label }
            </label>
            <div className="">
                <textarea
                    id={ name }
                    name={ name }
                    className={ className }
                    placeholder={ placeholder }
                    value={ state }
                    onChange={ formChangeHandler }
                    rows={ 5 }
                />
            </div>
        </div>

    );

}

export default TextArea;
