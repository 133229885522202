
interface IProps {
    children: React.ReactNode;
}

const FullWidthContainer = ( props: IProps ) => {

    return (

        <div className="mx-auto max-w-7xl">
            { props.children }
        </div>
        
    );
    
}

export default FullWidthContainer;