import axios from 'axios';

interface IHttp {

    get: ( path: string, token?: string ) => Promise<any>;
    post: ( path: string, body: any, token?: string ) => Promise<any>;
    upload: ( path: string, body: any, token?: string ) => Promise<any>;
    put: ( path: string, body: any, token?: string ) => Promise<any>;
    patch: ( path: string, body: any, token?: string ) => Promise<any>;
    delete: ( path: string, token?: string ) => Promise<any>;

}
// Crear una instancia de axios
const apiConnectionJSON = axios.create({

    baseURL: process.env.REACT_APP_API_HOST,

});

const http: IHttp = {

    get: async ( path, token ) => {

        let response;

        if( typeof token !== 'undefined' )
            response = await apiConnectionJSON.get( path, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} } );
        else
            response = await apiConnectionJSON.get( path );

        return response.data;

    },
    post: async ( path, body, token ) => {

        let response;

        if( typeof token !== 'undefined' )
            response = await apiConnectionJSON.post( path, body, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} } );
        else
            response = await apiConnectionJSON.post( path, body );

        return response.data;

    },
    upload: async ( path, body, token ) => {

        let response;

        response = await apiConnectionJSON.post( path, body, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`} } );

        return response.data;

    },
    put: async ( path, body, token ) => {

        let response;

        if( typeof token !== 'undefined' )
            response = await apiConnectionJSON.put( path, body, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} } );
        else
            response = await apiConnectionJSON.put( path, body );

        return response.data;

    },
    patch: async ( path, body, token ) => {

        let response;

        if( typeof token !== 'undefined' )
            response = await apiConnectionJSON.patch( path, body, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} } );
        else
            response = await apiConnectionJSON.patch( path, body );

        return response.data;

    },
    delete: async ( path, token ) => {

        let response;

        if( typeof token !== 'undefined' )
            response = await apiConnectionJSON.delete( path, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} } );
        else
            response = await apiConnectionJSON.delete( path );

        return response.data;

    }

}

export default http;