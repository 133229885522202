import { useEffect, useState } from 'react';
import useAuthenticated from '../../CustomHooks/useAuthenticated';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Context, { IContext } from '../Context/Context';

import Login from '../../Screens/Login/Login';
import Register from '../../Screens/Register/Register';
import Confirm from '../../Screens/Confirm/Confirm';

import DashboardContainer from '../../Containers/DashboardContainer/DashboardContainer';
import Home from '../../Screens/Dashboard/Home/Home';
import Categories from '../../Screens/Dashboard/Categories/Categories';
import Profiles from '../../Screens/Dashboard/Profiles/Profiles';
import Objectives from '../../Screens/Dashboard/Objectives/Objectives';
import Logistic from '../../Screens/Dashboard/Logistic/Logistic';
import Events from '../../Screens/Dashboard/Events/Events';

import MyAccount from '../../Screens/Dashboard/MyAccount/MyAccount';
import Suscription from '../../Screens/Dashboard/Suscription/Suscription';

import Month from '../../Screens/Dashboard/Payments/Month';

import Loader from '../Loader/Loader';
import Operators from '../../Screens/Dashboard/Operators/Operators';
import ArtificialIntelligence from '../../Screens/Dashboard/ArtificialIntelligence/ArtificialIntelligence';
import Politicoins from '../../Screens/Dashboard/Politicoins/Politicoins';
import Credits from '../../Screens/Dashboard/Payments/Credits';
import RequestDemo from '../../Screens/RequestDemo/RequestDemo';
import Dropbox from '../../Screens/Dashboard/Dropbox/Dropbox';



const App = () => {

    const { token, login, isAuthenticated, contextData } = useAuthenticated();

    useEffect(() => {

        if( token === null ){
            localStorage.clear();
        }
        else{
            isAuthenticated();
        }

    }, [ token ]);

    if( token !== null ){
        if( contextData.token === null )
            return <Loader fullScreen={ true } open={ true } />
        else
            return(
        
                <Router>
                    <Context.Provider value={ contextData }>
                        <Routes>
                            <Route path="/" element={ <DashboardContainer menuActive={0}><Home /></DashboardContainer> } />
                            <Route path="/calendario-eventos" element={ <DashboardContainer menuActive={1}><Events /></DashboardContainer> } />
                            <Route path="/tareas-logisticas" element={ <DashboardContainer menuActive={2}><Logistic /></DashboardContainer> } />
                            <Route path="/categorias" element={ <DashboardContainer menuActive={3}><Categories /></DashboardContainer> } />
                            <Route path="/perfiles" element={ <DashboardContainer menuActive={4}><Profiles /></DashboardContainer> } />
                            <Route path="/objetivos" element={ <DashboardContainer menuActive={5}><Objectives /></DashboardContainer> } />
                            <Route path="/archivos" element={ <DashboardContainer menuActive={6}><Dropbox /></DashboardContainer> } />
                            <Route path="/inteligencia-artificial" element={ <DashboardContainer menuActive={7}><ArtificialIntelligence /></DashboardContainer> } />
                            <Route path="/operadores" element={ <DashboardContainer menuActive={null}><Operators /></DashboardContainer> } />
                            <Route path="/mi-cuenta" element={ <DashboardContainer menuActive={null}><MyAccount /></DashboardContainer> } />
                            <Route path="/suscripcion" element={ <DashboardContainer menuActive={null}><Suscription /></DashboardContainer> } />
                            <Route path="/politicoins" element={ <DashboardContainer menuActive={null}><Politicoins /></DashboardContainer> } />

                            <Route path="/confirmacion-pago" element={ <Month /> } />
                            <Route path="/confirmacion-pago-creditos" element={ <Credits /> } />
                            
                        </Routes>
                    </Context.Provider>
                </Router>

            )

    }
    else{

        return(

            <Router>
                <Routes>
                    <Route path="/" element={ <Login auth={ login } /> } />
                    <Route path="/demo" element={ <RequestDemo /> } />
                    <Route path="/registrate" element={ <Register /> } />
                    <Route path="/confirm/:userId" element={ <Confirm /> } />
                </Routes>
            </Router>
            
        );

    }

}

export default App;
