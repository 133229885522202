import { useContext, useEffect, useState } from "react";
import Context from "../../../Components/Context/Context";
import Notification from "../../../Components/Notification/Notification";
import useNotification from "../../../CustomHooks/useNotification";
import useHttp from "../../../CustomHooks/useHttp";
import useForm, { IValidationField } from "../../../CustomHooks/useForm";
import { clearState } from "../../../Utils/forms";
import Loader from "../../../Components/Loader/Loader";
import Modal from "../../../Components/Modal/Modal";
import SlideForm from "../../../Components/Dashboard/SlideForm/SlideForm";
import FullWidthContainer from "../../../Containers/FullWidthContainer/FullWidthContainer";
import Input from "../../../Components/Forms/Input";
import Errors from "../../../Components/Errors/Errors";
import Title from "../../../Components/Dashboard/Title/Title";
import Empty from "../../../Components/Empty/Empty";
import Tables from "../../../Components/Tables/Tables";
import { createMomentDate } from "../../../Utils/dates";
import Actions from "../../../Components/Dashboard/SlideForm/Actions/Actions";
import Field from "../../../Components/Dashboard/SlideForm/Field/Field";
import Separator from "../../../Components/Dashboard/SlideForm/Separator/Separator";

interface IUser {
    
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    is_active: boolean;

}

const Operators = () => {

    // Context
    const contextData = useContext( Context );
    // States required for the UI
    const [ openFullScreenLoader, setOpenFullScreenLoader ] = useState< boolean >( false );
    const [ openCreateSlideForm, setOpenCreateSlideForm ]   = useState< boolean >( false );
    const [ openShowSlideForm, setOpenShowSlideForm ]       = useState< boolean >( false );
    const [ openEditSlideForm, setOpenEditSlideForm ]       = useState< boolean >( false );
    const [ openHelpSlide , setOpenHelpSlide ]              = useState< boolean >( false );
    const [ disableSave, setDisableSave ]                   = useState< boolean >( false );
    const [ disableEdit, setDisableEdit ]                   = useState< boolean >( false );
    const [ currentElementId, setCurrentElementId ]         = useState< string | null >( null );
    const [ openModal, setOpenModal ]                       = useState< boolean >( false );
    const [ currentPage, setCurrentPage ]                   = useState< number >( 1 );
    const [ totalItems, setTotalItems ]                     = useState< number >( 0 );
    const [ search, setSearch ]                             = useState< string >( '' );
    const { 
        notificationState,
        setNotificationState,
        openNotification,
        setOpenNotification,
        showNotification
    } = useNotification({
        title: '',
        message: '',
    });
    // FetchAPI customHook
    const { fetchAPI } = useHttp();
    // Table of results state
    const [ elements, setElements ] = useState< IUser[] | null >( null );
    // Forms state customHook
    const { values, formChangeHandler, formValidationHandler, errors, setErrors, setValues } = useForm({

        id: {
            value: '',
            isValid: true
        },
        first_name: {
            value: '',
            isValid: true
        },
        last_name: {
            value: '',
            isValid: true
        },
        email: {
            value: '',
            isValid: true
        }

    });

    // Help section
    const helpText = `Podrás registrar cuentas para los miembros del staff que desempeñan roles cruciales en tu partido político. 
    Su creación está directamente asociada a la cuenta del Espacio Político respectivo, asegurando una colaboración cohesiva. 
    La confirmación del registro se realiza mediante un enlace de confirmación enviado por correo electrónico, garantizando la seguridad y autenticidad del proceso.`;
    
    // Open the slide show form to show the help
    const openHelpSlideHandler = () => {
        setOpenHelpSlide( true );
    }

    // Get the table results.
    const getTableResults = async ( token: string, limit?:string, offset?: string, searchValue?: string ) => {

        try{

            const accountId = contextData.project;

            let resource = `/users/account/${accountId}/all`;
            // If the limit and offset are strings, then the request is paginated.
            if( typeof limit === 'string' && typeof offset ==='string' ){
                resource = `/users/account/${accountId}/all?limit=${limit}&offset=${offset}`;
                // If the searchValue is string, then the request is filtered.
                if( typeof searchValue === 'string' )
                    resource += `&search=${searchValue}`;
            }
            // Get the results.
            const response = await fetchAPI( resource, 'get', null, token );
            // If the response is successful, then set the elements and the total items.
            if( response.status === 200 ){

                setElements( response.data.elements );
                setTotalItems( response.data.total );

            }

        }
        catch( err ){
            console.log('Error');
        }

    }

    // Search handler
    const searchHandler = async ( searchValue: string ) => {

        if( searchValue === '' || searchValue === undefined || searchValue === null ){

            setCurrentPage( 1 );
            setSearch( '' );

            setOpenFullScreenLoader( true );

            if( contextData.token !== null )
                await getTableResults( contextData.token, '25', '0' );

            setOpenFullScreenLoader( false );

        }
        else{

            const page = currentPage;
            let limit  = 25;
            let offset = 0;
    
            if( page > 1 )
                offset = ( page - 1 ) * limit;
    
            if( contextData.token !== null ){
                
                setSearch( searchValue );
                setOpenFullScreenLoader( true );
                await getTableResults( contextData.token, limit.toString(), offset.toString(), searchValue );
                setOpenFullScreenLoader( false );

            }

        }

    }

    // Change page handler
    const changePageHandler = async ( page: number ) => {

        let limit  = 25;
        let offset = 0;

        if( page > 1 )
            offset = ( page - 1 ) * limit;

        setCurrentPage( page );

        if( contextData.token !== null ){

            setOpenFullScreenLoader( true );

            if( search !== '' && search !== undefined && search !== null )
                await getTableResults( contextData.token, limit.toString(), offset.toString(), search );
            else
                await getTableResults( contextData.token, limit.toString(), offset.toString() );

            setOpenFullScreenLoader( false );
        
        }

    }

    // Get the table results when the component is mounted.
    useEffect(() => {

        if( contextData.token !== null && elements === null )
            getTableResults( contextData.token );

    }, [ contextData, elements ]);

    // Open the slide show form to create a new item.
    const openCreateSlideFormHandler = () => {

        clearFieldsHandler();
        setOpenCreateSlideForm( true );

    }

    // Open the slide show form to edit an item.
    const OpenEditSlideFormHandler = () => {
        
        setOpenShowSlideForm( false );
        setOpenEditSlideForm( true );

    }

    // Open the slide show form to delete an item.
    const openDeleteSlideFormHandler = () => {
        
        setCurrentElementId( values.id.value );
        setOpenShowSlideForm( false );
        setOpenModal( true );

    }

    // Retrieve from the API an item for open the slide show form to show an item.
    const openShowSlideFormHandler = async( id: string ) => {
        
        try{
            
            if( contextData.token !== null ){
                
                clearFieldsHandler();
                setOpenFullScreenLoader( true );

                const accountId = contextData.project;

                const response = await fetchAPI( `/users/account-user/${accountId}/${id}`, 'get', null, contextData.token );

                
                if( response.status === 200 ){
                    
                    const copyValues = { ...values };

                    copyValues.id.value         = response.data.id;
                    copyValues.first_name.value = response.data.first_name;
                    copyValues.last_name.value  = response.data.last_name;
                    copyValues.email.value      = response.data.email;

                    setValues( copyValues );
                    setOpenFullScreenLoader( false );
                    setOpenShowSlideForm( true );
                
                }
            }

        }
        catch( err ){
            console.log('Error');
            setOpenFullScreenLoader( false );
        }

    }

    // Clear the form state.
    const clearFieldsHandler = () => {

        let copyValues = { ...values };
        copyValues     = clearState( copyValues );

        setDisableSave( false );
        setDisableEdit( false );
        setErrors( [] );
        setValues( copyValues );

    }

    // Save the new item.
    const saveButtonHandler = async () => {

        setDisableSave( true );

        const requiredFields: IValidationField[] = [

            {
                label: 'Nombre',
                name: 'first_name',
                type: 'text'
            },
            {
                label: 'Apellidos',
                name: 'last_name',
                type: 'text'
            },
            {
                label: 'Correo electrónico',
                name: 'email',
                type: 'email'
            }

        ];

        // Validate the required fields and the token.
        if( formValidationHandler( requiredFields ) && contextData.token ){

            try{

                setOpenFullScreenLoader( true );

                const accountId = contextData.project;

                const newElement = {

                    first_name: values.first_name.value,
                    last_name: values.last_name.value,
                    username: values.email.value,
                    email: values.email.value,
                    password: 'mypassword',
                    account: accountId

                }
                
                const response = await fetchAPI( `/users/create/account/${accountId}/user`, 'post', newElement, contextData?.token );

                if( response.status === 201 ){
                    
                    setOpenCreateSlideForm( false );
                    clearFieldsHandler();
                    setNotificationState({
                        title: 'Guardado exitosamente',
                        message: 'Puedes visualizarlo en el listado'
                    });
                    showNotification();
                    setElements( null );
                    setOpenFullScreenLoader( false );

                }

            }
            catch( err: any ){
                
                if( err.response.data.email ){

                    setDisableSave( false );
                    setOpenFullScreenLoader( false );
                    setErrors( [ 'El email ya esta en uso.' ] );

                }
                else{

                    setDisableSave( false );
                    setOpenFullScreenLoader( false );
                    setErrors( [ 'Ocurrio un error intenta nuevamente.' ] );

                }
            
            }

        }
        else
            setDisableSave( false );

    }

    // Update the item.
    const updateButtonHandler = async () => {

        setDisableEdit( true );

        const requiredFields: IValidationField[] = [

            {
                label: 'Nombre',
                name: 'first_name',
                type: 'text'
            },
            {
                label: 'Apellidos',
                name: 'last_name',
                type: 'text'
            }

        ];

        if( formValidationHandler( requiredFields ) && contextData.token ){

            try{

                const accountId = contextData.project;

                const updateElement = {
                
                    first_name: values.first_name.value,
                    last_name: values.last_name.value
                
                }

                setOpenFullScreenLoader( true );

                const response = await fetchAPI( `/users/account-user/${accountId}/${values.id.value}`, 'patch', updateElement, contextData?.token );

                if( response.status === 200 ){

                    setOpenEditSlideForm( false );
                    clearFieldsHandler();
                    setNotificationState({
                        title: 'Editado exitosamente',
                        message: 'Puedes visualizarlo en el listado'
                    });
                    showNotification();
                    setElements( null );
                    setOpenFullScreenLoader( false );

                }

            }
            catch( err ){
                setDisableSave( false );
                setErrors( [ 'Ocurrio un error, intenta nuevamente.' ] );
            }

        }
        else
            setDisableEdit( false );

    }

    // Delete the item.
    const deleteButtonHandler = async () => {

        try{

            if( contextData.token !== null ){

                const accountId = contextData.project;

                setOpenFullScreenLoader( true );

                const response = await fetchAPI( `/users/account-user/${accountId}/${currentElementId}`, 'delete', null, contextData.token );
                
                if( response.status === 200 ){

                    setOpenModal( false );
                    setCurrentElementId( null );
                    setNotificationState({
                        title: 'Eliminado exitosamente',
                        message: 'El elemento se elimino correctamente.'
                    });
                    showNotification();
                    setElements( null );
                    setOpenFullScreenLoader( false );

                }

            }

        }
        catch( err ){
            console.log('Error');
        }

    }
    
    if( elements === null )
        return <Loader />
    else
        return (

            <FullWidthContainer>

                <Title search={false} title='Operadores' buttonContent='Crear operador' buttonAction={ openCreateSlideFormHandler } searchHandler={ searchHandler } helpHandler={ openHelpSlideHandler } />
                {
                    ( elements.length === 0 )
                    ?
                        <Empty />
                    :
                        <>
                            <Tables 
                                headers={ 
                                    [ 

                                        { label: 'Nombre', sortable: false, key: 'name' },
                                        { label: 'Apellidos', sortable: false, key: 'name' },
                                        { label: 'Correo electrónico', sortable: false, key: 'name' }

                                    ] 
                                }
                                keys={[
                                    {
                                        key: 'first_name',
                                        type: 'text'
                                    },
                                    {
                                        key: 'last_name',
                                        type: 'text'
                                    },
                                    {
                                        key: 'email',
                                        type: 'text'
                                    }
                                ]}
                                items={ elements }
                                totalItems={ totalItems }
                                currentPage={ currentPage }
                                changePage={ changePageHandler }
                                detailsHandler={ openShowSlideFormHandler }
                                orderHandler={()=>{}}
                            />
                        </>
                }
                {/* Create Element */}
                <SlideForm title='Información de Operador' open={ openCreateSlideForm } setOpen={ setOpenCreateSlideForm } save={ saveButtonHandler } disableSave={ disableSave } clearFields={ clearFieldsHandler }>
                    <Errors errors={errors} title={'Errores'} />
                    <Input
                        type='text'
                        name='first_name'
                        label='Nombre'
                        placeholder='Nombre'
                        state={ values.first_name.value }
                        isValid={ values.first_name.isValid }
                        formChangeHandler={( evt ) => formChangeHandler( 'first_name', evt.target.value ) }
                    />
                    <Input
                        type='text'
                        name='last_name'
                        label='Apellidos'
                        placeholder='Apellidos'
                        state={ values.last_name.value }
                        isValid={ values.last_name.isValid }
                        formChangeHandler={( evt ) => formChangeHandler( 'last_name', evt.target.value ) }
                    />
                    <Input
                        type='email'
                        name='email'
                        label='Correo electrónico'
                        placeholder='Correo electrónico'
                        state={ values.email.value }
                        isValid={ values.email.isValid }
                        formChangeHandler={( evt ) => formChangeHandler( 'email', evt.target.value ) }
                    />
                </SlideForm>
                {/* Show Element */}
                <SlideForm title='Información de operadores' open={ openShowSlideForm } setOpen={ setOpenShowSlideForm } save={ null } disableSave={ null } clearFields={ null }>
                    <>
                        <Actions editAction={ true } openEdit={ OpenEditSlideFormHandler } openDelete={ openDeleteSlideFormHandler } />
                        <Field 
                            title='Nombre'
                            content={ values.first_name.value }
                            type='text'
                        />
                        <Separator />
                        <Field 
                            title='Apellidos'
                            content={ values.last_name.value }
                            type='text'
                        />
                        <Separator />
                        <Field 
                            title='Correo electrónico'
                            content={ values.email.value }
                            type='text'
                        />
                    </>
                </SlideForm>
                {/* Edit Element */}
                <SlideForm title='Información de operadores' open={ openEditSlideForm } setOpen={ setOpenEditSlideForm } save={ updateButtonHandler } disableSave={ disableEdit } clearFields={ clearFieldsHandler }>
                    <Errors errors={errors} title={'Errores'} />
                    <Input
                        type='text'
                        name='first_name'
                        label='Nombre'
                        placeholder='Nombre'
                        state={ values.first_name.value }
                        isValid={ values.first_name.isValid }
                        formChangeHandler={( evt ) => formChangeHandler( 'first_name', evt.target.value ) }
                    />
                    <Input
                        type='text'
                        name='last_name'
                        label='Apellidos'
                        placeholder='Apellidos'
                        state={ values.last_name.value }
                        isValid={ values.last_name.isValid }
                        formChangeHandler={( evt ) => formChangeHandler( 'last_name', evt.target.value ) }
                    />
                </SlideForm>
                {/* Help Element */}
                <SlideForm title='¿Para qué sirve?' open={ openHelpSlide } setOpen={ setOpenHelpSlide } save={ null } disableSave={ null } clearFields={ null }>
                    { helpText }
                </SlideForm>
                <Notification 

                    title={ notificationState.title }
                    message={ notificationState.message }
                    open={openNotification}
                    setOpen={ setOpenNotification }

                />
                <Modal 
                    title='Eliminar Elemento'
                    content='¿Seguro que quieres eliminar este elemento? No volverás recuperarlo una vez eliminado.'
                    buttonText='Eliminar'
                    cancelButton={ true }
                    icon='delete'
                    open={ openModal }
                    setOpen={ setOpenModal }
                    buttonAction={ deleteButtonHandler }
                />
                <Loader fullScreen={ true } open={ openFullScreenLoader } />
            </FullWidthContainer>

        );

};

export default Operators;
