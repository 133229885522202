import { MouseEventHandler } from 'react';
import useForm from '../../../CustomHooks/useForm';
import Search from '../../Forms/Search';
import help from '../../../Images/help.png';

interface IProps {

    title: string;
    buttonContent: string;
    buttonAction: MouseEventHandler<HTMLButtonElement>;
    searchHandler: Function;
    helpHandler: Function;
    search?: boolean;

}

const Title = ( props: IProps ) => {

    const buttonContent = props.buttonContent;
    const buttonAction  = props.buttonAction;
    const searchHandler = props.searchHandler;
    const helpHandler   = props.helpHandler;

    const { values, formChangeHandler, formValidationHandler, errors, setErrors } = useForm({
        
        search: {
            value: '',
            isValid: true
        }

    });

    let search = props.search;

    if( typeof search === 'undefined' )
        search = true;

    return(

        <div className='px-4 py-6 sm:px-6 lg:px-8'>
            <div className='flex flex-col sm:justify-between sm:items-center sm:flex-row gap-2'>
                <div className='flex gap-2 items-center'>
                    <h2 className='text-3xl lg:text-4xl font-semibold'>{ props.title }</h2>
                    <img
                        className='cursor-pointer h-7 w-7'
                        src={ help }
                        onClick={ () => helpHandler() }
                    />
                </div>
                    <Search
                        state={ values.search.value }
                        isValid={ values.search.isValid }
                        formChangeHandler={ ( evt ) => formChangeHandler('search', evt.target.value ) }
                        buttonContent={ buttonContent }
                        buttonAction={ buttonAction }
                        searchHandler={ searchHandler }
                        search={ search }
                    />
            </div>
        </div>

    );

}

export default Title;