import { useRef, useState } from "react";
import Errors from "../../Components/Errors/Errors";
import Form, { IFields } from "../../Components/Forms/Form";
import Loader from "../../Components/Loader/Loader";
import FullWidthWithPaddingContainer from "../../Containers/FullWidthWithPaddingContainer/FullWidthWithPaddingContainer";
import useForm, { IValidationField } from "../../CustomHooks/useForm";
import logo from '../../Images/logo_pic_small.png';
import launch from '../../Images/launch.png';
import { IFormFields, createForm } from "../../Utils/forms";
import useHttp from "../../CustomHooks/useHttp";

const RequestDemo = () => {

    // States
    const [ openFullScreenLoader, setOpenFullScreenLoader ] = useState< boolean >( false );
    const formRef        = useRef< HTMLDivElement >( null );
    const successMessage = useRef< HTMLDivElement >( null );

    // FetchAPI customHook
    const { fetchAPI } = useHttp();

    // Custom hooks - initial state
    const { values, formChangeHandler, formValidationHandler, errors, setErrors } = useForm({
        
        first_name: { value: '', isValid: true },
        last_name: { value: '', isValid: true },
        email: { value: '', isValid: true },
        notes: { value: '', isValid: true }

    });

    // Form configuration
    const formFields: IFormFields[] = [
        {
            fields: [
                {
                    type: 'text',
                    name: 'first_name',
                    label: 'Nombre',
                    placeholder: 'Nombre',
                    formElement: 'input',
                    options: null
                },
                {
                    type: 'text',
                    name: 'last_name',
                    label: 'Apellidos',
                    placeholder: 'Apellidos',
                    formElement: 'input',
                    options: null
                }
            ]
        },
        {
            fields: [
                {
                    type: 'text',
                    name: 'email',
                    label: 'Correo electrónico',
                    placeholder: 'Correo electrónico',
                    formElement: 'input',
                    options: null
                },
            ]
        },
        {
            fields: [
                {
                    type: 'text',
                    name: 'notes',
                    label: 'Especificaciones',
                    placeholder: `Indícanos si eres un candidato, parte de un equipo político o un gestor de campaña. También, si tienes preferencias o necesidades específicas para la demo, este es el lugar para compartirlo.`,
                    formElement: 'textarea',
                    options: null
                },
            ]
        }
    ];

    // Create the form
    const form: IFields[] = createForm( formFields, values, formChangeHandler );

    // Funtions
    const saveButtonHandler = async () => {
        
        setOpenFullScreenLoader( true );

        const requiredFields: IValidationField[] = [

            { label: 'Nombre', name: 'first_name', type: 'text' },
            { label: 'Apellidos', name: 'last_name', type: 'text' },
            { label: 'Correo electrónico', name: 'email', type: 'text' },
            { label: 'Especificaciones', name: 'notes', type: 'text' },

        ];

        if( formValidationHandler( requiredFields )){

            try{

                const newRequestDemo = {

                    first_name: values.first_name.value,
                    last_name: values.last_name.value,
                    email: values.email.value,
                    notes: values.notes.value,

                };

                await fetchAPI( '/register_code/', 'post', newRequestDemo );

                formRef.current?.classList.add( 'hidden' );
                successMessage.current?.classList.remove( 'hidden' );
                setOpenFullScreenLoader( false );

            }
            catch( err ){

                setOpenFullScreenLoader( false );
                setErrors( [ 'Ocurrio un error, intentelo nuevamente.' ] );

            }

        }
        else
            setOpenFullScreenLoader( false );

    }


    return (

        <>
            <div className="flex px-4 py-8 border-b">
                <img src={ logo } />
            </div>
            <FullWidthWithPaddingContainer>
                <div className="w-full m-auto pb-8 md:w-10/12 lg:w-8/12" ref={ formRef }>
                    <h2 className='font-semibold mt-8 text-2xl'>
                        Experimenta Politicsoft en Acción.
                    </h2>
                    <p className='text-gray-500 mb-5 text-sm'>
                        Inicia tu transformación digital política. Registra tus detalles a continuación para acceder a una demo de Politicsoft.
                    </p>
                    <Errors errors={errors} title={'Errores'} />
                    <Form
                        customKey="request-demo"
                        form={ form }
                    />
                    <button
                        onClick={ saveButtonHandler }
                        type='button'
                        className='text-center w-full rounded-md bg-indigo-600 p-2 text-sm text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    >
                        Continuar
                    </button>
                </div>
                <div className="w-full m-auto pb-8 md:w-10/12 lg:w-8/12 hidden" ref={ successMessage }>
                    <img className="mt-10 mx-auto"src={ launch } />
                    <h2 className='font-semibold mt-10 mb-2 text-2xl'>
                        ¡Gracias por solicitar una demostración de Politicsoft!
                    </h2>
                    <p className="text-gray-500 text-justify">
                        Hemos recibido tus datos con éxito. 
                        Pronto, uno de nuestros especialistas se pondrá en contacto contigo para coordinar una presentación 
                        online personalizada y atender todas tus especificaciones.
                        Estamos emocionados de mostrarte cómo nuestra plataforma puede potenciar tu estrategia política. 
                        Mientras tanto, si tienes alguna pregunta o requieres información adicional, 
                        no dudes en escribirnos a <span className="text-indigo-500">contacto@politicsoft.mx </span><br></br><br></br>
                        <b>¡Prepárate para llevar tu campaña al siguiente nivel!</b>
                    </p>
                </div>
            </FullWidthWithPaddingContainer>
            <Loader fullScreen={ true } open={ openFullScreenLoader } />
        </>

    );

}

export default RequestDemo;