import { useContext, useEffect, useState } from 'react';
import LauncherIcon from '../../../Images/launch.png';
import Loader from '../../../Components/Loader/Loader';
import useHttp from '../../../CustomHooks/useHttp';
import Context from '../../../Components/Context/Context';

const Credits = () => {

    // Context
    const contextData = useContext( Context );
    // FetchAPI customHook
    const { fetchAPI } = useHttp();

    // States required for the UI
    const [ openFullScreenLoader, setOpenFullScreenLoader ] = useState< boolean >( true );
    const [ payment, setPayment ] = useState< boolean >( false );

    useEffect(() => {

        const makePayment = async() => {

            if( contextData.token !== null ){
                
                const paymentsResponse = await fetchAPI( `/artificial_intelligence/activate_credits_request`, 'patch', null, contextData.token );
                setPayment( true );
                setOpenFullScreenLoader( false );

                setTimeout(function() {
                    window.location.href = '/';
                }, 5000);

            }
            else{
                setOpenFullScreenLoader( false );
            }
            
        }

        if( payment === false && contextData.project !== null )
            makePayment();
            
    },[]);

    return(

        ( payment === false ) 
            ?
                <Loader fullScreen={ true } open={ openFullScreenLoader } />
            :
                <div className="flex flex-col items-center justify-center h-screen">
                    <img className='pb-4' width="140" src={ LauncherIcon } />
                    <p className="font-semibold text-2xl text-gray-500 text-center">Politicoins activados</p>
                    <p className='text-gray-500 text-center'>Pago realizado exitosamente, espera un momento te redireccionaremos a tu panel...</p>
                </div>
    );

}

export default Credits;