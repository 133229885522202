import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import FullWidthContainer from "../../../Containers/FullWidthContainer/FullWidthContainer";
import Loader from "../../../Components/Loader/Loader";
import useHttp from "../../../CustomHooks/useHttp";
import Context from "../../../Components/Context/Context";
import Tables from "../../../Components/Tables/Tables";

interface IStats {
    name: string;
    value: string;
}

function classNames( ...classes: any ) {
    return classes.filter( Boolean ).join(' ');
}

const Home = () => {

    const contextData = useContext( Context );

    const { fetchAPI } = useHttp();
    const [ stats, setStats ] = useState< IStats[] >( [] );
    const [ logistics, setLogistics ] = useState< any[] >( [] );

    useEffect(() => {

        try{

            const accountDetails = async ( token: string ) => {

                try{

                    const project = contextData.project;

                    const response = await fetchAPI( `/accounts/${project}/details/`, 'get', null, token );
                    
                    if( response.status === 200 ){

                        setStats([

                            {
                                name: 'Objetivos completados',
                                value: `${response.data.objectives.completed}/${response.data.objectives.total}`
                            },
                            {
                                name: 'Eventos completados',
                                value: `${response.data.events.completed}/${response.data.events.total}`
                            },
                            {
                                name: 'Tareas registradas',
                                value: `${response.data.logistics.total}`
                            },
                            {
                                name: 'Almacenamiento (Archivos)',
                                value: `${response.data.cloud_storage.total_size}/${response.data.cloud_storage.account_size}`
                            }

                        ]);

                        if( response.data.logistics.records.length > 0 )
                            setLogistics( response.data.logistics.records );

                    }

                }
                catch( err ){
                    console.log( "Error" );
                }

            }

            if( stats.length === 0 && contextData.token !== null )
                accountDetails( contextData.token );

        }
        catch( err ){

        }

    }, [ contextData ]);

    if( stats.length === 0 )
        return( 
            <FullWidthContainer>
                <Loader />
            </FullWidthContainer>
        )
    else{

        return (
            <FullWidthContainer>
                <div className="px-4 py-6 sm:px-6 lg:px-8">
                    <h2 className="text-2xl lg:text-4xl font-semibold">Resumen</h2>
                </div>
                <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
                    <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
                        {stats.map((stat, statIdx) => (
                            <div
                                key={stat.name}
                                className={classNames(
                                    statIdx % 2 === 1
                                        ? "sm:border-l"
                                        : statIdx === 2
                                        ? "lg:border-l"
                                        : "",
                                    "flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8"
                                )}
                            >
                                <dt className="text-sm font-medium leading-6 text-gray-500">
                                    {stat.name}
                                </dt>
                                <dd className="w-full flex-none text-2xl lg:text-3xl font-medium leading-10 tracking-tight text-gray-900">
                                    {stat.value}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <div className="px-4 py-6 sm:px-6 lg:px-8">
                    <div className="flex flex-col sm:flex-row justify-between">
                        <div className="font-semibold text-lg">Tareas recientes</div>
                        <div>
                            <NavLink className='text-indigo-600 font-semibold text-md' to='/tareas-logisticas'>Ver todos</NavLink>
                        </div>
                    </div>
                </div>
            </FullWidthContainer>
        );

    }
};

export default Home;
