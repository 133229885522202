import { IFieldSettings, IFields, TFormElement } from "../Components/Forms/Form";
import { TInput } from "../Components/Forms/Input";
import { IState } from "../CustomHooks/useForm";

interface IFormFieldSettings {

    type: TInput;
    name: string;
    label: string;
    placeholder: string;
    formElement: TFormElement;
    options: any[] | null;

}
export interface IFormFields {
    fields: IFormFieldSettings[]
}

export const clearState = ( state: IState ) => {

    for( let key in state ){

        state[ key ].value   = '';
        state[ key ].isValid = true;

    }

    return state;

}

export const createForm = ( form: IFormFields[], state: any, formChangeHandler: Function, fileChangeHandler?: Function ): IFields[] => {

    const xxx = [];

    for( let formField of form ){

        let fields = formField.fields;
        let yy     = [];

        for( let field of fields ){

            let functionHandler = formChangeHandler;
            
            if( fileChangeHandler !== undefined )
                functionHandler = ( field.type !== 'file' ) ? formChangeHandler : fileChangeHandler;

            const newField = {
                ...field,
                state: state,
                formChangeHandler: functionHandler
            }

            yy.push( newField );

        }

        xxx.push({ fields: yy });

    }

    return xxx;

};