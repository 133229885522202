import {
    CheckIcon
} from '@heroicons/react/20/solid';
import { useContext, useEffect, useState } from "react";
import Tabs from "../../../Components/Dashboard/Tabs/Tabs";
import FullWidthContainer from "../../../Containers/FullWidthContainer/FullWidthContainer";
import useHttp from "../../../CustomHooks/useHttp";
import Context from "../../../Components/Context/Context";
import Loader from "../../../Components/Loader/Loader";

interface IPlan {

    id: string;
    title: string;
    description: string;
    price: string;
    credits: number;
    stripe_link: string;

}

interface ITab {

    name: string;
    path: string;
    isLink: boolean;

}

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

const Politicoins = () => {

    // Context
    const contextData = useContext( Context );
    // FetchAPI customHook
    const { fetchAPI } = useHttp();

    // States required for the UI
    const [ openFullScreenLoader, setOpenFullScreenLoader ] = useState< boolean >( false );
    const [ politicoins, setPoliticoins ]                   = useState< number | null >( null );
    const [ tabs, setTabs ]                                 = useState< ITab[] >( [] );
    const [ plans, setPlans ]                               = useState< IPlan[] >([
        {
            id: '1',
            title: 'Politicsoft Influencia: 100 Politicoins',
            description: 'Bonificación de 5 Politicoins.',
            price: '199',
            credits: 105,
            stripe_link: 'https://buy.stripe.com/00geXW2CjfPadMsaEL'
        },
        {
            id: '2',
            title: 'Politicsoft Impacto: 200 Politicoins',
            description: 'Bonificación de 15 Politicoins.',
            price: '299',
            credits: 215,
            stripe_link: 'https://buy.stripe.com/3cs4ji6SzgTe0ZGaEM'
        },
        {
            id: '3',
            title: 'Politicsoft Estratega: 300 Politicoins',
            description: 'Bonificación de 25 Politicoins.',
            price: '599',
            credits: 325,
            stripe_link: 'https://buy.stripe.com/bIY0326SzbyU4bS7sB'
        },
        {
            id: '4',
            title: 'Politicsoft Visionario: 400 Politicoins',
            description: 'Bonificación de 35 Politicoins.',
            price: '799',
            credits: 435,
            stripe_link: 'https://buy.stripe.com/28o7vugt9auQ7o4dR0'
        },
        {
            id: '5',
            title: 'Politicsoft Líder: 500 Politicoins',
            description: 'Bonificación de 45 Politicoins.',
            price: '999',
            credits: 545,
            stripe_link: 'https://buy.stripe.com/bIY1761yfgTecIo6oz'
        },
        {
            id: '6',
            title: 'Politicsoft Poder: 600 Politicoins',
            description: 'Bonificación de 55 Politicoins.',
            price: '1199',
            credits: 655,
            stripe_link: 'https://buy.stripe.com/9AQ1761yfgTe6k06oA'
        }
    
    ]);

    const [ features, setFeatures ] = useState< string[] >([

        'Genera discursos a medida.',
        'Genera publicaciones optimizadas para redes sociales.',
        'Genera propuestas políticas.',
        'Son acumulables.',
        'No caducan.',

    ]);
    

    useEffect(()=>{

        const getPoliticoins = async () => {

            try{
    
                if( contextData?.token !== null ){
    
                    const accountId = contextData.project;
    
                    const response = await fetchAPI( `/artificial_intelligence/get_credits/${accountId}`, 'get', null, contextData?.token );

                    const tabsOptions = [
                        {
                            name: "Mi perfil",
                            path: '/mi-cuenta',
                            isLink: true
                        }
                    ];

                    if( contextData.isOwnerAccount )
                        tabsOptions.push(
                            {
                                name: 'Mi suscripción',
                                path: '/suscripcion',
                                isLink: true
                            },
                            {
                                name: 'Politicoins',
                                path: '/politicoins',
                                isLink: false
                            }
                        );

                    setTabs( tabsOptions );

                    setPoliticoins( response.credits );
                    setOpenFullScreenLoader( false );
    
                }
    
            }
            catch( err: any ){
                alert( 'Ocurrio un error recarga la pagina, por favor.' );
            }
    
        }

        if( politicoins === null && contextData !== null ){

            setOpenFullScreenLoader( true );
            getPoliticoins();

        }

    },[ politicoins ]);

    const createPaymentHandler = async ( credits: number, stripeLink: string ) => {

        setOpenFullScreenLoader( true );

        try{

            if( contextData.token !== null ){

                const request = {
                    credits: credits
                }
                
                const paymentsResponse = await fetchAPI( `/artificial_intelligence/create_credits_request`, 'post', request, contextData.token );
                setOpenFullScreenLoader( false );

                window.location.href = stripeLink;

            }

        }
        catch( err ){
            setOpenFullScreenLoader( false );
        }

    }
        
    return (

        <FullWidthContainer>
            <Tabs items={ tabs } />
            <div className="px-4 py-6 sm:px-6 lg:px-8">
                <h2 className="font-bold text-3xl">Politicoins: {politicoins}</h2>
                <h3 className="mb-8 text-gray-500">No tienen caducidad mientras exista una suscripción activa.</h3>
                <div className='w-full'>
                {
                    ( plans === null )
                        ? 
                            null
                        : 
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2'>
                            {
                                plans.map( ( plan, index ) => {
                                    return(
                                        <div key={ 'index-' + index } className="py-4 ring-1 ring-gray-200 rounded-3xl p-8 xl:p-10">
                                            <div className="flex flex-col">
                                                <p className="font-semibold text-2xl">{plan.title}</p>
                                                <p className="text-indigo-600">{ plan.description }</p>
                                                
                                                <p className='mt-6 flex items-baseline gap-x-1'>
                                                    <span className='text-4xl font-bold tracking-tight text-gray-900'>
                                                        ${plan.price}
                                                    </span>
                                                    <span className='text-sm font-semibold leading-6 text-gray-600'>
                                                        pesos + IVA
                                                    </span>
                                                </p>
                                                    <span
                                                        aria-describedby={plan.id}
                                                        className={classNames(
                                                            'bg-indigo-600 text-white shadow-sm hover:bg-indigo-600 cursor-pointer',
                                                            'mt-6 block rounded-md py-2 px-3 text-center text-sm leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                                        )}
                                                        onClick={() => createPaymentHandler( plan.credits, plan.stripe_link )}
                                                    >
                                                        Adquirir
                                                    </span>
                                                <ul
                                                    role='list'
                                                    className='mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10'
                                                >
                                                    {features.map((feature: any) => (
                                                        <li
                                                            key={feature}
                                                            className='flex gap-x-3'
                                                        >
                                                            <CheckIcon
                                                                className='h-6 w-5 flex-none text-indigo-600'
                                                                aria-hidden='true'
                                                            />
                                                            {feature}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
            </div>
        </div>
        <Loader fullScreen={ true } open={ openFullScreenLoader } />
        </FullWidthContainer>

    )

}

export default Politicoins;