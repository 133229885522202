import error from '../../Images/error.png';

interface IProps {
    title: string;
    errors: string[];
} 

const Errors = ( props: IProps ) => {

    const title  = props.title;
    const errors = props.errors;

    if( errors.length === 0 ) 
        return null
    else
        return(

            <div className='bg-red-50  mx-auto text-sm p-4 mb-2 rounded w-full'>
                <div className='flex items-center gap-2'>
                    <img className='h-5 w-auto' alt="Error icon" src={ error } />
                    <div className='text-md mb-1 text-red-800'>{title}</div>
                </div>
                <ul className='list-disc list-inside mt-2 text-red-700'>
                    {
                        errors.map( ( error, index ) => {
                            return <li key={ 'error-'+index } className='ml-4'>{ error }</li>
                        })
                    }
                </ul>
            </div>

        );

}

export default Errors;