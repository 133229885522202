import { Fragment, MouseEventHandler } from "react";
import { Dialog, Transition } from "@headlessui/react";
import email_icon from '../../Images/email_modal.png';
import delete_icon from '../../Images/delete_element.png';
import guard_icon from '../../Images/guard.png';

type TIcons = 'email' | 'delete' | 'guard';

interface IProps {

    title: string;
    content: string;
    buttonText: string;
    cancelButton?: boolean;
    icon: TIcons;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    buttonAction: MouseEventHandler<HTMLButtonElement>;

}

const Modal = ( props: IProps ) => {

    const title = props.title;
    const content = props.content;
    const buttonText = props.buttonText;
    const cancelButton = props.cancelButton;
    const open = props.open;
    const setOpen = props.setOpen;
    const buttonAction = props.buttonAction;

    let icon;

    if( props.icon === 'email' )
        icon = email_icon;
    else if ( props.icon === 'delete' )
        icon = delete_icon;
    else if ( props.icon === 'guard' )
        icon = guard_icon;

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mx-auto flex items-center justify-center rounded-full">
                                        <img
                                            className="h-12 w-12"
                                            aria-hidden="true"
                                            src={icon}
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900"
                                        >
                                            {title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {content}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    ( cancelButton === false )
                                    ? 
                                        <div className="mt-5 sm:mt-6">
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                onClick={buttonAction}
                                            >
                                                {buttonText}
                                            </button>
                                        </div>
                                    :
                                        <div className="flex flex-col gap-2 mt-5 sm:flex-row sm:mt-6">
                                            <button
                                                type="button"
                                                className="justify-center rounded bg-indigo-600 px-4 py-2 text-white w-full sm:w-3/6"
                                                onClick={buttonAction}
                                            >
                                                {buttonText}
                                            </button>
                                            <button
                                                type="button" 
                                                className="border border-gray-300 rounded px-4 py-2 w-full sm:w-3/6"
                                                onClick={ () => setOpen(false) }
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default Modal;