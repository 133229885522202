import { NavLink, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loader from '../../Components/Loader/Loader';
import useHttp from '../../CustomHooks/useHttp';

const Confirm = () => {

    const { userId } = useParams<{ userId: string }>();
    const [ openLoader, setOpenLoader ] = useState<boolean>(true);
    const [ message, setMessage ] = useState<string>('Confirmando cuenta...');
    const { fetchAPI } = useHttp();

    useEffect(() => {

        const confirm = async () => {

            try {
                const response = await fetchAPI( `/users/confirm/${userId}`, 'get' );

                if( response.data.is_email_confirmed ){
                    setMessage( 'Correo electrónico confirmado correctamente.' );
                    setOpenLoader(false);
                }

            } 
            catch ( error: any ) {
                setMessage( 'Correo electrónico confirmado correctamente.' );
                setOpenLoader(false);
            }

        }

        confirm();

    }, [message]);

    return(

        <div className='flex flex-col h-screen items-center justify-center'>
            <h2 className='text-xl'>{message}</h2>
            <NavLink className='mt-1 text-md underline' to='/'>Iniciar Sesión</NavLink>
            <Loader fullScreen={true}  open={openLoader} />
        </div>

    );

}

export default Confirm;