import { ChangeEventHandler } from "react";

export interface ISelectOptions {
    id: string;
    title: string;
}

interface IProps {

    name: string;
    state: string;
    label: string;
    isValid: boolean;
    options: ISelectOptions[] | null
    formChangeHandler: ChangeEventHandler< HTMLSelectElement >;

}

const Select = ( props: IProps ) => {

    const name              = props.name;
    const state             = props.state;
    const isValid           = props.isValid;
    const label             = props.label;
    const options           = ( props.options !== null ) ? props.options : [];
    const formChangeHandler = props.formChangeHandler;

    let className = 'block w-full rounded-md mb-3 py-2 border-gray-200 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6';

    if( !isValid )
        className = 'block w-full rounded-md mb-3py-2 border-red-400 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6';

    return (

        <div>
            <label
                htmlFor="email"
                className="block text-sm leading-6 text-gray-900"
            >
                { label }
            </label>
            <div className="">
                <select
                    id={ name }
                    name={ name }
                    className={ className }
                    value={ state }
                    onChange={ formChangeHandler }
                >
                    <option value='-1'>{ label }</option>
                    {
                        options.map( ( option ) => {
                            return <option key={ option.id } value={ option.id }>{ option.title }</option>
                        })
                    }
                </select>
            </div>
        </div>

    );

}

export default Select;
