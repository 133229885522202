import { useContext } from "react";
import TableFeature, { IFeatureSettings, ITableSettings } from "../../../Components/Features/TableFeature/TableFeature";
import Context from "../../../Components/Context/Context";
import { IState, IValidationField } from "../../../CustomHooks/useForm";
import { IFormFields } from "../../../Utils/forms";
import { IFieldView } from "../../../Components/Forms/FormView";

const Profiles = () => {

    // Context
    const contextData = useContext( Context );

    // Feature settings
    const featureSettings: IFeatureSettings = {

        featureHelp: `Optimiza la gestión de tu estrategia política creando Perfiles de Campaña que funcionarán como etiquetas 
            personalizadas para asignar a personas específicas en eventos, tareas, objetivos, y más. Estas etiquetas te permiten organizar y 
            registrar de manera eficiente cada elemento de tu estrategia, facilitando el seguimiento y la logística.
        `,
        featureName: 'Perfiles',
        createButton: 'Crear perfil',
        slideTitle: 'Información de perfiles'

    };

    // Initial State
    const initialState = {

        id: { value: '', isValid: true },
        first_name: { value: '', isValid: true },
        last_name: { value: '', isValid: true },
        email: { value: '', isValid: true },
        mobile_phone: { value: '', isValid: true },
        cargo: { value: '', isValid: true },
        created_at: { value: '', isValid: true },
        created_by: { value: '', isValid: true },
        updated_at: { value: '', isValid: true },
        updated_by: { value: '', isValid: true }

    };

    // Form configuration
    const formFields: IFormFields[] = [
        {
            fields: [
                {
                    type: 'text',
                    name: 'first_name',
                    label: 'Nombre',
                    placeholder: 'Nombre',
                    formElement: 'input',
                    options: null
                }
            ]
        },
        {
            fields: [
                {
                    type: 'text',
                    name: 'last_name',
                    label: 'Apellidos',
                    placeholder: 'Apellidos',
                    formElement: 'input',
                    options: null
                }
            ]
        },
        {
            fields: [
                {
                    type: 'email',
                    name: 'email',
                    label: 'Correo electrónico',
                    placeholder: 'Correo electrónico',
                    formElement: 'input',
                    options: null
                }
            ]
        },
        {
            fields: [
                {
                    type: 'text',
                    name: 'mobile_phone',
                    label: 'Teléfono Móvil',
                    placeholder: 'Teléfono Móvil',
                    formElement: 'input',
                    options: null
                }
            ]
        },
        {
            fields: [
                {
                    type: 'text',
                    name: 'cargo',
                    label: 'Cargo',
                    placeholder: 'Cargo',
                    formElement: 'input',
                    options: null
                }
            ]
        },
    ];

    // Table settings
    
    const tableSettings: ITableSettings = {

        headers: [ 

            { label: 'Nombre', sortable: true, key: 'first_name' },
            { label: 'Apellidos', sortable: true, key: 'last_name' },
            { label: 'Email', sortable: true, key: 'email' },
            { label: 'Cargo', sortable: true, key: 'cargo' }

        ],
        keys: [
            { key: 'first_name', type: 'text' },
            { key: 'last_name', type: 'text' },
            { key: 'email', type: 'text' },
            { key: 'cargo', type: 'text' }
        ]
    };

    // Funtions
    const getRequiredFields = () => {

        const requiredFields: IValidationField[] = [

            { label: 'Nombre', name: 'first_name', type: 'text' },
            { label: 'Apellidos', name: 'last_name', type: 'text' }

        ];

        return requiredFields;

    }

    const getFieldView = ( values: IState ) => {

        const formView: IFieldView[] = [ 

            {
               title: 'Nombre',
               content: values.first_name.value,
               type: 'text',
               separator: true
            },
            {
               title: 'Apellidos',
               content: values.last_name.value,
               type: 'text',
               separator: true
            },
            {
               title: 'Correo electrónico',
               content: values.email.value, 
               type: 'text',
               separator: true
            },
            {
               title: 'Teléfono Móvil',
               content: values.mobile_phone.value,
               type: 'text',
               separator: true
            },
            {
                title: 'Cargo',
                content: values.cargo.value,
                type: 'text',
                separator: true
            },
            {
               title: 'Creado',
               content: values.created_at.value,
               type: 'date',
               separator: true
            },
            {
               title: 'Creado por',
               content: values.created_by.value,
               type: 'text',
               separator: true
            },
            {
               title: 'Actualizado',
               content: values.updated_at.value,
               type: 'date',
               separator: true
            },
            {
               title: 'Actualizado por',
               content: values.updated_by.value,
               type: 'text',
               separator: false
            }
   
       ];

       return formView;

    }

    const saveHandler = ( values: IState ) => {

        const newElement = {
                    
            first_name: values.first_name.value,
            last_name: values.last_name.value,
            email: values.email.value,
            mobile_phone: values.mobile_phone.value,
            cargo: values.cargo.value,
            account: contextData.project,
            is_active: true

        }

        return newElement;

    }

    const openShowHandler = ( copyValues: IState, data: any ) => {

        copyValues.id.value           = data.id;
        copyValues.first_name.value   = data.first_name;
        copyValues.last_name.value    = data.last_name;
        copyValues.email.value        = data.email;
        copyValues.mobile_phone.value = data.mobile_phone;
        copyValues.cargo.value        = data.cargo;
        copyValues.created_at.value   = data.created_at;
        copyValues.created_by.value   = `${data.created_by.first_name} ${data.created_by.last_name}`;
        copyValues.updated_at.value   = data.updated_at;
        copyValues.updated_by.value   = `${data.updated_by.first_name} ${data.updated_by.last_name}`;

        return copyValues;

    }

    const updateHandler = ( values: IState ) => {

        const updateElement = {

            first_name: values.first_name.value,
            last_name: values.last_name.value,
            email: values.email.value,
            mobile_phone: values.mobile_phone.value,
            cargo: values.cargo.value,
            account: contextData.project,
            is_active: true

        }

        return updateElement;

    }

    // Return the Table Feature Module
    return (

        <TableFeature
            featureSettings={ featureSettings }
            editAction={ true }
            elements={ null }
            endPoints={
                {
                    get: `/campaign_profile/${contextData.project}/`,
                    retrieve: `/campaign_profile/${contextData.project}/`,
                    post: `/campaign_profile/${contextData.project}/`,
                    put: `/campaign_profile/${contextData.project}/`,
                    delete: `/campaign_profile/${contextData.project}/`
                }
            }
            initialState={ initialState }
            getRequiredFields={ getRequiredFields }
            getFieldView={ getFieldView }
            saveHanlder={ saveHandler }
            openShowHandler={ openShowHandler }
            updateHandler={ updateHandler }
            formFields={ formFields }
            settings={{
                campaign_profile: false,
                category: false,
                is_completed: false
            }}
            table={ tableSettings }
        />
        
    );

};

export default Profiles;