import { useContext } from "react";
import TableFeature, { IFeatureSettings, ITableSettings } from "../../../Components/Features/TableFeature/TableFeature";
import Context from "../../../Components/Context/Context";
import { IState, IValidationField } from "../../../CustomHooks/useForm";
import { createMomentDate } from "../../../Utils/dates";
import { IFormFields } from "../../../Utils/forms";
import { IFieldView } from "../../../Components/Forms/FormView";

const Logistic = () => {

    // Context
    const contextData = useContext( Context );

    // Feature settings
    const featureSettings: IFeatureSettings = {

        featureHelp: `Organiza y supervisa detalles cruciales para tus actividades, desde direcciones hasta contactos, cantidades y costos. 
            Simplifica la gestión de logística para tus eventos políticos, asegurando que cada tarea se lleve a cabo sin contratiempos. 
            Descubre cómo la planificación estratégica puede impulsar tu éxito político con este potente módulo.
        `,
        featureName: 'Tareas Logísticas',
        createButton: 'Crear tarea',
        slideTitle: 'Información de Tarea'

    };

    // Initial State
    const initialState = { 

        id: { value: '', isValid: true },
        title: { value: '', isValid: true },
        description: { value: '', isValid: true },
        address: { value: '', isValid: true },
        contact_phone: { value: '', isValid: true },
        quantity: { value: '', isValid: true },
        cost: { value: '', isValid: true },
        date: { value: '', isValid: true },
        notes: { value: '', isValid: true },
        campaign_profile: { value: '-1', isValid: true },
        campaign_profile_label: { value: '', isValid: true },
        category: { value: '-1', isValid: true },
        category_label: { value: '', isValid: true },
        created_at: { value: '', isValid: true },
        created_by: { value: '', isValid: true },
        updated_at: { value: '', isValid: true },
        updated_by: { value: '', isValid: true }

    };

    // Form configuration
    const formFields: IFormFields[] = [
        {
            fields: [
                {
                    type: 'text',
                    name: 'title',
                    label: 'Titulo',
                    placeholder: 'Titulo',
                    formElement: 'input',
                    options: null
                }
            ]
        },
        {
            fields: [
                {
                    type: 'text',
                    name: 'description',
                    label: 'Descripción',
                    placeholder: 'Descripción',
                    formElement: 'textarea',
                    options: null
                }
            ]
        },
        {
            fields: [
                {
                    type: 'text',
                    name: 'address',
                    label: 'Dirección',
                    placeholder: 'Dirección',
                    formElement: 'input',
                    options: null
                }
            ]   
        },
        {
            fields: [
                {
                    type: 'text',
                    name: 'contact_phone',
                    label: 'Teléfono de contacto',
                    placeholder: 'Teléfono de contacto',
                    formElement: 'input',
                    options: null
                },
                {
                    type: 'text',
                    name: 'quantity',
                    label: 'Cantidad',
                    placeholder: 'Cantidad',
                    formElement: 'input',
                    options: null
                },
            ]
        },
        {
            fields: [
                {
                    type: 'text',
                    name: 'cost',
                    label: 'Cost',
                    placeholder: 'Cost',
                    formElement: 'input',
                    options: null
                },
                {
                    type: 'date',
                    name: 'date',
                    label: 'Fecha',
                    placeholder: 'Date',
                    formElement: 'input',
                    options: null
                },
            ]
        },
        {
            fields: [
                {
                    type: 'text',
                    name: 'notes',
                    label: 'Notas',
                    placeholder: 'Notas de tarea',
                    formElement: 'textarea',
                    options: null
                }
            ]   
        },
    ];

    // Table settings

    const tableSettings: ITableSettings = {

        headers: [ 

            { label: 'Titulo', sortable: true, key: 'title' },
            { label: 'Fecha', sortable: true, key: 'date' },
            { label: 'Categoría', sortable: false, key: 'category' },
            { label: 'Perfil', sortable: false, key: 'campaign_profile' },
            { label: 'Contacto', sortable: false, key: 'contact_phone' },
            
        ],
        keys: [
            { key: 'title', type: 'text' },
            { key: 'date', type: 'date' },
            { key: 'category', type: 'category' },
            { key: 'campaign_profile', type: 'profile' },
            { key: 'contact_phone', type: 'text' }
        ]

    };

    // Funtions
    const getRequiredFields = () => {

        const requiredFields: IValidationField[] = [

            { label: 'Titulo', name: 'title', type: 'text' },
            { label: 'Descripción', name: 'description', type: 'text' }

        ];

        return requiredFields;

    }

    const getFieldView = ( values: IState ) => {

        const formView: IFieldView[] = [ 

            {
               title: 'Titulo',
               content: values.title.value,
               type: 'text',
               separator: true
            },
            {
               title: 'Descripción',
               content: values.description.value,
               type: 'text',
               separator: true
            },
            {
                title: 'Dirección',
                content: values.address.value,
                type: 'text',
                separator: true
            },
            {
                title: 'Teléfono de contacto',
                content: values.contact_phone.value,
                type: 'text',
                separator: true
            },
            {
                title: 'Cantidad',
                content: values.quantity.value,
                type: 'text',
                separator: true
            },
            {
                title: 'Costo',
                content: values.cost.value,
                type: 'text',
                separator: true
            },
            {
                title: 'Fecha',
                content: values.date.value,
                type: 'date',
                separator: true
            },
            {
                title: 'Notas',
                content: values.notes.value,
                type: 'text',
                separator: true
            },
            {
                title: 'Perfil',
                content: values.campaign_profile_label.value,
                type: 'text',
                separator: true
            },
            {
                title: 'Categoría',
                content: values.category_label.value,
                type: 'badge',
                separator: true
            },
            {
                title: 'Creado',
                content: values.created_at.value,
                type: 'date',
                separator: true
            },
            {
                title: 'Creado por',
                content: values.created_by.value,
                type: 'text',
                separator: true
            },
            {
                title: 'Actualizado',
                content: values.updated_at.value,
                type: 'date',
                separator: true
            },
            {
                title: 'Actualizado por',
                content: values.updated_by.value,
                type: 'text',
                separator: true
            }
       ];

       return formView;

    }

    const saveHandler = ( values: IState ) => {

        const date = ( values.date.value !== '' && values.date.value !== null ) ? createMomentDate( values.date.value, 'YYYY-MM-DD' ) : null;
        const cost = ( values.cost.value !== '' ) ? parseFloat( values.cost.value ) : null;
        const quantity = ( values.quantity.value !== '' ) ? parseInt( values.quantity.value ) : null;

        const categoryValue = ( values.category.value === '-1' || values.category.value === '' ) ? null : values.category.value;
        const profileValue  = ( values.campaign_profile.value === '-1' || values.campaign_profile.value === '' ) ? null : values.campaign_profile.value;

        const newElement = {

            title: values.title.value,
            description: values.description.value,
            address: values.address.value,
            contact_phone: values.contact_phone.value,
            quantity: quantity,
            cost: cost,
            date: date,
            notes: values.notes.value,
            campaign_profile: profileValue,
            category: categoryValue,
            account: contextData.project,
            is_active: true

        }

        return newElement;

    }

    const openShowHandler = ( copyValues: IState, data: any ) => {

        copyValues.id.value                     = data.id;
        copyValues.title.value                  = data.title;
        copyValues.description.value            = data.description;
        copyValues.address.value                = (data.address === null) ? '' : data.address;
        copyValues.contact_phone.value          = (data.contact_phone === null) ? '' : data.contact_phone;
        copyValues.quantity.value               = (data.quantity === null) ? '' : data.quantity;
        copyValues.cost.value                   = (data.cost === null) ? '' : data.cost;
        copyValues.date.value                   = (data.date === null) ? '' : data.date;
        copyValues.notes.value                  = (data.notes === null) ? '' : data.notes;
        copyValues.campaign_profile.value       = (data.campaign_profile !== null) ? data.campaign_profile.id : '-1';
        copyValues.category.value               = (data.category !== null) ? data.category.id : '-1';
        copyValues.category_label.value         = (data.category !== null) ? data.category.title : '-';
        copyValues.campaign_profile_label.value = (data.campaign_profile !== null) ? `${data.campaign_profile.first_name} ${data.campaign_profile.last_name}` : '-';
        copyValues.created_at.value             = data.created_at;
        copyValues.created_by.value             = `${data.created_by.first_name} ${data.created_by.last_name}`;
        copyValues.updated_at.value             = data.updated_at;
        copyValues.updated_by.value             = `${data.updated_by.first_name} ${data.updated_by.last_name}`;

        return copyValues;

    }

    const updateHandler = ( values: IState ) => {

        const date = ( values.date.value !== '' && values.date.value !== null ) ? createMomentDate( values.date.value, 'YYYY-MM-DD' ) : null;
        const cost = ( values.cost.value !== '' ) ? parseFloat( values.cost.value ) : null;
        const quantity = ( values.quantity.value !== '' ) ? parseInt( values.quantity.value ) : null;

        const categoryValue = ( values.category.value === '-1' || values.category.value === '' ) ? null : values.category.value;
        const profileValue  = ( values.campaign_profile.value === '-1' || values.campaign_profile.value === '' ) ? null : values.campaign_profile.value;

        const updateElement = {

            title: values.title.value,
            description: values.description.value,
            address: values.address.value,
            contact_phone: values.contact_phone.value,
            quantity: quantity,
            cost: cost,
            date: date,
            notes: values.notes.value,
            campaign_profile: profileValue,
            category: categoryValue,
            account: contextData.project,
            is_active: true

        }

        return updateElement;

    }

    // Return the Table Feature Module
    return (
        <TableFeature
            featureSettings={ featureSettings }
            editAction={ true }
            elements={ null }
            endPoints={
                {
                    get: `/logistic/${contextData.project}/`,
                    retrieve: `/logistic/${contextData.project}/`,
                    post: `/logistic/${contextData.project}/`,
                    put: `/logistic/${contextData.project}/`,
                    delete: `/logistic/${contextData.project}/`
                }
            }
            initialState={ initialState }
            getRequiredFields={ getRequiredFields }
            getFieldView={ getFieldView }
            saveHanlder={ saveHandler }
            openShowHandler={ openShowHandler }
            updateHandler={ updateHandler }
            formFields={ formFields }
            settings={{
                campaign_profile: true,
                category: true,
                is_completed: false
            }}
            table={ tableSettings }
        />
    );

};

export default Logistic;