import { useContext, useState, useEffect } from 'react';
import SlideForm from '../../../Components/Dashboard/SlideForm/SlideForm';
import Title from '../../../Components/Dashboard/Title/Title';
import FullWidthContainer from '../../../Containers/FullWidthContainer/FullWidthContainer';
import useForm, { IState, IValidationField } from '../../../CustomHooks/useForm';
import { IFormFields, clearState, createForm } from '../../../Utils/forms';
import Errors from '../../../Components/Errors/Errors';
import useHttp from '../../../CustomHooks/useHttp';
import Context from '../../../Components/Context/Context';
import Empty from '../../../Components/Empty/Empty';
import Loader from '../../../Components/Loader/Loader';
import Notification from '../../../Components/Notification/Notification';
import useNotification from '../../../CustomHooks/useNotification';
import Tables, { Ikeys } from '../../../Components/Tables/Tables';
import Actions from '../../../Components/Dashboard/SlideForm/Actions/Actions';
import Modal from '../../../Components/Modal/Modal';
import { ISelectOptions } from '../../../Components/Forms/Select';
import Form, { IFields } from '../../../Components/Forms/Form';
import FormView, { IFieldView } from '../../../Components/Forms/FormView';

export interface IFeatureSettings {
    featureHelp: string;
    featureName: string;
    createButton: string;
    slideTitle: string;
}

export interface ITableHeaders {
    label: string;
    sortable: boolean;
    key: string | null;
}

export interface ITableSettings {
    headers: ITableHeaders[];
    keys: Ikeys[]
}

interface ITableFeatureSettings {
    campaign_profile: boolean;
    category: boolean;
    is_completed: boolean;
}

interface IEndPoints {

    get: string;
    retrieve: string;
    post: string;
    put: string;
    delete: string;

}

interface IProps {
    featureSettings: IFeatureSettings;
    endPoints: IEndPoints;
    editAction: boolean;
    elements: any[] |
              null;
    initialState: IState;
    formFields: IFormFields[];
    settings: ITableFeatureSettings;
    table: ITableSettings;
    getRequiredFields: () => IValidationField[];
    getFieldView: ( values: IState ) => IFieldView[];
    saveHanlder: ( values: IState ) => Object;
    openShowHandler: ( values: IState, data: any ) => IState;
    updateHandler: ( values: IState ) => Object;

}

// Component of Objectives
const TableFeature = ( props: IProps ) => {

    // Context
    const contextData = useContext( Context );
    // Props
    const endPoints            = props.endPoints;
    const TableFeatureElements = props.elements;
    const initialState         = props.initialState;
    const getRequiredFields    = props.getRequiredFields;
    const getFieldView         = props.getFieldView;
    const saveHandler          = props.saveHanlder;
    const openShowHandler      = props.openShowHandler;
    const updateHandler        = props.updateHandler;
    const formFields           = props.formFields;
    const settings             = props.settings;
    const table                = props.table;
    const featureSettings      = props.featureSettings;
    const editAction           = props.editAction;
    // States required for the UI
    const [ openFullScreenLoader, setOpenFullScreenLoader ] = useState< boolean >( false );
    const [ openCreateSlideForm, setOpenCreateSlideForm ]   = useState< boolean >( false );
    const [ openShowSlideForm, setOpenShowSlideForm ]       = useState< boolean >( false );
    const [ openEditSlideForm, setOpenEditSlideForm ]       = useState< boolean >( false );
    const [ openHelpSlide , setOpenHelpSlide ]              = useState< boolean >( false );
    const [ disableSave, setDisableSave ]                   = useState< boolean >( false );
    const [ disableEdit, setDisableEdit ]                   = useState< boolean >( false );
    const [ currentElementId, setCurrentElementId ]         = useState< string | null >( null );
    const [ openModal, setOpenModal ]                       = useState< boolean >( false );
    const [ currentPage, setCurrentPage ]                   = useState< number >( 1 );
    const [ totalItems, setTotalItems ]                     = useState< number >( 0 );
    const [ search, setSearch ]                             = useState< string >( '' );
    const {
        notificationState,
        setNotificationState,
        openNotification,
        setOpenNotification,
        showNotification
    } = useNotification({
        title: '',
        message: '',
    });
    // FetchAPI customHook
    const { fetchAPI } = useHttp();
    // Table of results state
    const [ elements, setElements ] = useState( TableFeatureElements );
    // Dropdowns
    const [ profiles, setProfiles ]     = useState< any[] | null>( null );
    const [ categories, setCategories ] = useState< any[] | null>( null );
    // Forms state customHook
    const { values, formChangeHandler, fileChangeHandler, formValidationHandler, errors, setErrors, setValues } = useForm( initialState );

    // Is completed dropdown
    const isCompletedDropdown: ISelectOptions[] = [

        {
            id: 'false',
            title: 'Pendiente'
        },
        {
            id: 'true',
            title: 'Completado'
        }

    ];

    // Open the slide show form to show the help
    const openHelpSlideHandler = () => {
        setOpenHelpSlide( true );
    }

    // Get profiles
    const getProfiles = async ( token: string ) => {

        try{

            const resource = `/campaign_profile/${contextData.project}/?limit=${10000}&offset=${0}`;
            const response = await fetchAPI( resource, 'get', null, token );
            // If the response is successful, then set the elements and the total items.
            if( response.status === 200 ){

                let profilesForDropdown = [];

                for( let profile of response.data.elements ){

                    profilesForDropdown.push({

                        id: profile.id,
                        title: `${profile.first_name} ${profile.last_name}`

                    });

                }

                setProfiles( profilesForDropdown );

            }

        }
        catch( err ){
            console.log('Error');
        }

    }

    // Get categories
    const getCategories = async ( token: string ) => {

        try{

            const resource = `/categories/${contextData.project}/?limit=${10000}&offset=${0}`;
            const response = await fetchAPI( resource, 'get', null, token );
            // If the response is successful, then set the elements and the total items.
            if( response.status === 200 ){

                let categoriesForDropdown = [];

                for( let category of response.data.elements ){

                    categoriesForDropdown.push({

                        id: category.id,
                        title: `${category.title}`

                    });

                }

                setCategories( categoriesForDropdown );

            }

        }
        catch( err ){
            console.log('Error');
        }

    }

    // Get the table results.
    const getTableResults = async ( token: string, limit?:string, offset?: string, searchValue?: string, orderField?: string ) => {

        try{

            let resource = `${endPoints.get}`;
            // If the limit and offset are strings, then the request is paginated.
            if( typeof limit === 'string' && typeof offset ==='string' ){
                resource = `${endPoints.get}?limit=${limit}&offset=${offset}`;
                // If the searchValue is string, then the request is filtered.
                if( typeof searchValue === 'string' )
                    resource += `&search=${searchValue}`;
                if( typeof orderField === 'string' ){
                    resource += `&orderby=${orderField}`;
                }
            }
            // Get the results.
            const response = await fetchAPI( resource, 'get', null, token );
            // If the response is successful, then set the elements and the total items.
            if( response.status === 200 ){

                setElements( response.data.elements );
                setTotalItems( response.data.total );

            }

        }
        catch( err ){
            console.log('Error');
        }

    }
    // Search handler
    const searchHandler = async ( searchValue: string ) => {

        if( searchValue === '' || searchValue === undefined || searchValue === null ){

            setCurrentPage( 1 );
            setSearch( '' );

            setOpenFullScreenLoader( true );

            if( contextData.token !== null )
                await getTableResults( contextData.token, '25', '0' );

            setOpenFullScreenLoader( false );

        }
        else{

            const page = currentPage;
            let limit  = 25;
            let offset = 0;
    
            if( page > 1 )
                offset = ( page - 1 ) * limit;
    
            if( contextData.token !== null ){
                
                setSearch( searchValue );
                setOpenFullScreenLoader( true );
                await getTableResults( contextData.token, limit.toString(), offset.toString(), searchValue );
                setOpenFullScreenLoader( false );

            }

        }

    }

    // Order handler
    const orderHandler = async ( orderField: string ) => {

        const page = currentPage;
        let limit  = 25;
        let offset = 0;

        if( page > 1 )
            offset = ( page - 1 ) * limit;

        if( contextData.token !== null ){
            
            setOpenFullScreenLoader( true );
            await getTableResults( contextData.token, limit.toString(), offset.toString(), search, orderField );
            setOpenFullScreenLoader( false );

        }

    }

    // Change page handler
    const changePageHandler = async ( page: number ) => {

        let limit  = 25;
        let offset = 0;

        if( page > 1 )
            offset = ( page - 1 ) * limit;

        setCurrentPage( page );

        if( contextData.token !== null ){

            setOpenFullScreenLoader( true );

            if( search !== '' && search !== undefined && search !== null )
                await getTableResults( contextData.token, limit.toString(), offset.toString(), search );
            else
                await getTableResults( contextData.token, limit.toString(), offset.toString() );

            setOpenFullScreenLoader( false );
        
        }

    }

    // Get the table results when the component is mounted.
    useEffect(() => {

        if( contextData.token !== null && elements === null )
            getTableResults( contextData.token );
        if( contextData.token !== null && profiles === null && settings.campaign_profile )
            getProfiles( contextData.token );
        if( contextData.token && categories === null && settings.category )
            getCategories( contextData.token );

    }, [ contextData, elements, profiles, categories ]);

    // Open the slide show form to create a new item.
    const openCreateSlideFormHandler = () => {

        clearFieldsHandler();
        setOpenCreateSlideForm( true );

    }

    // Open the slide show form to edit an item.
    const OpenEditSlideFormHandler = () => {
        
        setOpenShowSlideForm( false );
        setOpenEditSlideForm( true );

    }

    // Open the slide show form to delete an item.
    const openDeleteSlideFormHandler = () => {
        
        setCurrentElementId( values.id.value );
        setOpenShowSlideForm( false );
        setOpenModal( true );

    }
    
    // Retrieve from the API an item for open the slide show form to show an item.
    const openShowSlideFormHandler = async( id: string ) => {
        
        try{
            
            if( contextData.token !== null ){
                
                clearFieldsHandler();
                setOpenFullScreenLoader( true );

                const response = await fetchAPI( `${endPoints.retrieve}${id}/`, 'get', null, contextData.token );

                if( response.status === 200 ){
                    
                    let copyValues = { ...values };

                    copyValues = openShowHandler( copyValues, response.data );
                    
                    setValues( copyValues );
                    setOpenFullScreenLoader( false );
                    setOpenShowSlideForm( true );
                
                }
            }

        }
        catch( err ){
            setOpenFullScreenLoader( false );
        }

    }

    // Clear the form state.
    const clearFieldsHandler = () => {

        let copyValues = { ...values };
        copyValues     = clearState( copyValues );

        setDisableSave( false );
        setDisableEdit( false );
        setErrors( [] );
        setValues( copyValues );

    }

    // Save the new item.
    const saveButtonHandler = async () => {

        setDisableSave( true );

        // Get the required fields.
        const requiredFields = getRequiredFields();

        if( values.hasOwnProperty('start_date') )
            if( values.start_date.value !== '' && values.start_date.value !== null )
                requiredFields.push({ label: 'Fecha inicio', name: 'start_date', type: 'date' });

        if( values.hasOwnProperty('end_date') )
            if( values.end_date.value !== '' && values.end_date.value !== null )
                requiredFields.push({ label: 'Fecha fin', name: 'end_date', type: 'date' });

        if( values.hasOwnProperty('date') )
            if( values.date.value !== '' && values.date.value !== null )
                requiredFields.push({ label: 'Fecha', name: 'date', type: 'date' });

        if( values.hasOwnProperty('email') )
            if( values.email.value !== '' && values.email.value !== null )
                requiredFields.push({ label: 'Email', name: 'email', type: 'email' });

        if( values.hasOwnProperty('mobile_phone') )
            if( values.mobile_phone.value !== '' && values.mobile_phone.value !== null )
                requiredFields.push({ label: 'Teléfono Móvil', name: 'mobile_phone', type: 'mobile' });

        // Validate the required fields and the token.
        if( formValidationHandler( requiredFields ) && contextData.token ){

            try{

                setOpenFullScreenLoader( true );

                const newElement = saveHandler( values );
                const response   = await fetchAPI( `${endPoints.post}`, 'upload', newElement, contextData?.token );

                if( response.status === 201 ){
                    
                    setOpenCreateSlideForm( false );
                    clearFieldsHandler();
                    setNotificationState({
                        title: 'Guardado exitosamente',
                        message: 'Puedes visualizarlo en el listado'
                    });
                    showNotification();
                    setElements( null );
                    setOpenFullScreenLoader( false );

                }

            }
            catch( err ){
            
                setDisableSave( false );
                setOpenFullScreenLoader( false );
                setErrors( [ 'Ocurrio un error, intenta nuevamente.' ] );
            
            }

        }
        else
            setDisableSave( false );

    }

    // Update the item.
    const updateButtonHandler = async () => {

        setDisableEdit( true );

        // Get the required fields.
        const requiredFields = getRequiredFields();

        if( values.hasOwnProperty('start_date') )
            if( values.start_date.value !== '' && values.start_date.value !== null )
                requiredFields.push({ label: 'Fecha inicio', name: 'start_date', type: 'date' });

        if( values.hasOwnProperty('end_date') )
            if( values.end_date.value !== '' && values.end_date.value !== null )
                requiredFields.push({ label: 'Fecha fin', name: 'end_date', type: 'date' });

        if( values.hasOwnProperty('date') )
            if( values.date.value !== '' && values.date.value !== null )
                requiredFields.push({ label: 'Fecha', name: 'date', type: 'date' });

        if( values.hasOwnProperty('email') )
            if( values.email.value !== '' && values.email.value !== null )
                requiredFields.push({ label: 'Email', name: 'email', type: 'email' });

        if( values.hasOwnProperty('mobile_phone') )
            if( values.mobile_phone.value !== '' && values.mobile_phone.value !== null )
                requiredFields.push({ label: 'Teléfono Móvil', name: 'mobile_phone', type: 'mobile' });

        if( formValidationHandler( requiredFields ) && contextData.token ){

            try{

                setOpenFullScreenLoader( true );

                const updateElement = updateHandler( values );
                const response      = await fetchAPI( `${endPoints.put}${values.id.value}/`, 'put', updateElement, contextData?.token );

                if( response.status === 200 ){

                    setOpenEditSlideForm( false );
                    clearFieldsHandler();
                    setNotificationState({
                        title: 'Editado exitosamente',
                        message: 'Puedes visualizarlo en el listado'
                    });
                    showNotification();
                    setElements( null );
                    setOpenFullScreenLoader( false );

                }

            }
            catch( err ){

                setDisableSave( false );
                setErrors( [ 'Ocurrio un error, intenta nuevamente.' ] );

            }

        }
        else
            setDisableEdit( false );

    }
    // Delete the item.
    const deleteButtonHandler = async () => {

        try{

            if( contextData.token !== null ){

                setOpenFullScreenLoader( true );

                const response = await fetchAPI( `${endPoints.delete}${currentElementId}/`, 'delete', null, contextData.token );
                
                if( response.status === 200 ){

                    setOpenModal( false );
                    setCurrentElementId( null );
                    setNotificationState({
                        title: 'Eliminado exitosamente',
                        message: 'El elemento se elimino correctamente.'
                    });
                    showNotification();
                    setElements( null );
                    setOpenFullScreenLoader( false );

                }

            }

        }
        catch( err ){
            setOpenFullScreenLoader( false );
        }

    }

    // Form configuration
    if( settings.campaign_profile && profiles !== null ){

        const existElement = formFields.some( item => 
            Array.isArray(item.fields) && item.fields.some( field => field.name === 'campaign_profile' )
        );

        if( !existElement )
            formFields.push({
                fields: [
                    {
                        type: 'text',
                        name: 'campaign_profile',
                        label: 'Perfil',
                        placeholder: 'Perfil',
                        formElement: 'select',
                        options: profiles
                    }
                ]
            });

    }
    
    if( settings.category && categories !== null ){

        const existElement = formFields.some( item => 
            Array.isArray(item.fields) && item.fields.some( field => field.name === 'category' )
        );

        if( !existElement )
            formFields.push({
                fields: [
                    {
                        type: 'text',
                        name: 'category',
                        label: 'Categoría',
                        placeholder: 'Categoría',
                        formElement: 'select',
                        options: categories
                    }
                ]
            });

    }
    
    if( ( settings.campaign_profile && settings.category ) && ( profiles !== null && categories !== null ) && settings.is_completed ){

        const existElement = formFields.some( item => 
            Array.isArray(item.fields) && item.fields.some( field => field.name === 'is_completed' )
        );

        if( !existElement )
            formFields.push({
                fields: [
                    {
                        type: 'text',
                        name: 'is_completed',
                        label: 'Estado del objetivo',
                        placeholder: 'Estado del objetivo',
                        formElement: 'select',
                        options: isCompletedDropdown
                    }
                ]
            });

    }
    else if( !settings.campaign_profile && !settings.category && settings.is_completed ){

        const existElement = formFields.some( item => 
            Array.isArray(item.fields) && item.fields.some( field => field.name === 'is_completed' )
        );

        if( !existElement )
            formFields.push({
                fields: [
                    {
                        type: 'text',
                        name: 'is_completed',
                        label: 'Estado del objetivo',
                        placeholder: 'Estado del objetivo',
                        formElement: 'select',
                        options: isCompletedDropdown
                    }
                ]
            });

    }

    // FieldsView configuration
    const formView: IFieldView[] = getFieldView( values );
    // Create the form
    const form: IFields[] = createForm( formFields, values, formChangeHandler, fileChangeHandler );

    if( elements === null )
        return <Loader />
    else
        return (

            <FullWidthContainer>

                <Title title={ featureSettings.featureName } buttonContent={ featureSettings.createButton } buttonAction={ openCreateSlideFormHandler } searchHandler={ searchHandler } helpHandler={ openHelpSlideHandler } />
                {
                    ( elements.length === 0 )
                    ?
                        <Empty />
                    :
                        <>
                            <Tables 

                                headers={ table.headers }
                                keys={ table.keys }
                                items={ elements }
                                totalItems={ totalItems }
                                currentPage={ currentPage }
                                changePage={ changePageHandler }
                                detailsHandler={ openShowSlideFormHandler }
                                orderHandler={ orderHandler }
                            />
                        </>
                }
                {/* Create Element */}
                <SlideForm title={ featureSettings.slideTitle } open={ openCreateSlideForm } setOpen={ setOpenCreateSlideForm } save={ saveButtonHandler } disableSave={ disableSave } clearFields={ clearFieldsHandler }>
                    <Errors errors={errors} title={'Errores'} />
                    <Form
                        customKey='create-form'  
                        form={ form }
                    />
                </SlideForm>
                {/* Show Element */}
                <SlideForm title={ featureSettings.slideTitle } open={ openShowSlideForm } setOpen={ setOpenShowSlideForm } save={ null } disableSave={ null } clearFields={ null }>
                    <>
                        <Actions editAction={ editAction } openEdit={ OpenEditSlideFormHandler } openDelete={ openDeleteSlideFormHandler } />
                        <FormView fields={ formView } />
                    </>
                </SlideForm>
                {/* Edit Element */}
                <SlideForm title={ featureSettings.slideTitle } open={ openEditSlideForm } setOpen={ setOpenEditSlideForm } save={ updateButtonHandler } disableSave={ disableEdit } clearFields={ clearFieldsHandler }>
                    <Errors errors={errors} title={'Errores'} />
                    <Form
                        customKey='edit-form'  
                        form={ form }
                    />
                </SlideForm>
                {/* Help Element */}
                <SlideForm title='¿Para qué sirve?' open={ openHelpSlide } setOpen={ setOpenHelpSlide } save={ null } disableSave={ null } clearFields={ null }>
                    { featureSettings.featureHelp }
                </SlideForm>
                <Notification 

                    title={ notificationState.title }
                    message={ notificationState.message }
                    open={openNotification}
                    setOpen={ setOpenNotification }

                />
                <Modal 
                    title='Eliminar Elemento'
                    content='¿Seguro que quieres eliminar este elemento? No volverás recuperarlo una vez eliminado.'
                    buttonText='Eliminar'
                    cancelButton={ true }
                    icon='delete'
                    open={ openModal }
                    setOpen={ setOpenModal }
                    buttonAction={ deleteButtonHandler }
                />
                <Loader fullScreen={ true } open={ openFullScreenLoader } />
            </FullWidthContainer>

        );

}

export default TableFeature;