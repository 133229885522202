import { useState } from 'react';

export interface INotification {

    title: string;
    message: string;

}

const useNotification = ( initialState: INotification ) => {

    const [ notificationState, setNotificationState ] = useState< INotification >( initialState );
    const [ openNotification, setOpenNotification ]   = useState( false );

    const showNotification = () => {

        setOpenNotification( true );

        const timer = setTimeout(() => {
            setOpenNotification( false );
        }, 5000);
      
        return () => clearTimeout( timer );

    }

    return {
        
        notificationState,
        setNotificationState,
        openNotification,
        setOpenNotification,
        showNotification

    }

}


export default useNotification;