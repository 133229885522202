import { useContext, useState, useEffect } from "react";
import Field from "../../../Components/Dashboard/SlideForm/Field/Field";
import Separator from "../../../Components/Dashboard/SlideForm/Separator/Separator";
import Tabs from "../../../Components/Dashboard/Tabs/Tabs";
import Input from "../../../Components/Forms/Input";
import useForm, { IValidationField } from "../../../CustomHooks/useForm";
import launch from '../../../Images/launch.png';
import Context from "../../../Components/Context/Context";
import Errors from "../../../Components/Errors/Errors";
import useHttp from "../../../CustomHooks/useHttp";
import Modal from "../../../Components/Modal/Modal";
import Loader from "../../../Components/Loader/Loader";
import SlideForm from "../../../Components/Dashboard/SlideForm/SlideForm";
import { clearState } from "../../../Utils/forms";
import Notification from "../../../Components/Notification/Notification";
import useNotification from "../../../CustomHooks/useNotification";
import FullWidthContainer from "../../../Containers/FullWidthContainer/FullWidthContainer";

interface ITab {

    name: string;
    path: string;
    isLink: boolean;

}

const MyAccount = () => {
    
    // Context
    const contextData = useContext( Context );
    // FetchAPI customHook
    const { fetchAPI } = useHttp();

    // States required for the UI
    const [ disableUpdatePassword, setDisableUpdatePassword ] = useState< boolean >( false );
    const [ openEditSlideForm, setOpenEditSlideForm ]         = useState< boolean >( false );
    const [ disableEdit, setDisableEdit ]                     = useState< boolean >( false );
    const [ openFullScreenLoader, setOpenFullScreenLoader ]   = useState< boolean >( false );
    const [ openModal, setOpenModal ]                         = useState< boolean >( false );
    const { 
        notificationState,
        setNotificationState,
        openNotification,
        setOpenNotification,
        showNotification
    } = useNotification({
        title: '',
        message: '',
    });
    const [ tabs, setTabs ] = useState< ITab[] >( [] );
    // Forms state customHook
    const { values, formChangeHandler, formValidationHandler, errors, setErrors, setValues } = useForm({

        id: {
            value: '',
            isValid: true
        },
        first_name: {
            value: '',
            isValid: true
        },
        last_name: {
            value: '',
            isValid: true
        },
        email: {
            value: '',
            isValid: true
        },
        mobile_phone: {
            value: '',
            isValid: true
        },
        password: {
            value: '',
            isValid: true
        },
        repassword: {
            value: '',
            isValid: true
        }

    });

    useEffect(() => {

        const retrieveUser = async() => {

            setOpenFullScreenLoader( true );

            if( contextData !== null && contextData.token ){

                const response = await fetchAPI( `/users/retrieve`, 'get', null, contextData.token );

                if( response.status === 200 ){

                    const user     = response.data.user;
                    let copyValues = { ...values };

                    copyValues.id.value           = user.id;
                    copyValues.first_name.value   = user.first_name;
                    copyValues.last_name.value    = user.last_name;
                    copyValues.email.value        = user.email;
                    copyValues.mobile_phone.value = ( user.mobile_phone === null ? '' : user.mobile_phone );


                    const tabsOptions = [
                        {
                            name: "Mi perfil",
                            path: '/mi-cuenta',
                            isLink: false
                        }
                    ];

                    if( contextData.isOwnerAccount )
                        tabsOptions.push(
                            {
                                name: 'Mi suscripción',
                                path: '/suscripcion',
                                isLink: true
                            },
                            {
                                name: 'Politicoins',
                                path: '/politicoins',
                                isLink: true
                            }
                        );
                    
                    setTabs( tabsOptions );
                    setValues( copyValues );
                    setOpenFullScreenLoader( false );

                }

            }   

        }

        if( values.id.value === '' )
            retrieveUser();

    },[ contextData ]);

    // Open the slide show form to edit an item.
    const OpenEditSlideFormHandler = () => {
        setErrors( [] );
        setOpenEditSlideForm( true );
    }

    // Clear the form state.
    const clearFieldsHandler = () => {

        let copyValues = { ...values };
        copyValues     = clearState( copyValues );

        setDisableEdit( false );
        setErrors( [] );
        setValues( copyValues );

    }

    // Update user password
    const updatePasswordButton = async () => {

        setDisableUpdatePassword( true );

        const requiredFields: IValidationField[] = [

            {
                label: 'Contraseña',
                name: 'password',
                type: 'text'
            },
            {
                label: 'Confirmar contraseña',
                name: 'repassword',
                type: 'text'
            }

        ];

        if( values.password.value !== values.repassword.value ){

            setErrors( [ 'Las contraseñas no coinciden.' ] );
            setDisableUpdatePassword( false );

        }
        else if( formValidationHandler( requiredFields ) && contextData.token ){
            
            setOpenFullScreenLoader( true );

            try{

                const newPassword = {
                    password: values.password.value
                };

                const updateResponse = await fetchAPI( `/users/update-password/${values.id.value}`, 'patch', newPassword, contextData?.token );
                
                setOpenFullScreenLoader( false );
                setOpenModal( true );

            }
            catch( err ){}

        }
        else
            setDisableUpdatePassword( false );

    }

    // Clear the mobile_phone field of the update user form.
    const updateUserClearFields = () => {
        
        const copyValues = { ...values };
        copyValues.mobile_phone.isValid = true;

        setErrors([]);

    }

    // Update the item.
    const updateButtonHandler = async () => {

        setDisableEdit( true );
        
        const requiredFields: IValidationField[] = [

            {
                label: 'Nombre',
                name: 'first_name',
                type: 'text'
            },
            {
                label: 'Apellidos',
                name: 'last_name',
                type: 'text'
            }

        ];

        if( values.mobile_phone.value !== '' ){
                
            requiredFields.push({
                label: 'Teléfono móvil',
                name: 'mobile_phone',
                type: 'mobile'
            });

        }

        if( formValidationHandler( requiredFields ) && contextData.token ){

            try{

                setOpenFullScreenLoader( true );

                const mobile_phone = ( values.mobile_phone.value === '' ? null : values.mobile_phone.value );
                const updateElement = {

                    first_name: values.first_name.value,
                    last_name: values.last_name.value,
                    mobile_phone: mobile_phone

                };

                const response = await fetchAPI( `/users/update/${values.id.value}`, 'put', updateElement, contextData?.token );

                setOpenEditSlideForm( false );
                setNotificationState({
                    title: 'Editado exitosamente',
                    message: 'Datos actualizados correctamente.'
                });
                showNotification();
                setOpenFullScreenLoader( false );
                setDisableEdit( false );

            }
            catch( err ){
                setDisableEdit( false );
            }

        }
        else{
            const copyValues = { ...values };
            
            copyValues.mobile_phone.value = '';

            setValues( copyValues );
            setDisableEdit( false );
        }

    }

    return (
        <FullWidthContainer>
            <Tabs items={ tabs } />
            <div className="px-4 py-6 sm:px-6 lg:px-8">
                <h2 className="font-bold text-3xl mb-8">Mi perfil</h2>
                <div className="flex flex-col md:flex-row pb-8">
                    <div className="w-full md:w-4/12">
                        <h4 className="font-semibold text-xl">Datos personales</h4>
                        <p className="text-gray-500">
                            Visualiza tus datos de creación de cuenta. 
                            Recomendamos utilizar un email permanente en la que pueda recibir correos.
                        </p>
                    </div>
                    <div className="px-16 mt-8 md:mt-0 md:w-8/12">
                        <img
                            alt="Launch icon"
                            src={ launch }
                        />
                        <Separator />
                        <Field 
                            title='Nombre'
                            content={values.first_name.value}
                            type='text'
                        />
                        <Separator />
                        <Field 
                            title='Apellidos'
                            content={values.last_name.value}
                            type='text'
                        />
                        <Separator />
                        <Field 
                            title='Email'
                            content={values.email.value}
                            type='text'
                        />
                        <Separator />
                        <Field 
                            title='Teléfono móvil'
                            content={values.mobile_phone.value}
                            type='text'
                        />
                        <button 
                            type="button" 
                            className="bg-indigo-600 mt-8 text-white rounded px-4 py-2"
                            onClick={ OpenEditSlideFormHandler }
                            disabled={ false }
                        >
                            Editar datos
                        </button>

                        {/* Edit Element */}
                        <SlideForm title='Mis Datos' open={ openEditSlideForm } setOpen={ setOpenEditSlideForm } save={ updateButtonHandler } disableSave={ disableEdit } clearFields={ updateUserClearFields }>
                            <Errors errors={errors} title={'Errores'} />
                            <Input
                                type='text'
                                name='first_name'
                                label='Nombre'
                                placeholder='Nombre'
                                state={ values.first_name.value }
                                isValid={ values.first_name.isValid }
                                formChangeHandler={ ( evt ) => formChangeHandler( 'first_name', evt.target.value ) }
                            />
                            <Input
                                type='text'
                                name='last_name'
                                label='Apellidos'
                                placeholder='Apellidos'
                                state={ values.last_name.value }
                                isValid={ values.last_name.isValid }
                                formChangeHandler={ ( evt ) => formChangeHandler( 'last_name', evt.target.value ) }
                            />
                            <Input
                                type='text'
                                name='mobile_phone'
                                label='Teléfono móvil'
                                placeholder='Teléfono móvil'
                                state={ values.mobile_phone.value }
                                isValid={ values.mobile_phone.isValid }
                                formChangeHandler={ ( evt ) => formChangeHandler( 'mobile_phone', evt.target.value ) }
                            />

                        </SlideForm>

                    </div>
                </div>
                <Separator />
                <div className="flex flex-col md:flex-row mt-16">
                    <div className="w-full md:w-4/12">
                        <h4 className="font-semibold text-xl">Cambiar contraseña</h4>
                        <p className="text-gray-500">
                            Actualice la contraseña asociada a su cuenta.
                        </p>
                    </div>
                    <div className="px-16 w-full mt-8 md:mt-0 md:w-8/12">
                        {
                            (openEditSlideForm === false ) ?
                                <Errors errors={errors} title={'Errores'} />
                            : null
                        }
                        
                        <div className='flex flex-col md:flex-row gap-2'>
                            <Input
                                type='password'
                                name='password'
                                label='Crear contraseña'
                                placeholder='Crear contraseña'
                                state={ values.password.value }
                                isValid={ true }
                                formChangeHandler={ ( evt ) => formChangeHandler( 'password', evt.target.value ) }
                            />
                            <Input
                                type='password'
                                name='repassword'
                                label='Confirmar contraseña'
                                placeholder='Confirmar contraseña'
                                state={ values.repassword.value }
                                isValid={ true }
                                formChangeHandler={ ( evt ) => formChangeHandler( 'repassword', evt.target.value ) }
                            />
                        </div>
                        <button 
                            type="button" 
                            className="bg-indigo-600 mt-8 text-white rounded px-4 py-2"
                            onClick={ updatePasswordButton }
                            disabled={ disableUpdatePassword }
                        >
                            Cambiar contraseña
                        </button>
                    </div>
                </div>
            </div>
            <Modal 
                title='Contraseña actualizada'
                content='Has actualizado con éxito tu contraseña. Ahora vuelve iniciar sesión con tu nueva contraseña.'
                buttonText='Iniciar Sesión'
                cancelButton={ false }
                icon='guard'
                open={ openModal }
                setOpen={ setOpenModal }
                buttonAction={()=>{ localStorage.clear(); window.location.href = '/';}}
            />
            <Notification
                title={ notificationState.title }
                message={ notificationState.message }
                open={openNotification}
                setOpen={ setOpenNotification }
            />
            <Loader fullScreen={ true } open={ openFullScreenLoader } />
        </FullWidthContainer>
    );

}

export default MyAccount
