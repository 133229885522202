import { useContext, useState, useEffect } from "react";
import FullWidthContainer from "../../../Containers/FullWidthContainer/FullWidthContainer";
import useForm, { IValidationField } from "../../../CustomHooks/useForm";
import Context from "../../../Components/Context/Context";
import useHttp from "../../../CustomHooks/useHttp";
import Errors from "../../../Components/Errors/Errors";
import Select from "../../../Components/Forms/Select";
import EmptyAI from "./EmptyAI/EmptyAI";
import { clearState } from "../../../Utils/forms";
import Loader from "../../../Components/Loader/Loader";
import Separator from "../../../Components/Dashboard/SlideForm/Separator/Separator";

import discurso from '../../../Images/discurso_small.png';
import redesSociales from '../../../Images/redes_sociales_small.png';
import propuestas from '../../../Images/propuestas_small.png';
import { NavLink } from "react-router-dom";

type TPromptsContainer = '' | 'hidden';
type TPrompts = 'prompts' | 'discurso' | 'publicacion' | 'propuestas';

interface IPrompt {
    
    icon: string;
    title: string;
    description: string;
    type: TPrompts;
    cost: string;

}

interface IPromptsContainer {

    prompts: TPromptsContainer;
    discurso: TPromptsContainer;
    publicacion: TPromptsContainer;
    propuestas: TPromptsContainer;

}

interface ISelectOption {

    id: string;
    title: string;

}

interface ISpeechResponse {
    
    title: string;
    speech: string[];
    
}

interface ISocialNetworkPostResponse {

    hashtags: string[];
    post: string[];
    title: string;

}

interface ICampaignIdeas {
    title: string;
    content: string;
    feeling: string;
}

interface ICampaignIdeasResponse {
    ideas: ICampaignIdeas[];
}

const AI = () => {

    // Context
    const contextData = useContext( Context );

    const prompts: IPrompt[] = [
        {
            icon: discurso,
            title: 'Discurso Político',
            description: 'Genera discursos políticos a tu medida.',
            type: 'discurso',
            cost: '25 creditos'
        },
        {
            icon: redesSociales,
            title: 'Redes Sociales',
            description: 'Genera publicaciones de contenido optimizado para redes sociales.',
            type: 'publicacion',
            cost: '15 creditos'
        }, 
        {
            icon: propuestas,
            title: 'Propuestas Políticas',
            description: 'Genera propuestas personalizadas para tu gobierno o tu campaña.',
            type: 'propuestas',
            cost: '25 creditos'
        }
    ];

    const temas: ISelectOption[] = [

        { id: 'Agricultura y Desarrollo Rural', title: 'Agricultura y Desarrollo Rural' },
        { id: 'Ciencia y Tecnología', title: 'Ciencia y Tecnología' },
        { id: 'Cultura y Patrimonio', title: 'Cultura y Patrimonio' },
        { id: 'Derecho a la Información', title: 'Derecho a la Información' },
        { id: 'Derechos Humanos', title: 'Derechos Humanos' },
        { id: 'Desarrollo Urbano y Vivienda', title: 'Desarrollo Urbano y Vivienda' },
        { id: 'Diversidad Cultural', title: 'Diversidad Cultural' },
        { id: 'Economía', title: 'Economía' },
        { id: 'Economía Digital', title: 'Economía Digital' },
        { id: 'Educación', title: 'Educación' },
        { id: 'Educación Superior', title: 'Educación Superior' },
        { id: 'Empleo y Trabajo', title: 'Empleo y Trabajo' },
        { id: 'Emprendimiento e Innovación', title: 'Emprendimiento e Innovación' },
        { id: 'Energía', title: 'Energía' },
        { id: 'Género y Equidad', title: 'Género y Equidad' },
        { id: 'Gestión de Riesgos y Desastres', title: 'Gestión de Riesgos y Desastres' },
        { id: 'Inclusión Social', title: 'Inclusión Social' },
        { id: 'Infraestructura y Transporte', title: 'Infraestructura y Transporte' },
        { id: 'Innovación y Tecnología', title: 'Innovación y Tecnología' },
        { id: 'Juventud y Deporte', title: 'Juventud y Deporte' },
        { id: 'Medio Ambiente', title: 'Medio Ambiente' },
        { id: 'Migración', title: 'Migración' },
        { id: 'Patrimonio Cultural', title: 'Patrimonio Cultural' },
        { id: 'Política Exterior', title: 'Política Exterior' },
        { id: 'Protección de Datos Personales', title: 'Protección de Datos Personales' },
        { id: 'Salud Mental', title: 'Salud Mental' },
        { id: 'Salud Pública', title: 'Salud Pública' },
        { id: 'Seguridad y Justicia', title: 'Seguridad y Justicia' },
        { id: 'Soberanía Alimentaria', title: 'Soberanía Alimentaria' },
        { id: 'Transformación Digital', title: 'Transformación Digital' },
        { id: 'Turismo Sustentable', title: 'Turismo Sustentable' },
        { id: 'Vida Silvestre y Biodiversidad', title: 'Vida Silvestre y Biodiversidad' }

    ];

    const sentimientos: ISelectOption[] = [

        { id: 'Alerta', title: 'Alerta' },
        { id: 'Compromiso', title: 'Compromiso' },
        { id: 'Conciencia Social', title: 'Conciencia Social' },
        { id: 'Confianza', title: 'Confianza' },
        { id: 'Coraje', title: 'Coraje' },
        { id: 'Creatividad', title: 'Creatividad' },
        { id: 'Curiosidad', title: 'Curiosidad' },
        { id: 'Desafío', title: 'Desafío' },
        { id: 'Empatía', title: 'Empatía' },
        { id: 'Esperanza', title: 'Esperanza' },
        { id: 'Fe', title: 'Fe' },
        { id: 'Generosidad', title: 'Generosidad' },
        { id: 'Gratitud', title: 'Gratitud' },
        { id: 'Honestidad', title: 'Honestidad' },
        { id: 'Inclusión', title: 'Inclusión' },
        { id: 'Integridad', title: 'Integridad' },
        { id: 'Inspiración', title: 'Inspiración' },
        { id: 'Justicia', title: 'Justicia' },
        { id: 'Libertad', title: 'Libertad' },
        { id: 'Motivación', title: 'Motivación' },
        { id: 'Optimismo', title: 'Optimismo' },
        { id: 'Paz', title: 'Paz' },
        { id: 'Resiliencia', title: 'Resiliencia' },
        { id: 'Responsabilidad', title: 'Responsabilidad' },
        { id: 'Serenidad', title: 'Serenidad' },
        { id: 'Solidaridad', title: 'Solidaridad' },
        { id: 'Tolerancia', title: 'Tolerancia' },
        { id: 'Urgencia', title: 'Urgencia' },
        { id: 'Voluntad', title: 'Voluntad' }

    ];

    const audiencia: ISelectOption[] = [

        { id: 'Abogados y juristas', title: 'Abogados y juristas' },
        { id: 'Activistas ambientales', title: 'Activistas ambientales' },
        { id: 'Adolescentes', title: 'Adolescentes' },
        { id: 'Adultos mayores', title: 'Adultos mayores' },
        { id: 'Agricultores locales', title: 'Agricultores locales' },
        { id: 'Amas de casa', title: 'Amas de casa' },
        { id: 'Artistas y creativos', title: 'Artistas y creativos' },
        { id: 'Bibliotecarios y educadores', title: 'Bibliotecarios y educadores' },
        { id: 'Científicos y académicos', title: 'Científicos y académicos' },
        { id: 'Comerciantes y vendedores', title: 'Comerciantes y vendedores' },
        { id: 'Comunidades indígenas', title: 'Comunidades indígenas' },
        { id: 'Consumidores ecológicos', title: 'Consumidores ecológicos' },
        { id: 'Deportistas y entrenadores', title: 'Deportistas y entrenadores' },
        { id: 'Electores primerizos', title: 'Electores primerizos' },
        { id: 'Empresarios pequeños y medianos', title: 'Empresarios pequeños y medianos' },
        { id: 'Especialistas en marketing', title: 'Especialistas en marketing' },
        { id: 'Estudiantes de posgrado', title: 'Estudiantes de posgrado' },
        { id: 'Estudiantes de secundaria', title: 'Estudiantes de secundaria' },
        { id: 'Expatriados', title: 'Expatriados' },
        { id: 'Inmigrantes', title: 'Inmigrantes' },
        { id: 'Ingenieros y arquitectos', title: 'Ingenieros y arquitectos' },
        { id: 'Inversionistas y financistas', title: 'Inversionistas y financistas' },
        { id: 'Investigadores sociales', title: 'Investigadores sociales' },
        { id: 'Jóvenes emprendedores', title: 'Jóvenes emprendedores' },
        { id: 'Jóvenes profesionales', title: 'Jóvenes profesionales' },
        { id: 'Líderes comunitarios', title: 'Líderes comunitarios' },
        { id: 'Médicos', title: 'Médicos' },
        { id: 'Músicos y actores', title: 'Músicos y actores' },
        { id: 'Padres de familia', title: 'Padres de familia' },
        { id: 'Pensionados', title: 'Pensionados' },
        { id: 'Periodistas y comunicadores', title: 'Periodistas y comunicadores' },
        { id: 'Personal administrativo y de oficina', title: 'Personal administrativo y de oficina' },
        { id: 'Personal de emergencias', title: 'Personal de emergencias' },
        { id: 'Personal de mantenimiento', title: 'Personal de mantenimiento' },
        { id: 'Personal de servicios de comida', title: 'Personal de servicios de comida' },
        { id: 'Personas con discapacidad', title: 'Personas con discapacidad' },
        { id: 'Profesionales de bienes raíces', title: 'Profesionales de bienes raíces' },
        { id: 'Profesionales de la belleza y la moda', title: 'Profesionales de la belleza y la moda' },
        { id: 'Profesionales de TI', title: 'Profesionales de TI' },
        { id: 'Profesores de primaria', title: 'Profesores de primaria' },
        { id: 'Residentes rurales', title: 'Residentes rurales' },
        { id: 'Residentes urbanos', title: 'Residentes urbanos' },
        { id: 'Taxistas y choferes', title: 'Taxistas y choferes' },
        { id: 'Técnicos y tecnólogos', title: 'Técnicos y tecnólogos' },
        { id: 'Trabajadores de la construcción', title: 'Trabajadores de la construcción' },
        { id: 'Trabajadores de la salud', title: 'Trabajadores de la salud' },
        { id: 'Trabajadores independientes', title: 'Trabajadores independientes' },
        { id: 'Turistas y sector turístico', title: 'Turistas y sector turístico' },
        { id: 'Veteranos militares', title: 'Veteranos militares' },
        { id: 'Voluntarios de ONGs', title: 'Voluntarios de ONGs' }

    ];    

    const words: ISelectOption[] = [
        {
            id: '100',
            title: '100'
        },
        {
            id: '250',
            title: '250'
        },
        {
            id: '400',
            title: '400'
        },
        {
            id: '800',
            title: '800'
        }
    ];

    const paragraphs: ISelectOption[] = [
        {
            id: '1',
            title: 'Uno'
        },
        {
            id: '2',
            title: 'Dos'
        },
        {
            id: '3',
            title: 'Tres'
        },
        {
            id: '4',
            title: 'Cuatro'
        },
        {
            id: '5',
            title: 'Cinco'
        },
        {
            id: '6',
            title: 'Seis'
        },
        {
            id: '7',
            title: 'Siete'
        },
        {
            id: '8',
            title: 'Ocho'
        }
    ];

    const ideasType: ISelectOption[] = [
        {
            id: 'campaña',
            title: 'Ideas para campañas'
        },
        {
            id: 'gobierno',
            title: 'Ideas para gobierno'
        }
    ];

    const politic_type: ISelectOption[] = [

        {
            id: 'candidato municipal',
            title: 'Candidato Municipal'
        },
        {
            id: 'candidato estatal',
            title: 'Candidato Estatal'
        },
        {
            id: 'candidato federal',
            title: 'Candidato Federal'
        },
        {
            id: 'funcionario municipal',
            title: 'Funcionario Municipal'
        },
        {
            id: 'funcionario estatal',
            title: 'Funcionario Estatal'
        },
        {
            id: 'funcionario federal',
            title: 'Funcionario Federal'
        }

    ];

    const getPoliticoins = async () => {

        try{

            if( contextData?.token !== null ){

                const accountId = contextData.project;

                const response = await fetchAPI( `/artificial_intelligence/get_credits/${accountId}`, 'get', null, contextData?.token );
                setPoliticoins( response.credits );
                setOpenFullScreenLoader( false );

            }

        }
        catch( err: any ){
            setOpenFullScreenLoader( false );
            alert( 'Ocurrio un error recarga la pagina, por favor.' );
        }

    }

    // States required for the UI
    const [ promptsContainer, setPromptsContainer ] = useState< IPromptsContainer >({

        prompts: '',
        discurso: 'hidden',
        publicacion: 'hidden',
        propuestas: 'hidden'

    });

    const [ politicoins, setPoliticoins ] = useState< number | null >( null );

    const [ emptyText, setEmptyText ]                       = useState< string >( 'Selecciona un modo para comenzar' );
    const [ disableGenerate, setDisableGenerate ]           = useState< boolean >( false );
    const [ openFullScreenLoader, setOpenFullScreenLoader ] = useState< boolean >( false );

    const [ speechResponse, setSpeechResponse ]                       = useState< ISpeechResponse | null >( null );
    const [ socialNetworkPostResponse, setSocialNetworkPostResponse ] = useState< ISocialNetworkPostResponse | null >( null );
    const [ campaignIdeasResponse, setCampaignIdeasResponse ]         = useState< ICampaignIdeasResponse | null >( null );
    // FetchAPI customHook
    const { fetchAPI } = useHttp();
    // Forms state customHook
    const { values, formChangeHandler, formValidationHandler, errors, setErrors, setValues } = useForm({

        topic: {
            value: '-1',
            isValid: true
        },
        feeling: {
            value: '-1',
            isValid: true
        },
        audience: {
            value: '-1',
            isValid: true
        },
        words: {
            value: '-1',
            isValid: true
        },
        paragraphs: {
            value: '-1',
            isValid: true
        },
        ideasType: {
            value: '-1',
            isValid: true
        },
        politicType: {
            value: '-1',
            isValid: true
        }

    });

    // Clear the form state.
    const clearFieldsHandler = () => {

        let copyValues = { ...values };
        copyValues     = clearState( copyValues );
        
        setSpeechResponse( null );
        setSocialNetworkPostResponse( null );
        setCampaignIdeasResponse( null );
        setErrors( [] );
        setValues( copyValues );

    }

    const choosePromptHandler = ( option: TPrompts ) => {

        const promptsContainerCopy = { ...promptsContainer };

        promptsContainerCopy[ 'prompts' ]     = 'hidden';
        promptsContainerCopy[ 'discurso' ]    = 'hidden';
        promptsContainerCopy[ 'publicacion' ] = 'hidden';
        promptsContainerCopy[ 'propuestas' ]  = 'hidden';
        promptsContainerCopy[ option ]        = '';

        if( option == 'prompts' )
            setEmptyText( 'Selecciona un modo para comenzar' );
        else
            setEmptyText( 'Ahora, llena los campos para generar el contenido' );

        clearFieldsHandler();
        setDisableGenerate( false );
        setPromptsContainer( promptsContainerCopy );

    }

    const makeSpeechHandler = async () => {

        const requiredFields: IValidationField[] = [

            {
                label: 'Tema',
                name: 'topic',
                type: 'dropdown'
            },
            {
                label: 'Sentimiento',
                name: 'feeling',
                type: 'dropdown'
            },
            {
                label: 'Audiencia',
                name: 'audience',
                type: 'dropdown'
            },
            {
                label: 'Palabras',
                name: 'words',
                type: 'dropdown'
            },

        ];

        // Validate the required fields and the token.
        if( formValidationHandler( requiredFields ) && contextData.token ){
            
            try{
                
                setOpenFullScreenLoader( true );

                const accountId = contextData.project;

                const speech = {
                    topic: values.topic.value,
                    feeling: values.feeling.value,
                    audience: values.audience.value,
                    words: parseInt( values.words.value )
                }

                const response = await fetchAPI( `/artificial_intelligence/make_speech/${accountId}`, 'post', speech, contextData?.token );
                
                setSpeechResponse( response );
                setOpenFullScreenLoader( false );
                setPoliticoins( null );

            }
            catch( err: any ){
                
                if( err.response.data.status !== null && err.response.data.status !== undefined && err.response.data.status === 402 ){

                    setDisableGenerate( false );
                    setOpenFullScreenLoader( false );
                    setErrors( [ 'Creditos insuficientes.' ] );    

                }
                else{

                    setDisableGenerate( false );
                    setOpenFullScreenLoader( false );
                    setErrors( [ 'Ocurrio un error, intenta nuevamente.' ] );

                }

            }

        }
        else
            setDisableGenerate( false );

    }

    const makeSocialNetworkPostHandler = async() => {

        const requiredFields: IValidationField[] = [

            {
                label: 'Tema',
                name: 'topic',
                type: 'dropdown'
            },
            {
                label: 'Sentimiento',
                name: 'feeling',
                type: 'dropdown'
            },
            {
                label: 'Audiencia',
                name: 'audience',
                type: 'dropdown'
            },
            {
                label: 'Palabras',
                name: 'words',
                type: 'dropdown'
            },
            {
                label: 'Parrafos',
                name: 'paragraphs',
                type: 'dropdown'
            },

        ];

        // Validate the required fields and the token.
        if( formValidationHandler( requiredFields ) && contextData.token ){

            try{

                setOpenFullScreenLoader( true );

                const accountId = contextData.project;

                const speech = {
                    topic: values.topic.value,
                    feeling: values.feeling.value,
                    audience: values.audience.value,
                    words: parseInt( values.words.value ),
                    paragraphs: parseInt( values.paragraphs.value )
                }

                const response = await fetchAPI( `/artificial_intelligence/make_social_network_post/${accountId}`, 'post', speech, contextData?.token );
                
                setSocialNetworkPostResponse( response );
                setOpenFullScreenLoader( false );
                setPoliticoins( null );

            }
            catch( err: any ){

                if( err.response.data.status !== null && err.response.data.status !== undefined && err.response.data.status === 402 ){

                    setDisableGenerate( false );
                    setOpenFullScreenLoader( false );
                    setErrors( [ 'Creditos insuficientes.' ] );    

                }
                else{

                    setDisableGenerate( false );
                    setOpenFullScreenLoader( false );
                    setErrors( [ 'Ocurrio un error, intenta nuevamente.' ] );

                }

            }

        }
        else
            setDisableGenerate( false );

    }

    const makeCampaignIdeasHandler = async() => {

        const requiredFields: IValidationField[] = [
            {
                label: 'Tema',
                name: 'topic',
                type: 'dropdown'
            },
            {
                label: 'Audiencia',
                name: 'audience',
                type: 'dropdown'
            },
            {
                label: 'Objetivo de las propuestas',
                name: 'ideasType',
                type: 'dropdown'
            },
            {
                label: 'Nivel político',
                name: 'politicType',
                type: 'dropdown'
            }
        ];

        // Validate the required fields and the token.
        if( formValidationHandler( requiredFields ) && contextData.token ){

            try{

                setOpenFullScreenLoader( true );

                const accountId = contextData.project;

                const ideas = {

                    topic: values.topic.value,
                    audience: values.audience.value,
                    type: values.ideasType.value,
                    politic_type: values.politicType.value

                }

                const response = await fetchAPI( `/artificial_intelligence/make_campaign_ideas/${accountId}`, 'post', ideas, contextData?.token );
                console.log( response );
                setCampaignIdeasResponse( response );
                setOpenFullScreenLoader( false );
                setPoliticoins( null );

            }
            catch( err: any ){

                if( err.response.data.status !== null && err.response.data.status !== undefined && err.response.data.status === 402 ){

                    setDisableGenerate( false );
                    setOpenFullScreenLoader( false );
                    setErrors( [ 'Creditos insuficientes.' ] );    

                }
                else{

                    setDisableGenerate( false );
                    setOpenFullScreenLoader( false );
                    setErrors( [ 'Ocurrio un error, intenta nuevamente.' ] );

                }

            }

        }
        else
            setDisableGenerate( false );

    }

    useEffect(() => {

        if( politicoins === null && contextData !== null ){
            
            setOpenFullScreenLoader( true );
            getPoliticoins();

        }

    }, [ politicoins ]);

    if( politicoins === null )
        return <Loader />
    else
        return(
            <FullWidthContainer>
                <div className="w-full px-6">
                    <p className="font-bold text-2xl">Politicoins: <span className="text-indigo-500">{ politicoins }</span></p>
                    {
                        ( contextData.isOwnerAccount )
                            ?
                                <NavLink to="/politicoins" className="text-indigo-500">
                                    <p className="text-indigo-500">Añade Politicoins</p>
                                </NavLink>
                            : 
                                null
                    }
                </div>
                <Separator />
                <div className="flex flex-col px-6 md:flex-row">
                    <div className={ `w-full md:w-4/12 ${promptsContainer.prompts}` }>
                        {
                            prompts.map(( prompt, index ) => (
                                <div key={index} className="border-2 cursor-pointer rounded-md mb-2 p-4" onClick={ () => choosePromptHandler( prompt.type ) }>
                                    <img className="mb-6" src={ prompt.icon } />
                                    <p className="text-xl font-semibold">{ prompt.title }</p>
                                    <p className="text-md text-gray-500">{ prompt.description }</p>
                                </div>
                            ))
                        }
                    </div>

                    <div className={ `w-full md:w-4/12 ${promptsContainer.discurso}` }>
                        <p className="text-2xl font-semibold">Discurso Político</p>
                        <p className="text-sm text-gray-500 mb-6">Costo: 25 creditos.</p>
                        <Errors errors={errors} title={'Errores'} />
                        <Select
                            options={ temas }
                            name='topic'
                            label='Elige el tema'
                            state={ values.topic.value }
                            isValid={ values.topic.isValid }
                            formChangeHandler={(evt) =>
                                formChangeHandler( 'topic', evt.target.value )
                            }
                        />
                        <Select
                            options={ sentimientos }
                            name='feeling'
                            label='Escoge el sentimiento'
                            state={ values.feeling.value }
                            isValid={ values.feeling.isValid }
                            formChangeHandler={(evt) =>
                                formChangeHandler( 'feeling', evt.target.value )
                            }
                        />
                        <Select
                            options={ audiencia }
                            name='audience'
                            label='Enfócalo a tu audiencia'
                            state={ values.audience.value }
                            isValid={ values.audience.isValid }
                            formChangeHandler={(evt) =>
                                formChangeHandler( 'audience', evt.target.value )
                            }
                        />
                        <Select
                            options={ words }
                            name='words'
                            label='Numero de palabras aproximadas'
                            state={ values.words.value }
                            isValid={ values.words.isValid }
                            formChangeHandler={(evt) =>
                                formChangeHandler( 'words', evt.target.value )
                            }
                        />
                        <button 
                            type="button" 
                            className="bg-indigo-600 mt-2 mb-1 text-white rounded px-4 py-2 w-full"
                            onClick={ makeSpeechHandler }
                            disabled={ disableGenerate }
                        >
                            Generar discurso
                        </button>
                        <button 
                            type="button" 
                            className="bg-gray-500 text-white rounded px-4 py-2 w-full"
                            onClick={ () => choosePromptHandler( 'prompts' ) }
                            disabled={ false }
                        >
                            Regresar
                        </button>

                    </div>
                    <div className={ `w-full md:w-4/12 ${promptsContainer.publicacion}` }>
                        <p className="text-2xl font-semibold">Redes Sociales</p>
                        <p className="text-sm text-gray-500 mb-6">Costo: 15 creditos.</p>
                        <Errors errors={errors} title={'Errores'} />
                        <Select
                            options={ temas }
                            name='topic'
                            label='Elige el tema'
                            state={ values.topic.value }
                            isValid={ values.topic.isValid }
                            formChangeHandler={(evt) =>
                                formChangeHandler( 'topic', evt.target.value )
                            }
                        />
                        <Select
                            options={ sentimientos }
                            name='feeling'
                            label='Escoge el sentimiento'
                            state={ values.feeling.value }
                            isValid={ values.feeling.isValid }
                            formChangeHandler={(evt) =>
                                formChangeHandler( 'feeling', evt.target.value )
                            }
                        />
                        <Select
                            options={ audiencia }
                            name='audience'
                            label='Enfócalo a tu audiencia'
                            state={ values.audience.value }
                            isValid={ values.audience.isValid }
                            formChangeHandler={(evt) =>
                                formChangeHandler( 'audience', evt.target.value )
                            }
                        />
                        <Select
                            options={ words }
                            name='words'
                            label='Numero de palabras aproximadas'
                            state={ values.words.value }
                            isValid={ values.words.isValid }
                            formChangeHandler={(evt) =>
                                formChangeHandler( 'words', evt.target.value )
                            }
                        />
                        <Select
                            options={ paragraphs }
                            name='paragraphs'
                            label='Parrafos deseados'
                            state={ values.paragraphs.value }
                            isValid={ values.paragraphs.isValid }
                            formChangeHandler={(evt) =>
                                formChangeHandler( 'paragraphs', evt.target.value )
                            }
                        />
                        <button 
                            type="button" 
                            className="bg-indigo-600 mt-2 mb-1 text-white rounded px-4 py-2 w-full"
                            onClick={ makeSocialNetworkPostHandler }
                            disabled={ disableGenerate }
                        >
                            Generar publicación
                        </button>
                        <button 
                            type="button" 
                            className="bg-gray-500 text-white rounded px-4 py-2 w-full"
                            onClick={ () => choosePromptHandler( 'prompts' ) }
                            disabled={ false }
                        >
                            Regresar
                        </button>
                    </div>

                    <div className={ `w-full md:w-4/12 ${promptsContainer.propuestas}` }>
                        <p className="text-2xl font-semibold">Propuestas Políticas</p>
                        <p className="text-sm text-gray-500 mb-6">Costo: 25 creditos.</p>
                        <Errors errors={ errors } title={ 'Errores' } />
                        <Select
                            options={ temas }
                            name='topic'
                            label='Elige el tema'
                            state={ values.topic.value }
                            isValid={ values.topic.isValid }
                            formChangeHandler={(evt) =>
                                formChangeHandler( 'topic', evt.target.value )
                            }
                        />
                        <Select
                            options={ audiencia }
                            name='audience'
                            label='Enfócalo a tu audiencia'
                            state={ values.audience.value }
                            isValid={ values.audience.isValid }
                            formChangeHandler={(evt) =>
                                formChangeHandler( 'audience', evt.target.value )
                            }
                        />
                        <Select
                            options={ ideasType }
                            name='ideasType'
                            label='Objetivo de las propuestas'
                            state={ values.ideasType.value }
                            isValid={ values.ideasType.isValid }
                            formChangeHandler={(evt) =>
                                formChangeHandler( 'ideasType', evt.target.value )
                            }
                        />
                        <Select
                            options={ politic_type }
                            name='politicType'
                            label='Nivel político'
                            state={ values.politicType.value }
                            isValid={ values.politicType.isValid }
                            formChangeHandler={(evt) =>
                                formChangeHandler( 'politicType', evt.target.value )
                            }
                        />
                        <button 
                            type="button" 
                            className="bg-indigo-600 mt-2 mb-1 text-white rounded px-4 py-2 w-full"
                            onClick={ makeCampaignIdeasHandler }
                            disabled={ disableGenerate }
                        >
                            Generar publicación
                        </button>
                        <button 
                            type="button" 
                            className="bg-gray-500 text-white rounded px-4 py-2 w-full"
                            onClick={ () => choosePromptHandler( 'prompts' ) }
                            disabled={ false }
                        >
                            Regresar
                        </button>
                    </div>

                    <div className="w-full md:w-8/12 pt-4 md:px-8 md:pt-4">
                        {
                            ( speechResponse === null && socialNetworkPostResponse === null && campaignIdeasResponse === null )
                            ? 
                                <EmptyAI text={ emptyText } />
                            : 
                                null
                        }
                        {
                            ( speechResponse !== null && socialNetworkPostResponse === null && campaignIdeasResponse === null )
                                ? 
                                    <div className="w-full text-justify">
                                        <p className="text-2xl font-semibold mb-2">{ speechResponse.title }</p>
                                        {
                                            speechResponse.speech.map(( paragraph, index ) => (
                                                <p key={ index } className="text-md text-gray-500 mb-2">{ paragraph }</p>
                                            ))
                                            
                                        }
                                        <div className="pt-4">
                                            <p className="font-bold">Asegurate de copiar y guardar el discurso generado.</p>
                                        </div>
                                    </div>
                                : 
                                    null
                        }
                        {
                            ( socialNetworkPostResponse !== null && speechResponse === null && campaignIdeasResponse === null )
                                ? 
                                    <div className="w-full text-justify">
                                        <p className="text-2xl font-semibold mb-2">{ socialNetworkPostResponse.title }</p>
                                        {
                                            socialNetworkPostResponse.post.map(( paragraph, index ) => (
                                                <p key={ index } className="text-md text-gray-500 mb-2">{ paragraph }</p>
                                            ))
                                        }
                                        <div className="pt-2">
                                            <p className="font-semibold text-md">Hashtags sugeridos:</p>
                                            <p className="text-md text-indigo-500 mb-2">{ socialNetworkPostResponse.hashtags }</p>
                                        </div>
                                        <div className="pt-4">
                                            <p className="font-bold">Asegurate de copiar y guardar la publicación generada.</p>
                                        </div>
                                    </div>  
                                : 
                                        null
                        }
                        {
                            ( campaignIdeasResponse !== null && socialNetworkPostResponse === null && speechResponse === null )
                                ? 
                                    <div className="w-full text-justify">
                                        <p className="text-2xl font-semibold mb-2">Propuestas generadas:</p>
                                        {
                                            campaignIdeasResponse.ideas.map( ( idea, index ) => {
                                                return(
                                                    <div key={ index } className="mb-2">
                                                        <p  className="text-lg font-semibold">Propuesta {index + 1}.- { idea.title }</p>
                                                        <p  className="text-md">{ idea.content }</p>
                                                        <p  className="text-md mt-2 font-semibold">Analisis:</p>
                                                        <p  className="text-md text-gray-500 text-underline">{ idea.feeling }</p>
                                                        <Separator />
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="pt-4">
                                            <p className="font-bold">Asegurate de copiar y guardar las propuestas generadas.</p>
                                        </div>
                                    </div>  
                                : 
                                        null
                        }

                        
                    </div>
                </div>
                <Loader fullScreen={ true } open={ openFullScreenLoader } />
            </FullWidthContainer>
        );

}

export default AI;