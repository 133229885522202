import { ChangeEventHandler } from "react";

export type TInput = 'email' | 'text' | 'password' | 'date' | 'file';

interface IProps {

    type: TInput;
    name: string;
    label: string;
    placeholder: string;
    state: string;
    isValid: boolean;
    formChangeHandler: ChangeEventHandler< HTMLInputElement >;

}

const Input = ( props: IProps ) => {

    const type              = props.type;
    const name              = props.name;
    const label             = props.label;
    const placeholder       = props.placeholder;
    const state             = props.state;
    const isValid           = props.isValid;
    const formChangeHandler = props.formChangeHandler;

    let className = 'block w-full rounded-md mb-3 border-gray-200 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6';
    let accept    = undefined;

    if( !isValid )
        className = 'block w-full rounded-md mb-3 border-red-400 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6';

    if( type === 'file' )
        accept = ".jpg,.png,.jpeg,.docx,.xlsx,.pptx,.pdf,.txt,.csv,.zip";

    return (

        <div className="w-full">
            <label
                htmlFor={ name }
                className="block text-sm leading-6 text-gray-900"
            >
                { label }
            </label>
            <div className="">
                {
                    ( type === 'file' )
                    ?
                        <input
                        id={ name }
                        type={ type }
                        accept={ accept }
                        name={ name }
                        className={ className }
                        placeholder={ placeholder }
                        onChange={ formChangeHandler }
                    />
                :
                    <input
                        id={ name }
                        type={ type }
                        accept={ accept }
                        name={ name }
                        className={ className }
                        placeholder={ placeholder }
                        value={ state }
                        onChange={ formChangeHandler }
                    />
                }
            </div>
        </div>

    );

}

export default Input;
