import { JSX } from "react/jsx-runtime";
import { IState } from "../../CustomHooks/useForm";
import Input, { TInput } from "./Input";
import Select from "./Select";
import TextArea from "./TextArea";

export type TFormElement = 'input' | 'textarea' | 'select';

export interface IFieldSettings {
    
    type: TInput;
    name: string;
    label: string;
    placeholder: string;
    formElement: TFormElement;
    options: any[] | null;
    state: IState;
    formChangeHandler: Function;

}

export interface IFields {
    fields: IFieldSettings[]
}

interface IProps {

    customKey: string;
    form: IFields[];

}

const Form = ( props: IProps ) => {

    const form = props.form;
    const key  = props.customKey;

    const getFormElement = ( field: IFieldSettings, index: number ) => {

        if( field.formElement == 'input' ){

            if( field.type !== 'file' )
                return (

                    <Input

                        key={ key + '-' + index }
                        type={ field.type }
                        name={ field.name }
                        label={ field.label }
                        placeholder={ field.placeholder }
                        state={ field.state[ field.name ].value }
                        isValid={ field.state[ field.name ].isValid }
                        formChangeHandler={( evt ) => field.formChangeHandler( field.name, evt.target.value ) }

                    />
                    
                );
            else
                return (

                    <Input

                        key={ key + '-' + index }
                        type={ field.type }
                        name={ field.name }
                        label={ field.label }
                        placeholder={ field.placeholder }
                        state={ field.state[ field.name ].value }
                        isValid={ field.state[ field.name ].isValid }
                        formChangeHandler={( evt: any ) => field.formChangeHandler( field.name, evt.target.files[0] ) }

                    />
                    
                );
        }
        else if( field.formElement == 'select' ){

            return(

                <Select

                    key={ key + '-' +index }
                    options={ field.options }
                    name={ field.name }
                    label={ field.label }
                    state={ field.state[ field.name ].value }
                    isValid={ field.state[ field.name ].isValid }
                    formChangeHandler={(evt) =>
                        field.formChangeHandler( field.name, evt.target.value )
                    }

                />

            );

        }
        else if( field.formElement == 'textarea'){

            return(

                <TextArea

                    key={ key + '-' +index }
                    name={ field.name }
                    label={ field.label }
                    placeholder={ field.placeholder }
                    state={ field.state[ field.name ].value }
                    isValid={ field.state[ field.name ].isValid }
                    formChangeHandler={( evt ) => field.formChangeHandler( field.name, evt.target.value ) }

                />

            );

        }

    }

    const createForm = ( form: IFields[] ) => {

        const JSX: ( JSX.Element | undefined )[] = [];

        form.map( ( formElement, index ) => {

            if( formElement.fields.length == 1 ){
                JSX.push( getFormElement( formElement.fields[0], index ) );
            }
            else{

                JSX.push(
                    <div key={key + '-' + index} className='flex flex-col md:flex-row gap-2'>
                        {
                            formElement.fields.map(( field, index ) => getFormElement( field, index ))
                        }
                    </div>
                )

            }

        });

        return JSX;

    }

    return ( 
        <div key={ key }>
            { createForm( form ) }
        </div>
    )

}

export default Form;