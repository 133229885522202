import edit from '../../../../Images/edit.png';
import remove from '../../../../Images/delete.png';

interface IProps {
    
    openEdit: any;
    openDelete: any;
    editAction: boolean;

}

const Actions = ( props: IProps ) => {

    const editAction = props.editAction;
    const openEdit   = props.openEdit;
    const openDelete = props.openDelete;

    return(
        <div className="flex flex-col font-semibold gap-3 mb-8 sm:flex-row sm:items-center">
            {
                ( editAction === true )
                    ?
                        <button
                            type="button" 
                            className="border border-gray-300 rounded px-4 py-2"
                            onClick={ openEdit }
                        >
                            <img 
                                className="inline-block h-4 w-4 mr-2"
                                alt="Edit icon" 
                                src={ edit }
                            />
                            Editar
                        </button>
                    :
                        null
            }
            <button
                type="button" 
                className="border border-gray-300 rounded px-4 py-2"
                onClick={ openDelete }
            >
                <img 
                    className="inline-block h-4 w-4 mr-2"
                    alt="Edit icon" 
                    src={ remove }
                />
                Eliminar
            </button>
        </div>
    );

}

export default Actions;