import { createdAtFormatDate } from '../../Utils/dates';
import Badge from '../Badge/Badge';
import { ITableHeaders } from '../Features/TableFeature/TableFeature';
import Status from '../Status/Status';

type TKeysTypes = 'text' | 'badge' | 'status' | 'date' | 'profile' | 'category';

export interface Ikeys {

    key: string;
    type: TKeysTypes

}

interface IProps {

    headers: ITableHeaders[];
    keys: Ikeys[];
    items: any[];
    totalItems: number;
    currentPage: number;
    changePage: Function;
    detailsHandler: Function;
    orderHandler: Function;

}

const Tables = ( props: IProps ) => {

    const headers        = props.headers;
    const keys           = props.keys;
    const items          = props.items;
    const totalItems     = props.totalItems;
    const currentPage    = props.currentPage;
    const changePage     = props.changePage;
    const detailsHandler = props.detailsHandler;
    const orderHandler   = props.orderHandler;

    const totalPages = Math.ceil( totalItems / 25 );
    const pagination = [];

    for( let i = 0; i < totalPages; i++ ){

        const page = i + 1;

        if( page == currentPage ){
            pagination.push(
                <div 
                    className='border bg-indigo-600 px-4 py-2 rounded text-white'
                    key={ 'page-'+page }
                >
                    { page }
                </div>
            );
        }
        else{
            pagination.push(
                <div 
                    className='border cursor-pointer px-4 py-2 rounded text-gray-700'
                    key={ 'page-'+page }
                    onClick={ () => changePage( page ) }
                >
                    { page }
                </div>
            );
        }

    }

    const renderValue = ( item: any, key: Ikeys ) => {

        if( key.type === 'text' ){
            return ( item[ key.key ] !== null ) ? item[ key.key ] : '-';
        }
        else if( key.type === 'badge' ){
            return <Badge text={ item[ key.key ] } />
        }
        else if( key.type === 'date' ){

            const dateTime      = item[ key.key ];
            const formattedDate = ( dateTime !== null ) ? createdAtFormatDate( dateTime ) : '-';

            return formattedDate;

        }
        else if( key.type === 'profile' ){
            return ( item[ key.key ] !== null ) ? `${item[ key.key ].first_name} ${item[ key.key ].last_name}` : '-';
        }
        else if( key.type === 'category' ){
            return ( item[ key.key ] !== null ) ? `${item[ key.key ].title}` : '-';
        }
        else if( key.type === 'status' ){

            return <Status status={ item[ key.key ] } />
        
        }

    }

    return (
        <div className="w-full">
            <div className="mt-8 flow-root">
                <div className="overflow-x-auto">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <div className="">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        
                                        {
                                            headers.map( ( header, index ) => {

                                                let headerJsx;

                                                if( header.sortable )
                                                    headerJsx = (
                                                        <th
                                                            scope="col"
                                                            className="cursor-pointer py-2 px-4 text-md text-left hover:text-indigo-600"
                                                            key={ 'title'+'-'+index }
                                                            onClick={ () => orderHandler( header.key ) }
                                                        >
                                                            { header.label }
                                                        </th>
                                                    );
                                                else 
                                                headerJsx = (
                                                    <th
                                                        scope="col"
                                                        className="py-2 px-4 text-md text-left"
                                                        key={ 'title'+'-'+index }
                                                    >
                                                        { header.label }
                                                    </th>
                                                );

                                                return headerJsx;

                                            })
                                        }
                                        <th
                                            scope="col"
                                            className="py-2 px-4 text-md text-left"
                                            key={'title'+'-'+'index'}
                                        >
                                            Detalles
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">

                                    {
                                        items.map( ( item ) => {
                                            return(
                                                <tr 
                                                    className=""
                                                    key={ item.id }
                                                >
                                                    {
                                                        keys.map( ( key ) => {
                                                            return(
                                                                <td
                                                                    className="whitespace-nowrap py-2 px-4 text-md text-gray-500 text-left lg:text-wrap"
                                                                    key={ key.key }
                                                                >
                                                                    {
                                                                        renderValue( item, key )
                                                                    }
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                                                        <span
                                                            className="cursor-pointer text-indigo-600 hover:text-indigo-900"
                                                            onClick={ () => detailsHandler( item.id ) }
                                                        >
                                                            Ver Detalles
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-end gap-1 py-4'>
                { pagination }
            </div>
        </div>
    );

}

export default Tables;