interface IProps {
    status: boolean;
}

const Status = ( props: IProps ) => {

    const status = props.status;

    let className = ( status === true ) ? 'text-green-400 bg-green-400/10' : 'text-rose-400 bg-rose-400/10';

    return(

        <div className="flex gap-2 items-center">
            <span className={ className }>
                <div className="h-2 w-2 rounded-full bg-current" />
            </span>
            <span>
                {
                    ( status === true ) ? 'Completado' : 'Pendiente'
                }
            </span>
        </div>

    );

}

export default Status;