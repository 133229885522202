import { ChangeEventHandler, MouseEventHandler } from "react";

interface IProps {

    state: string;
    isValid: boolean;
    buttonContent: string;
    searchHandler: Function;
    formChangeHandler: ChangeEventHandler<HTMLInputElement>;
    buttonAction: MouseEventHandler<HTMLButtonElement>;
    search?: boolean;

}

const Search = (props: IProps) => {
    
    const state             = props.state;
    const isValid           = props.isValid;
    const buttonContent     = props.buttonContent;
    const searchHandler     = props.searchHandler;
    const formChangeHandler = props.formChangeHandler;
    const buttonAction      = props.buttonAction;
    const search            = props.search;

    let className = "h-12 block w-full rounded-md border-gray-200 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6";

    if (!isValid)
        className = "h-12 block w-full rounded-md border-red-400 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6";

    if( typeof search === 'boolean' && search === false )
        className += ' hidden';

    const onKeyDownHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {

        if( evt.key === 'Enter' ){
            
            searchHandler( state );

        }

    }
    return (

        <div className="flex flex-col items-start gap-3 sm:flex-row sm:items-center">
            <input
                id="search"
                name="search"
                type="text"
                placeholder="Buscar"
                className={ className }
                value={ state }
                onChange={ formChangeHandler }
                onKeyDown={ onKeyDownHandler }
            />
            <button 
                className="w-40 sm:w-60 py-3 text-center rounded-md bg-indigo-600 text-sm text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={ buttonAction }
            >
                + {buttonContent}
            </button>
        </div>

    );
};

export default Search;
