import { JSX } from "react/jsx-runtime";
import Field, { TFieldType } from "../Dashboard/SlideForm/Field/Field";
import Separator from "../Dashboard/SlideForm/Separator/Separator";

export interface IFieldView {
    
    title: string;
    content: string;
    type: TFieldType;
    separator: boolean;

}

interface IProps {
    fields: IFieldView[];
}

const FormView = ( props: IProps ) => {
   
    const fields = props.fields;
    const formViewElements: any = [];

    fields.map(( field, index ) => {

        formViewElements.push(
            <Field 
                key={ 'index-field-' + index }
                title={ field.title }
                content={ field.content }
                type={ field.type }
            />
        );

        if( field.separator === true )
            formViewElements.push(
                <Separator key={ 'index-separator-' + index } />
            );

    })

    return (

        <div key="form-view">
            { formViewElements }
        </div>
    
    );

}

export default FormView;