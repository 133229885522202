import { useContext } from "react";
import TableFeature, { IFeatureSettings, ITableSettings } from "../../../Components/Features/TableFeature/TableFeature";
import Context from "../../../Components/Context/Context";
import { IState, IValidationField } from "../../../CustomHooks/useForm";
import { IFormFields } from "../../../Utils/forms";
import { IFieldView } from "../../../Components/Forms/FormView";

const Categories = () => {

    // Context
    const contextData = useContext( Context );

    // Feature settings
    const featureSettings: IFeatureSettings = {

        featureHelp: `Clasifica y organiza tu información política de manera efectiva. 
            Podrás personalizar y etiquetar tus datos según tus necesidades, facilitando la búsqueda y gestión de información relevante. 
            Desde perfiles de campaña hasta eventos y objetivos, este módulo te permite estructurar tu espacio político de manera intuitiva.
        `,
        featureName: 'Categorías',
        createButton: 'Crear categoría',
        slideTitle: 'Información de categorías'

    };

    // Initial State
    const initialState = {

        id: { value: '', isValid: true },
        title: { value: '', isValid: true },
        description: { value: '', isValid: true },
        created_at: { value: '', isValid: true },
        created_by: { value: '', isValid: true },
        updated_at: { value: '', isValid: true },
        updated_by: { value: '', isValid: true }

    };

    // Form configuration
    const formFields: IFormFields[] = [
        {
            fields: [
                {
                    type: 'text',
                    name: 'title',
                    label: 'Titulo',
                    placeholder: 'Titulo',
                    formElement: 'input',
                    options: null
                }
            ]
        },
        {
            fields: [
                {
                    type: 'text',
                    name: 'description',
                    label: 'Descripción',
                    placeholder: 'Descripción',
                    formElement: 'textarea',
                    options: null
                }
            ]
        }
    ];

    // Table settings
    
    const tableSettings: ITableSettings = {

        headers: [
            { label: 'Titulo', sortable: true, key: 'title' },
            { label: 'Creado', sortable: true, key: 'created_at' }
        ],
        keys: [
            { key: 'title', type: 'badge' },
            { key: 'created_at', type: 'date' }
        ]
        
    };

    // Funtions
    const getRequiredFields = () => {

        const requiredFields: IValidationField[] = [

            { label: 'Titulo', name: 'title', type: 'text' },
            { label: 'Descripción', name: 'description', type: 'text' }

        ];

        return requiredFields;

    }

    const getFieldView = ( values: IState ) => {

        const formView: IFieldView[] = [ 

            {
               title: 'Titulo',
               content: values.title.value,
               type: 'text',
               separator: true
            },
            {
               title: 'Descripción',
               content: values.description.value,
               type: 'text',
               separator: true
            },
            {
               title: 'Creado',
               content: values.created_at.value,
               type: 'date',
               separator: true
            },
            {
               title: 'Creado por',
               content: values.created_by.value,
               type: 'text',
               separator: true
            },
            {
               title: 'Actualizado',
               content: values.updated_at.value,
               type: 'date',
               separator: true
            },
            {
               title: 'Actualizado por',
               content: values.updated_by.value,
               type: 'text',
               separator: false
            }
   
       ];

       return formView;

    }

    const saveHandler = ( values: IState ) => {

        const newElement = {

            title: values.title.value,
            description: values.description.value,
            account: contextData.project,
            is_active: true

        }

        return newElement;

    }

    const openShowHandler = ( copyValues: IState, data: any ) => {

        copyValues.id.value          = data.id;
        copyValues.title.value       = data.title;
        copyValues.description.value = data.description;
        copyValues.created_at.value  = data.created_at;
        copyValues.created_by.value  = `${data.created_by.first_name} ${data.created_by.last_name}`;
        copyValues.updated_at.value  = data.updated_at;
        copyValues.updated_by.value  = `${data.updated_by.first_name} ${data.updated_by.last_name}`;

        return copyValues;

    }

    const updateHandler = ( values: IState ) => {

        const updateElement = {

            title: values.title.value,
            description: values.description.value,
            account: contextData.project,
            is_active: true

        }


        return updateElement;

    }

    // Return the Table Feature Module
    return (

        <TableFeature
            featureSettings={ featureSettings }
            editAction={ true }
            elements={ null }
            endPoints={
                {
                    get: `/categories/${contextData.project}/`,
                    retrieve: `/categories/${contextData.project}/`,
                    post: `/categories/${contextData.project}/`,
                    put: `/categories/${contextData.project}/`,
                    delete: `/categories/${contextData.project}/`
                }
            }
            initialState={ initialState }
            getRequiredFields={ getRequiredFields }
            getFieldView={ getFieldView }
            saveHanlder={ saveHandler }
            openShowHandler={ openShowHandler }
            updateHandler={ updateHandler }
            formFields={ formFields }
            settings={{
                campaign_profile: false,
                category: false,
                is_completed: false
            }}
            table={ tableSettings }
        />
        
    );

};

export default Categories;