const Separator = () => {

    return(
        <div className="py-6">
            <hr></hr>
        </div>
    );

}

export default Separator;