import { useContext, useEffect, useState } from 'react';
import LauncherIcon from '../../../Images/launch.png';
import Loader from '../../../Components/Loader/Loader';
import useHttp from '../../../CustomHooks/useHttp';
import Context from '../../../Components/Context/Context';

interface IPlan {

    id: string;
    title: string;
    amount: string;

}

const Month = () => {

    // Context
    const contextData = useContext( Context );
    // FetchAPI customHook
    const { fetchAPI } = useHttp();

    // States required for the UI
    const [ openFullScreenLoader, setOpenFullScreenLoader ] = useState< boolean >( true );
    const [ plan, setPlan ] = useState< IPlan | null >( null );
    const [ payment, setPayment ] = useState< boolean >( false );

    useEffect(() => {

        const makePayment = async() => {

            const accountId = contextData.project;

            if( contextData.token !== null ){

                const paymentsResponse = await fetchAPI( `/payments/${accountId}/?search=is_completed=False`, 'get', null, contextData.token );

                if( paymentsResponse.status === 200 ){

                    const elements = paymentsResponse.data.elements;
                    
                    if( elements.length === 0 )
                        window.location.href = '/suscripcion';
                    else{
                        
                        const plan: IPlan = paymentsResponse.data.elements[ 0 ];

                        const updateElement = {

                            account: accountId,
                            is_completed: true

                        }

                        const response = await fetchAPI( `/payments/${accountId}/${plan.id}/`, 'patch', updateElement, contextData.token );

                        setPayment( true );
                        setPlan( plan );

                        setTimeout(function() {
                            window.location.href = '/';
                        }, 5000);
                        
                    }

                }
                else{
                    setOpenFullScreenLoader( false );
                }

            }
            
        }

        if( payment === false && contextData.project !== null )
            makePayment();

    },[]);

    return(

        ( payment === false && plan === null ) 
            ?
                <Loader fullScreen={ true } open={ openFullScreenLoader } />
            :
                <div className="flex flex-col items-center justify-center h-screen">
                    <img className='pb-4' width="140" src={ LauncherIcon } />
                    <p className="font-semibold text-2xl text-gray-500 text-center">{ plan?.title }</p>
                    <p className='text-gray-500 text-center'>Pago realizado exitosamente, espera un momento te redireccionaremos a tu panel...</p>
                </div>
    );

}

export default Month;