import {
    CheckIcon
} from '@heroicons/react/20/solid';
import { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import politicsoft from '../../Images/logo_pic_small.png';
import FullWidthWithPaddingContainer from '../../Containers/FullWidthWithPaddingContainer/FullWidthWithPaddingContainer';
import Input from '../../Components/Forms/Input';
import useHttp from '../../CustomHooks/useHttp';
import Select from '../../Components/Forms/Select';
import useForm, { IValidationField } from '../../CustomHooks/useForm';
import Errors from '../../Components/Errors/Errors';
import Modal from '../../Components/Modal/Modal';
import Loader from '../../Components/Loader/Loader';

interface IDropdownOptions {

    id: string;
    title: string;

}

interface IPoliticParty extends IDropdownOptions {}
interface IConuntryState extends IDropdownOptions {}
interface IUsersPlan extends IDropdownOptions {}


function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

const Register = () => {
    
    const { fetchAPI } = useHttp();
    const [politicParties, setPoliticParties] = useState< IPoliticParty[] >( [] );
    const [entities, setEntities]   = useState< IConuntryState[] >([]);
    const [usersPlan, setUsersPlan] = useState<IUsersPlan[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [openLoader, setOpenLoader] = useState(false);
    const [ tiers, setTiers ] = useState<any[]>([]);

    const [steps, setSteps] = useState([
        { id: '1', name: 'Datos del Owner', status: 'current' },
        { id: '2', name: 'Espacio Político', status: 'upcoming' },
        { id: '3', name: 'Elegir Plan', status: 'upcoming' },
    ]);

    const stepOne = useRef<HTMLDivElement>(null);
    const stepTwo = useRef<HTMLDivElement>(null);
    const stepThree = useRef<HTMLDivElement>(null);

    const {
        values,
        formChangeHandler,
        formValidationHandler,
        errors,
        setErrors,
    } = useForm({
        first_name: {
            value: '',
            isValid: true,
        },
        last_name: {
            value: '',
            isValid: true,
        },
        email: {
            value: '',
            isValid: true,
        },
        mobile_phone: {
            value: '',
            isValid: true,
        },
        password: {
            value: '',
            isValid: true,
        },
        re_password: {
            value: '',
            isValid: true,
        },
        registration_code: {
            value: '',
            isValid: true
        },
        title: {
            value: '',
            isValid: true
        },
        politic_party: {
            value: '-1',
            isValid: true
        },
        state: {
            value: '-1',
            isValid: true
        }
    });

    useEffect(() => {
        const getPoliticParties = async () => {
            try {
                const response = await fetchAPI('/politic_party/', 'get');
                setPoliticParties(response.data);
            } catch (error) {
                alert('Error');
            }
        };

        const getEntities = async () => {
            try {
                const response = await fetchAPI('/country_states/', 'get');
                setEntities(response.data);
            } catch (error) {
                alert('Error');
            }
        };

        const getUsersPlan = async () => {

            try {
                
                const response = await fetchAPI('/users_plan/', 'get');
                let tiersPlans = []

                for( let plan of response.data ){

                    let aditionalUsers = '';
                    let popular = false;

                    if( plan.max_users === 1 ){
                        aditionalUsers = '1 Usuario adicional';
                    }
                    else{
                        aditionalUsers = plan.max_users + ' Usuarios adicionales';
                    }

                    if( plan.title === 'Politicsoft Jaguar' || plan.title === 'Politicsoft Colibrí' )
                        popular = true;

                    tiersPlans.push({
                        name: plan.title,
                        id: plan.id,
                        price: '$'+plan.price,
                        description: 'GRATIS DURANTE 7 DÍAS',
                        features: [
                            aditionalUsers,
                            plan.storage+' GB de almacenamiento (Archivos).',
                            'Calendario de eventos.',
                            'Tareas logísticas.',
                            'Categorización.',
                            'Perfiles de Campaña/Gobierno.',
                            'Objetivos de Campaña/Gobierno.',
                            'Inteligencia Artificial.',
                        ],
                        mostPopular: popular,
                    });

                }
                setUsersPlan(response.data);
                setTiers(tiersPlans);
            } catch (error) {
                alert('Error');
            }

        }

        if (politicParties.length === 0) {
            getPoliticParties();
        }

        if (entities.length === 0) {
            getEntities();
        }

        if( usersPlan.length === 0 ) {
            getUsersPlan();
        }

    }, [politicParties, entities]);

    const chooseUserPlanHandler = async( user_plan:string ) => {

        setOpenLoader( true );

        const selectedPlan = usersPlan.find( ( plan: IUsersPlan ) => plan.title === user_plan );

        try{

            const newUser = {

                email: values.email.value,
                first_name: values.first_name.value,
                last_name: values.last_name.value,
                username: values.email.value,
                mobile_phone: values.mobile_phone.value,
                password: values.password.value
    
            }

            const createdUserResponse = await fetchAPI( '/users/create', 'post', newUser );
            const createdUser         = createdUserResponse.data;

            const newAccount = {

                title: values.title.value,
                user: createdUser.id,
                politic_party: values.politic_party.value,
                users_plan: selectedPlan?.id,
                state: values.state.value,
                registration_code: values.registration_code.value

            }

            const createdAccountResponse = await fetchAPI( '/accounts/', 'post', newAccount );

            if( createdAccountResponse.status === 201 ){
                setOpenLoader( false );
                setOpenModal( true );
            }
            else{
                
                const stepsCopy = [ ...steps ];
                values.email.isValid = false;
                setErrors( [ 'Ocurrio un error, por favor intente nuevamente.' ] );

                stepOne.current?.classList.remove('hidden');
                stepTwo.current?.classList.add('hidden');
                stepThree.current?.classList.add('hidden');
    
                stepsCopy[0].status = 'current';
                stepsCopy[1].status = 'upcoming';
                stepsCopy[2].status = 'upcoming';
                
                setOpenLoader( false );
                setSteps(stepsCopy);

            }

        }
        catch( err: any ){
            
            if( err.response.data.email ){

                const stepsCopy = [ ...steps ];
                values.email.isValid = false;
                setOpenLoader( false );
                setErrors( [ err.response.data.email ] );
                
                stepOne.current?.classList.remove('hidden');
                stepTwo.current?.classList.add('hidden');
                stepThree.current?.classList.add('hidden');
    
                stepsCopy[0].status = 'current';
                stepsCopy[1].status = 'upcoming';
                stepsCopy[2].status = 'upcoming';
    
                setSteps(stepsCopy);

            }
            else{

                const stepsCopy = [ ...steps ];
                values.email.isValid = false;
                setErrors( [ 'Ocurrio un error, por favor intente nuevamente.' ] );

                stepOne.current?.classList.remove('hidden');
                stepTwo.current?.classList.add('hidden');
                stepThree.current?.classList.add('hidden');
    
                stepsCopy[0].status = 'current';
                stepsCopy[1].status = 'upcoming';
                stepsCopy[2].status = 'upcoming';
    
                setSteps(stepsCopy);
        
            }

        }

    }

    const modalActionHandler = () => {
        window.location.href = '/';
    }

    const nextStepHandler = async (step: number) => {
        if (step === 0) {
            const stepsCopy = [...steps];

            const requiredFields: IValidationField[] = [
                {
                    name: 'first_name',
                    label: 'Nombre',
                    type: 'text',
                },
                {
                    name: 'last_name',
                    label: 'Apellidos',
                    type: 'text',
                },
                {
                    name: 'email',
                    label: 'Email',
                    type: 'email',
                },
                {
                    name: 'mobile_phone',
                    label: 'Número móvil',
                    type: 'mobile',
                },
                {
                    name: 'password',
                    label: 'Contraseña',
                    type: 'text',
                },
                {
                    name: 're_password',
                    label: 'Confirmar contraseña',
                    type: 'text',
                },
                {
                    name: 'registration_code',
                    label: 'Codigo de regístro',
                    type: 'text',
                }

            ];

            if (formValidationHandler(requiredFields)) {

                try{

                    if( values.password.value !== values.re_password.value ) {
                        values.password.isValid    = false;
                        values.re_password.isValid = false;
                        setErrors(['Las contraseñas no coinciden']);
                        return;
                    }
                    else{   

                        setOpenLoader( true );

                        const verify_registration_code = {
                            registration_code : values.registration_code.value
                        };
                        const response = await fetchAPI( '/accounts/verify_registration_code/', 'post', verify_registration_code );

                        setOpenLoader( false );

                        if( response.data.verified === true ){

                            stepOne.current?.classList.add('hidden');
                            stepTwo.current?.classList.remove('hidden');
                
                            stepsCopy[0].status = 'complete';
                            stepsCopy[1].status = 'current';
                
                            setSteps(stepsCopy);

                        }
                        else{
                            setErrors(['El codigo de regístro es invalido']);
                        }

                    }

                }
                catch( err ){
                    setErrors(['El codigo de regístro es invalido']);
                }

                return false;

            }

        } else if (step === 1) {
            const stepsCopy = [...steps];

            const requiredFields: IValidationField[] = [
                {
                    name: 'title',
                    label: 'Espacio político',
                    type: 'text',
                },
                {
                    name: 'politic_party',
                    label: 'Partido político',
                    type: 'dropdown',
                },
                {
                    name: 'state',
                    label: 'Entidad federativa',
                    type: 'dropdown',
                }

            ];

            if (formValidationHandler(requiredFields)) {

                stepTwo.current?.classList.add('hidden');
                stepThree.current?.classList.remove('hidden');
    
                steps[1].status = 'complete';
                steps[2].status = 'current';
    
                setSteps(stepsCopy);

            }

        }
    };

    return (
        <>
            <div className='mx-auto max-w-7xl pt-8 pb-4 md:pb-0 pb-1 px-3 sm:px-5'>
                <div className='flex h-16 justify-between'>
                    <div className='flex'>
                        <div className='flex flex-shrink-0 items-center'>
                            <img
                                className='w-auto'
                                src={politicsoft}
                                alt='Politicsoft'
                            />
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row items-center gap-2 md:gap-4'>
                        <p className='text-sm'>¿Ya tienes cuenta?</p>
                        <NavLink to='/'>
                            <button
                                type='button'
                                className='border-2 rounded-md px-3 py-2 text-sm shadow-sm'
                            >
                                Iniciar Sesión
                            </button>
                        </NavLink>
                    </div>
                </div>
            </div>
            <nav
                className='bg-gray-100 rounded-md border border-gray-300'
                aria-label='Progress'
            >
                <ol
                    role='list'
                    className='justify-center divide-y divide-gray-300 md:flex md:divide-y-0'
                >
                    {steps.map((step, stepIdx) => (
                        <li
                            key={step.name}
                            className='relative justify-center md:flex'
                        >
                            {step.status === 'complete' ? (
                                <span className='group flex w-full items-center'>
                                    <span className='flex items-center px-6 py-4 text-sm font-medium'>
                                        <span className='flex text-white h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-black group-hover:bg-indigo-800'>
                                            {step.id}
                                        </span>
                                        <span className='ml-4 text-sm font-medium text-gray-900'>
                                            {step.name}
                                        </span>
                                    </span>
                                </span>
                            ) : step.status === 'current' ? (
                                <span
                                    className='flex items-center px-6 py-4 text-sm font-medium'
                                    aria-current='step'
                                >
                                    <span className='bg-indigo-600 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600'>
                                        <span className='text-white'>
                                            {step.id}
                                        </span>
                                    </span>
                                    <span className='ml-4 text-sm font-medium text-indigo-600'>
                                        {step.name}
                                    </span>
                                </span>
                            ) : (
                                <span className='group flex items-center'>
                                    <span className='flex items-center px-6 py-4 text-sm font-medium'>
                                        <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400'>
                                            <span className='text-gray-500 group-hover:text-gray-900'>
                                                {step.id}
                                            </span>
                                        </span>
                                        <span className='ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900'>
                                            {step.name}
                                        </span>
                                    </span>
                                </span>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>
            <div className='step-one' ref={stepOne}>
                <FullWidthWithPaddingContainer>
                    <div className='py-12 w-full mx-auto md:w-10/12 lg:w-8/12'>
                        <form>
                            <h2 className='text-2xl font-semibold mb-1'>
                                Completa los datos de Owner
                            </h2>
                            <p className='text-gray-500 mb-3 text-sm'>
                                Llena tus datos como propietario del espacio
                                político.
                            </p>
                            <Errors
                                title='Errores en datos del Owner'
                                errors={errors}
                            />
                            <div className='flex flex-col mt-4 sm:flex-row gap-4'>
                                <div className='w-full'>
                                    <Input
                                        type='text'
                                        name='first_name'
                                        label='Nombre'
                                        placeholder='Nombre'
                                        state={values.first_name.value}
                                        isValid={values.first_name.isValid}
                                        formChangeHandler={(evt) =>
                                            formChangeHandler('first_name', evt.target.value)
                                        }
                                    />
                                </div>
                                <div className='w-full'>
                                    <Input
                                        type='text'
                                        name='last_name'
                                        label='Apellidos'
                                        placeholder='Apellidos'
                                        state={values.last_name.value}
                                        isValid={values.last_name.isValid}
                                        formChangeHandler={(evt) =>
                                            formChangeHandler('last_name', evt.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col sm:flex-row gap-4'>
                                <div className='w-full'>
                                    <Input
                                        type='email'
                                        name='email'
                                        label='Email'
                                        placeholder='Email'
                                        state={values.email.value}
                                        isValid={values.email.isValid}
                                        formChangeHandler={(evt) =>
                                            formChangeHandler('email', evt.target.value)
                                        }
                                    />
                                </div>
                                <div className='w-full'>
                                    <Input
                                        type='text'
                                        name='phone'
                                        label='Número móvil'
                                        placeholder='10 digitos'
                                        state={values.mobile_phone.value}
                                        isValid={values.mobile_phone.isValid}
                                        formChangeHandler={(evt) =>
                                            formChangeHandler('mobile_phone', evt.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col sm:flex-row gap-4'>
                                <div className='w-full'>
                                    <Input
                                        type='password'
                                        name='password'
                                        label='Crear contraseña'
                                        placeholder='Crear contraseña'
                                        state={values.password.value}
                                        isValid={values.password.isValid}
                                        formChangeHandler={(evt) =>
                                            formChangeHandler('password', evt.target.value)
                                        }
                                    />
                                </div>
                                <div className='w-full'>
                                    <Input
                                        type='password'
                                        name='re_password'
                                        label='Confirmar contraseña'
                                        placeholder='Confirmar contraseña'
                                        state={values.re_password.value}
                                        isValid={values.re_password.isValid}
                                        formChangeHandler={(evt) =>
                                            formChangeHandler('re_password', evt.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <Input
                                    type='text'
                                    name='registration_code'
                                    label='Codigo de regístro'
                                    placeholder='Proporcionado por el equipo de Politicsoft'
                                    state={values.registration_code.value}
                                    isValid={values.registration_code.isValid}
                                    formChangeHandler={(evt) =>
                                        formChangeHandler('registration_code', evt.target.value)
                                    }
                                />
                            </div>
                            <div className='py-8'>
                                <p className='text-gray-500 mb-3 text-sm'>
                                    Al continuar, aceptas nuestras{' '}
                                    <span className='text-indigo-600 underline'>
                                        Condiciones de uso{' '}
                                    </span>
                                    y admites haber leído nuestro{' '}
                                    <span className='text-indigo-600 underline'>
                                        Aviso de privacidad
                                    </span>
                                    .
                                </p>
                            </div>
                            <button
                                onClick={() => nextStepHandler(0)}
                                type='button'
                                className='mt-6 text-center w-full rounded-md bg-indigo-600 p-2 text-sm text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                            >
                                Continuar
                            </button>
                        </form>
                    </div>
                </FullWidthWithPaddingContainer>
            </div>
            <div className='step-two hidden' ref={stepTwo}>
                <FullWidthWithPaddingContainer>
                    <div className='py-12 w-full mx-auto md:w-10/12 lg:w-8/12'>
                        <form>
                            <h2 className='text-2xl font-semibold mb-1'>
                                Información de tu Espacio Político
                            </h2>
                            <p className='text-gray-500 mb-3 text-sm'>
                                Crea y personaliza tu espacio político.
                            </p>
                            <Errors
                                title='Errores en datos del Espacio Político'
                                errors={errors}
                            />
                            <div className='flex flex-col mt-4'>
                                <div className='w-full'>
                                    <Input
                                        type='text'
                                        name='title'
                                        label='Nombre de Espacio Político'
                                        placeholder='Nombre de Espacio Político'
                                        state={values.title.value}
                                        isValid={values.title.isValid}
                                        formChangeHandler={(evt) =>
                                            formChangeHandler('title', evt.target.value)
                                        }
                                    />
                                </div>
                                <div className='w-full'>
                                    <Select
                                        options={politicParties}
                                        name='politic_party'
                                        label='Partido político'
                                        state={values.politic_party.value}
                                        isValid={values.politic_party.isValid}
                                        formChangeHandler={(evt) =>
                                            formChangeHandler('politic_party', evt.target.value)
                                        }
                                    />
                                </div>
                                <div className='w-full'>
                                    <Select
                                        options={entities}
                                        name='state'
                                        label='Entidad federativa'
                                        state={values.state.value}
                                        isValid={values.state.isValid}
                                        formChangeHandler={(evt) =>
                                            formChangeHandler('state', evt.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <button
                                onClick={() => nextStepHandler(1)}
                                type='button'
                                className='mt-6 text-center w-full rounded-md bg-indigo-600 p-2 text-sm text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                            >
                                Continuar
                            </button>
                        </form>
                    </div>
                </FullWidthWithPaddingContainer>
            </div>
            <div className='step-three hidden' ref={stepThree}>
                <FullWidthWithPaddingContainer>
                    <div className='py-12 w-full text-center'>
                        <h2 className='text-2xl font-semibold mb-1'>
                            Impulsa tu campaña política. Empieza gratis por 7
                            días.
                        </h2>
                        <p className='text-gray-500 mb-3 text-sm'>
                            No te preocupes, te enviaremos un correo electrónico
                            antes de que termine el periodo de prueba.
                        </p>
                        <div className='plans'>
                            <div className='isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-4 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
                                {tiers.map((tier) => (
                                    <div
                                        key={tier.id}
                                        className={classNames(
                                            tier.mostPopular
                                                ? 'ring-2 ring-indigo-600'
                                                : 'ring-1 ring-gray-200',
                                            'rounded-3xl p-8 xl:p-10'
                                        )}
                                    >
                                        <div className='flex items-center justify-between gap-x-4'>
                                            <h3
                                                id={tier.id}
                                                className={classNames(
                                                    tier.mostPopular
                                                        ? 'text-indigo-600'
                                                        : 'text-gray-900',
                                                    'text-lg font-semibold leading-8'
                                                )}
                                            >
                                                {tier.name}
                                            </h3>
                                            {tier.mostPopular ? (
                                                <p className='rounded-full bg-indigo-600/10 px-2.5 py-1 text-sm font-semibold leading-5 text-indigo-600'>
                                                    ¡Popular!
                                                </p>
                                            ) : null}
                                        </div>
                                        <p className='mt-1 text-sm text-left leading-6 text-indigo-600'>
                                            {tier.description}
                                        </p>
                                        <p className='mt-6 flex items-baseline gap-x-1'>
                                            <span className='text-4xl font-bold tracking-tight text-gray-900'>
                                                {tier.price}
                                            </span>
                                            <span className='text-sm font-semibold leading-6 text-gray-600'>
                                                Mensuales + IVA
                                            </span>
                                        </p>
                                        <span
                                            aria-describedby={tier.id}
                                            className={classNames(
                                                'bg-indigo-600 text-white shadow-sm hover:bg-indigo-600 cursor-pointer',
                                                'mt-6 block rounded-md py-2 px-3 text-center text-sm leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                            )}
                                            onClick={() => chooseUserPlanHandler(tier.name)}
                                        >
                                            Comienza tu prueba gratuita
                                        </span>
                                        <span className='text-gray-500 text-sm'>
                                            Cancela cuando quieras
                                        </span>
                                        <ul
                                            role='list'
                                            className='mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10'
                                        >
                                            {tier.features.map((feature: any) => (
                                                <li
                                                    key={feature}
                                                    className='flex gap-x-3'
                                                >
                                                    <CheckIcon
                                                        className='h-6 w-5 flex-none text-indigo-600'
                                                        aria-hidden='true'
                                                    />
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </FullWidthWithPaddingContainer>
            </div>
            <Modal
                title='Enlace enviado al correo electrónico'
                content='Hemos enviado un enlace de confirmación a su correo electrónico. Por favor, confirme su correo electrónico para completar el proceso de incorporación al Espacio Político.'
                buttonText='Continuar'
                icon='email'
                open={openModal}
                setOpen={setOpenModal}
                buttonAction={modalActionHandler}
                cancelButton={ false }
            />
            <Loader fullScreen={ true } open={ openLoader } />
        </>
    );
};

export default Register;
