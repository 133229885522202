import { Fragment, MouseEventHandler, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface IProps {

    title: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    save: MouseEventHandler<HTMLButtonElement> | null;
    clearFields: Function | null;
    disableSave: boolean | null;
    children: React.ReactNode;

}

const SlideForm = ( props: IProps ) => {

    const title       = props.title;
    const open        = props.open;
    const setOpen     = props.setOpen;
    const save        = props.save;
    const disableSave = props.disableSave;
    const clearFields = props.clearFields;

    const cancelButtonHandler = () => {

        if( clearFields !== null )
           clearFields();

        setOpen( false );

    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={setOpen}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                    <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                        <div className="flex-1">
                                            {/* Header */}
                                            <div className="bg-gray-50 px-4 py-8 sm:px-6">
                                                <div className="flex items-start justify-between space-x-3">
                                                    <div className="space-y-1">
                                                        <Dialog.Title className="text-lg font-semibold leading-6 text-gray-900">
                                                            { title }
                                                        </Dialog.Title>
                                                    </div>
                                                    <div className="flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="relative text-gray-400 hover:text-gray-500"
                                                            onClick={() =>
                                                                setOpen(false)
                                                            }
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">
                                                                Close panel
                                                            </span>
                                                            <XMarkIcon
                                                                className="h-6 w-6"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                                                <div className="px-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                    { props.children }
                                                    <div className="clear-both h-5"></div>
                                                    {
                                                        save !== null && disableSave !== null
                                                        ?
                                                            <div className="flex gap-4">
                                                                <button 
                                                                    type="button" 
                                                                    className="bg-indigo-600 text-white rounded px-4 py-2"
                                                                    onClick={ save }
                                                                    disabled={ disableSave }
                                                                >
                                                                    Guardar cambios
                                                                </button>
                                                                <button
                                                                    type="button" 
                                                                    className="border border-gray-300 rounded px-4 py-2"
                                                                    onClick={ cancelButtonHandler }
                                                                >
                                                                    Cancelar
                                                                </button>
                                                            </div>
                                                        : 
                                                            null
                                                        }
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default SlideForm;