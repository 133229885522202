import { Fragment, ReactElement, useContext, useState } from "react";
import Context from '../../Components/Context/Context';
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
    Bars3Icon,
    BellIcon,
    Cog6ToothIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import {
    ChevronDownIcon
} from "@heroicons/react/20/solid";

import politicsoft_logo from '../../Images/logo_light.png';
import { NavLink, useLocation } from "react-router-dom";
import sidebarHome from '../../Images/sidebar_home.svg';
import sidebarHomeActive from '../../Images/sidebar_home_active.svg';
import sidebarCalendar from '../../Images/sidebar_calendar.svg';
import sidebarCalendarActive from '../../Images/sidebar_calendar_active.svg';
import sidebarLogistic from '../../Images/sidebar_logistic.svg';
import sidebarLogisticActive from '../../Images/sidebar_logistic_active.svg';
import sidebarCategories from '../../Images/sidebar_categories.svg';
import sidebarCategoriesActive from '../../Images/sidebar_categories_active.svg';
import sidebarProfiles from '../../Images/sidebar_profiles.svg';
import sidebarProfilesActive from '../../Images/sidebar_profiles_active.svg';
import sidebarObjetives from '../../Images/sidebar_objetives.svg';
import sidebarObjetivesActive from '../../Images/sidebar_objetives_active.svg';
import sidebarFiles from '../../Images/sidebar_files.svg';
import sidebarFilesActive from '../../Images/sidebar_files_active.svg';
import sibarAi from '../../Images/sidebar_ai.svg';
import sidebarAiActive from '../../Images/sidebar_ai_active.svg';

import { daysUntilDate } from '../../Utils/dates';

interface IDashboardContainerProps {
    menuActive: number | null;
    children: ReactElement;   
}

const userNavigation = [
    { name: "Cerrar sesión", href: '#', function: () => {localStorage.clear();window.location.href='/'} },
];

function classNames(...classes:any) {

    return classes.filter(Boolean).join(" ");

}

const DashboardContainer = ( props: IDashboardContainerProps ) => {

    const location = useLocation();

    const [ sidebarOpen, setSidebarOpen ] = useState< boolean >( false );
    const contextData = useContext( Context );

    const menuActive = props.menuActive;

    const activeIcons = [

        sidebarHomeActive,
        sidebarCalendarActive,
        sidebarLogisticActive,
        sidebarCategoriesActive,
        sidebarProfilesActive,
        sidebarObjetivesActive,
        sidebarFilesActive,
        sidebarAiActive

    ];

    let navigation = [

        { name: "Resumen", href: "/", icon: sidebarHome, current: false },
        { name: "Calendario Eventos", href: "/calendario-eventos", icon: sidebarCalendar, current: false },
        { name: "Tareas Logísticas", href: "/tareas-logisticas", icon: sidebarLogistic, current: false },
        { name: "Categorías", href: "/categorias", icon: sidebarCategories, current: false },
        { name: "Perfiles", href: "/perfiles", icon: sidebarProfiles, current: false },
        { name: "Objetivos", href: "/objetivos", icon: sidebarObjetives, current: false },
        { name: "Archivos", href: "/archivos", icon: sidebarFiles, current: false },
        { name: "Inteligencia Artificial", href: "/inteligencia-artificial", icon: sibarAi, current: false }

    ];
    
    let days: number = 0;

    if( contextData.isTrial && contextData.trialEnd !== null )
        days = daysUntilDate( contextData.trialEnd, 'YYYY-MM-DD' );
    else if( !contextData.isTrial && contextData.monthEnd )
        days = daysUntilDate( contextData.monthEnd, 'YYYY-MM-DD' );
    else 
        days = daysUntilDate( new Date(), 'YYYY-MM-DD' );

    //const days = daysUntilDate( contextData.trialEnd || new Date(), 'YYYY-MM-DD' );

    if( menuActive !== null){
    
        navigation[ menuActive ].current = true;
        navigation[ menuActive ].icon = activeIcons[ menuActive ];
    
    }

    if( contextData.isTrial && contextData.trialEnd !== null && days < 0 ){

        if( location.pathname !== '/suscripcion' && contextData.isOwnerAccount )
            window.location.href = '/suscripcion';
        else if( location.pathname !== '/mi-cuenta' && !contextData.isOwnerAccount )
            window.location.href = '/mi-cuenta';

        navigation = [];
        
    }
    else if( !contextData.isTrial && contextData.monthEnd !== null && days < 0 ){

        if( location.pathname !== '/suscripcion' && contextData.isOwnerAccount )
            window.location.href = '/suscripcion';
        else if( location.pathname !== '/mi-cuenta' && !contextData.isOwnerAccount )
            window.location.href = '/mi-cuenta';

        navigation = [];

    }

    return (
        <>
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-50 lg:hidden"
                        onClose={setSidebarOpen}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                            <button
                                                type="button"
                                                className="-m-2.5 p-2.5"
                                                onClick={() =>
                                                    setSidebarOpen(false)
                                                }
                                            >
                                                <span className="sr-only">
                                                    Close sidebar
                                                </span>
                                                <XMarkIcon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    {/* Sidebar component, swap this element with another sidebar if you like */}
                                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 pt-4 ring-1 ring-white/10">
                                        <div className="flex h-16 shrink-0 items-center">
                                            <img
                                                className="h-8 w-auto"
                                                src={politicsoft_logo}
                                                alt="Politicsoft"
                                            />
                                        </div>
                                        <nav className="flex flex-1 flex-col">
                                            <ul
                                                role="list"
                                                className="flex flex-1 flex-col gap-y-7"
                                            >
                                                <li>
                                                    <ul
                                                        role="list"
                                                        className="-mx-2 space-y-1"
                                                    >
                                                        {navigation.map(
                                                            (item) => (
                                                                <li
                                                                    key={
                                                                        item.name
                                                                    }
                                                                >
                                                                    <NavLink
                                                                        to={
                                                                            item.href
                                                                        }
                                                                        className={classNames(
                                                                            item.current
                                                                                ? "bg-gray-800 font-semibold text-gray-300"
                                                                                : "text-gray-300 hover:text-white hover:bg-gray-800",
                                                                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6"
                                                                        )}
                                                                    >
                                                                        <img
                                                                            className="h-6 w-6 shrink-0"
                                                                            aria-hidden="true"
                                                                            src={ item.icon }
                                                                        />
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </NavLink>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </li>
                                                <li className="mt-auto">
                                                    {/* <a
                                                        href="#"
                                                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                                                    >
                                                        <Cog6ToothIcon
                                                            className="h-6 w-6 shrink-0"
                                                            aria-hidden="true"
                                                        />
                                                        Ayuda
                                                    </a> */}

                                                    {
                                                        ( contextData.isOwnerAccount && !contextData.isTrial && ( contextData.operators !== null && contextData.operators > 0) )
                                                            ?
                                                            <NavLink
                                                                to="/operadores"
                                                                className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                                                            >
                                                                <Cog6ToothIcon
                                                                    className="h-6 w-6 shrink-0"
                                                                    aria-hidden="true"
                                                                />
                                                                Operadores
                                                            </NavLink>
                                                            :
                                                                null
                                                    }
                                                    {
                                                        ( contextData.isOwnerAccount && contextData.isTrial && days > 0 && ( contextData.operators !== null && contextData.operators > 0) )
                                                            ?
                                                            <NavLink
                                                                to="/operadores"
                                                                className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                                                            >
                                                                <Cog6ToothIcon
                                                                    className="h-6 w-6 shrink-0"
                                                                    aria-hidden="true"
                                                                />
                                                                Operadores
                                                            </NavLink>
                                                            :
                                                            null
                                                    }
                                                    
                                                    
                                                    <NavLink
                                                        to="/mi-cuenta"
                                                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                                                    >
                                                        <Cog6ToothIcon
                                                            className="h-6 w-6 shrink-0"
                                                            aria-hidden="true"
                                                        />
                                                        Mi cuenta
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 pt-4">
                        <div className="flex h-16 shrink-0 items-center">
                            <img
                                className="h-8 w-auto"
                                src={politicsoft_logo}
                                alt="Politicsoft"
                            />
                        </div>
                        <nav className="flex flex-1 flex-col">
                            <ul
                                role="list"
                                className="flex flex-1 flex-col gap-y-7"
                            >
                                <li>
                                    <ul role="list" className="-mx-2 space-y-1">
                                        {navigation.map((item) => (
                                            <li key={item.name}>
                                                <NavLink
                                                    to={item.href}
                                                    className={classNames(
                                                        item.current
                                                            ? "bg-gray-800 font-semibold text-gray-300"
                                                            : "text-gray-300 hover:text-white hover:bg-gray-800",
                                                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6"
                                                    )}
                                                >
                                                    <img
                                                        className="h-6 w-6 shrink-0"
                                                        aria-hidden="true"
                                                        src={ item.icon }
                                                    />
                                                    {item.name}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li className="mt-auto">
                                    {/* <a
                                        href="#"
                                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                                    >
                                        <Cog6ToothIcon
                                            className="h-6 w-6 shrink-0"
                                            aria-hidden="true"
                                        />
                                        Ayuda
                                    </a> */}
                                    {
                                        ( contextData.isOwnerAccount && !contextData.isTrial && ( contextData.operators !== null && contextData.operators > 0) )
                                            ?
                                                <NavLink
                                                    to="/operadores"
                                                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                                                >
                                                    <Cog6ToothIcon
                                                        className="h-6 w-6 shrink-0"
                                                        aria-hidden="true"
                                                    />
                                                    Operadores
                                                </NavLink>
                                            : 
                                                null
                                    }
                                                                        {
                                        ( contextData.isOwnerAccount && contextData.isTrial && days > 0 && ( contextData.operators !== null && contextData.operators > 0) )
                                            ?
                                                <NavLink
                                                    to="/operadores"
                                                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                                                >
                                                    <Cog6ToothIcon
                                                        className="h-6 w-6 shrink-0"
                                                        aria-hidden="true"
                                                    />
                                                    Operadores
                                                </NavLink>
                                            : 
                                                null
                                    }

                                    <NavLink
                                        to="/mi-cuenta"
                                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                                    >
                                        <Cog6ToothIcon
                                            className="h-6 w-6 shrink-0"
                                            aria-hidden="true"
                                        />
                                        Mi cuenta
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="lg:pl-72">
                    {
                        (( days >= 1 && days <= 3 ) && contextData.isOwnerAccount && contextData.isTrial )
                        ? 
                            <div className="bg-gradient-to-r from-indigo-400 to-indigo-800 flex justify-center py-6 text-white text-lg gap-3 items-center">
                                <span>Tu prueba gratuita expira en { days } días. Actualiza para seguir disfrutando de Politicsoft.</span>
                                    <NavLink className="" to="/suscripcion" >
                                        <div className="bg-gray-200 border rounded-md px-4 py-1 text-black">
                                            Ver suscripción
                                        </div>
                                    </NavLink>
                            </div>
                        :
                            null
                    }
                    {
                        ( days < 0 && contextData.isOwnerAccount && contextData.isTrial )
                        ? 
                            <div className="bg-gradient-to-r from-indigo-400 to-indigo-800 flex justify-center py-6 text-white text-lg gap-3 items-center">
                                <span>Tu prueba gratuita ha expirado. Actualiza para seguir disfrutando de Politicsoft.</span>
                                    <NavLink className="" to="/suscripcion" >
                                        <div className="bg-gray-200 border rounded-md px-4 py-1 text-black">
                                            Ver suscripción
                                        </div>
                                    </NavLink>
                            </div>
                        :
                            null
                    }
                    {
                        ( days < 0 && contextData.isOwnerAccount && !contextData.isTrial )
                        ? 
                            <div className="bg-gradient-to-r from-indigo-400 to-indigo-800 flex justify-center py-6 text-white text-lg gap-3 items-center">
                                <span>La vigencia de tu plan ha caducado. Renueva para seguir disfrutando de Politicsoft.</span>
                                    <NavLink className="" to="/suscripcion" >
                                        <div className="bg-gray-200 border rounded-md px-4 py-1 text-black">
                                            Ver suscripción
                                        </div>
                                    </NavLink>
                            </div>
                        :
                            null
                    }
                    {
                        ( days < 0 && !contextData.isOwnerAccount && !contextData.isTrial )
                        ? 
                            <div className="bg-gradient-to-r from-indigo-400 to-indigo-800 flex justify-center py-6 text-white text-lg gap-3 items-center">
                                <span>La vigencia del plan ha caducado. Comunicate con el administrador de la cuenta para seguir disfrutando de Politicsoft.</span>
                                    <NavLink className="" to="/suscripcion" >
                                        <div className="bg-gray-200 border rounded-md px-4 py-1 text-black">
                                            Ver suscripción
                                        </div>
                                    </NavLink>
                            </div>
                        :
                            null
                    }
                    {
                        ( days < 0 && !contextData.isOwnerAccount && contextData.isTrial )
                        ? 
                            <div className="bg-gradient-to-r from-indigo-400 to-indigo-800 flex justify-center py-6 text-white text-lg gap-3 items-center">
                                <span>La prueba gratuita ha expirado. Comunicate con el administrador de la cuenta para seguir disfrutando de Politicsoft.</span>
                                    <NavLink className="" to="/suscripcion" >
                                        <div className="bg-gray-200 border rounded-md px-4 py-1 text-black">
                                            Ver suscripción
                                        </div>
                                    </NavLink>
                            </div>
                        :
                            null
                    }
                    <div className="sticky top-0 z-40 flex py-10 h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                        <button
                            type="button"
                            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        {/* Separator */}
                        <div
                            className="h-6 w-px bg-gray-900/10 lg:hidden"
                            aria-hidden="true"
                        />
                
                        <div className="flex flex-1 flex-row-reverse gap-x-4 self-stretch lg:gap-x-6">
                            {/* <form
                                className="relative flex flex-1"
                                action="#"
                                method="GET"
                            >
                                <label
                                    htmlFor="search-field"
                                    className="sr-only"
                                >
                                    Search
                                </label>
                                <MagnifyingGlassIcon
                                    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                                <input
                                    id="search-field"
                                    className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                    placeholder="Search..."
                                    type="search"
                                    name="search"
                                />
                            </form> */}
                            <div className="flex items-center gap-x-4 lg:gap-x-6">
                                {/* <button
                                    type="button"
                                    className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                                >
                                    <span className="sr-only">
                                        View notifications
                                    </span>
                                    <BellIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </button> */}

                                {/* Separator */}
                                <div
                                    className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                                    aria-hidden="true"
                                />

                                {/* Profile dropdown */}
                                <Menu as="div" className="relative">
                                    <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                        <span className="sr-only">
                                            Open user menu
                                        </span>
                                        <span
                                                className="lg:hidden ml-4 text-md leading-6 text-gray-900"
                                                aria-hidden="true"
                                            >
                                            { contextData.firstName }
                                        </span>
                                        <span className="hidden lg:flex lg:items-center">
                                            <span
                                                className="ml-4 text-md leading-6 text-gray-900"
                                                aria-hidden="true"
                                            >
                                                { contextData.firstName }
                                            </span>
                                            <ChevronDownIcon
                                                className="ml-2 h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                            {userNavigation.map((item) => (
                                                <Menu.Item key={item.name}>
                                                    {({ active }) => (
                                                        <a
                                                            href={item.href}
                                                            className={classNames(
                                                                active
                                                                    ? "bg-gray-50"
                                                                    : "",
                                                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                                                            )}
                                                            onClick={item.function}
                                                        >
                                                            {item.name}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <main className="py-10">
                        <div className="">
                            { props.children }
                        </div>
                    </main>
                    
                </div>
            </div>
        </>
    );

}

export default DashboardContainer;