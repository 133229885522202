import { useState } from 'react';

type TValidationTypes = 'email' | 'text' | 'dropdown' | 'number' | 'mobile' | 'date' | 'file';

export interface IState {

    [ key: string ]: {
        
        value: string,
        file?: File | null,
        isValid: boolean

    };

}

export interface IValidationField {

    name: string,
    label: string
    type: TValidationTypes;

}

const useForm = ( initialState: IState ) => {

    const [ values, setValues ] = useState< IState >( initialState );
    const [ errors, setErrors ] = useState< string[] >( [] );

    const formChangeHandler = ( name: string, value: string ) => {

        const copyValues         = { ...values };
        copyValues[ name ].value = value;

        setValues( copyValues );

    }

    const fileChangeHandler = ( name: string, file: File | null ) => {
        const copyValues         = { ...values }
        copyValues[ name ].file  = file;
        copyValues[ name ].value = 'Archivo seleccionado.';
        setValues( copyValues );

    }

    const formValidationHandler = ( requiredFields: IValidationField[] ) => {

        let formErrors: string[] = [];
        let isValid: boolean     = true;

        for( let field of requiredFields ){

            if( field.type === 'email' ){

                const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
                const email      = values[ field.name ].value;

                if ( !emailRegex.test( values[ field.name ].value ) ) {

                    values[field.name].isValid = false;
                    formErrors.push(`${field.label} no es un correo electrónico válido.`);
                    isValid = false;

                }
                else
                    values[ field.name ].isValid = true;

            }
            else if( field.type === 'text' ){

                if( values[ field.name ].value === '' ){

                    values[field.name].isValid = false;
                    formErrors.push(`${field.label} es requerido.`);
                    isValid = false;

                }
                else
                    values[ field.name ].isValid = true;

            }
            else if( field.type === 'dropdown' ){

                if( values[ field.name ].value === '-1' || values[ field.name ].value === '' ){

                    values[field.name].isValid = false;
                    formErrors.push(`${field.label} es requerido.`);
                    isValid = false;

                }
                else
                    values[ field.name ].isValid = true;

            }
            else if( field.type === 'number' ){

                const numberRegex = /^\d+$/;
        
                if ( !numberRegex.test( values[ field.name ].value ) ) {
        
                    values[field.name].isValid = false;
                    formErrors.push(`${field.label} solo debe contener números.`);
                    isValid = false;
        
                }
                else
                    values[ field.name ].isValid = true;
        
            }
            else if( field.type === 'mobile' ){

                const numberRegex = /^\d{10}$/;
        
                if ( !numberRegex.test( values[ field.name ].value ) ) {
        
                    values[field.name].isValid = false;
                    formErrors.push(`${field.label} el número es invalido.`);
                    isValid = false;
        
                }
                else
                    values[ field.name ].isValid = true;
        
            }
            else if( field.type === 'date' ){

                const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

                if ( !dateRegex.test( values[ field.name ].value ) ) {
            
                    values[field.name].isValid = false;
                    formErrors.push(`${field.label} la fecha es inválida.`);
                    isValid = false;
            
                }
                else
                    values[ field.name ].isValid = true;

            }
            else if( field.type === 'file' ){

                if( values[ field.name ].file === undefined || values[ field.name ].file === null ){

                    values[ field.name ].isValid = false;
                    formErrors.push(`${field.label} es requerido.`);
                    isValid = false;

                }
                else 
                    values[ field.name ].isValid = true;

            }

        }

        setErrors( formErrors );
        return isValid;

    }

    return {

        values,
        formChangeHandler,
        fileChangeHandler,
        formValidationHandler,
        errors,
        setErrors,
        setValues
        
    }

}


export default useForm;