import { useState } from 'react';
import http from '../Handlers/Http';
import { IContext } from '../Components/Context/Context';

const useAuthenticated = () => {

    const [ token, setToken ] = useState<string | null>( localStorage.getItem( 'token' ) || null );
    const [ contextData, setContextData ] = useState< IContext >({
        token: null,
        project: null,
        firstName: null,
        isOwner: null,
        isTrial: null,
        trialEnd: null,
        monthEnd: null,
        operators: null,
        isOwnerAccount: null
    });

    const login = async( email: string, password: string ) => {
            
        try{
            const response = await http.post( '/users/login/', { email, password } );
            localStorage.setItem( 'token', response.access );
            setToken( response.access );
        }
        catch( err ){
            localStorage.removeItem( 'token' );
            setToken( null );
            throw err;
        }

    }

    const logout = () => {

        localStorage.removeItem( 'token' );
        setToken( null );
        setContextData({

            token: null,
            project: null,
            firstName: null,
            isOwner: null,
            isTrial: null,
            trialEnd: null,
            monthEnd: null,
            operators: null,
            isOwnerAccount: null

        });

        window.location.href = '/';

    }

    const isValidUserAuthenticated = async() => {
        
        if( token !== null ){

            try{

                const isValidToken = await http.get( '/users/login/is-valid-token', token );
                return isValidToken.is_valid_token;

            }
            catch( err ){
                return false;
            }

        }
        else
            return false;

    }

    const isAuthenticated = async() => {

        try{

            if( token !== null ){

                const isValidToken = await http.get( '/users/login/is-valid-token', token );

                if( isValidToken.is_valid_token !== true ){
                    localStorage.removeItem( 'token' );
                    setToken( null );
                    setContextData({

                        token: null,
                        project: null,
                        firstName: null,
                        isOwner: null,
                        isTrial: null,
                        trialEnd: null,
                        monthEnd: null,
                        operators: null,
                        isOwnerAccount: null

                    });

                }
                else{

                    const response = await http.get( '/users/retrieve', token );

                    if( response.data.user.is_owner_account ){

                        setContextData({

                            token: token,
                            project: response.data.id || response.data.user.account,
                            firstName: response.data.user.first_name,
                            isOwner: response.data.user.is_owner_account,
                            isTrial: response.data.is_trial || null ,
                            trialEnd: response.data.trial_end || null,
                            monthEnd: response.data.month_end || null,
                            operators: response.data.users_plan.max_users || null,
                            isOwnerAccount: response.data.user.is_owner_account || false
    
                        });

                    }
                    else{

                        setContextData({

                            token: token,
                            project: response.data.account.id,
                            firstName: response.data.user.first_name,
                            isOwner: response.data.user.is_owner_account,
                            isTrial: response.data.account.is_trial || null ,
                            trialEnd: response.data.account.trial_end || null,
                            monthEnd: response.data.account.month_end || null,
                            operators: null,
                            isOwnerAccount: response.data.user.is_owner_account
    
                        });

                    }

                }
            }
            
        }
        catch( err ){
            localStorage.removeItem( 'token' );
            setToken( null );
        }

    }

    return {

        token,
        login,
        isAuthenticated,
        contextData,
        isValidUserAuthenticated,
        logout

    }

}

export default useAuthenticated;