import {
    CheckIcon
} from '@heroicons/react/20/solid';
import { useContext, useEffect, useState } from "react";
import Tabs from "../../../Components/Dashboard/Tabs/Tabs";
import FullWidthContainer from "../../../Containers/FullWidthContainer/FullWidthContainer";
import useHttp from "../../../CustomHooks/useHttp";
import Context from "../../../Components/Context/Context";
import Loader from "../../../Components/Loader/Loader";
import { createdAtFormatDate } from '../../../Utils/dates';

interface IPlan {

    id: string;
    title: string;
    max_users: number;
    storage: number;
    price: string;
    stripe_link: string;

}

interface ITab {

    name: string;
    path: string;
    isLink: boolean;

}

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

const Suscription = () => {

    // Context
    const contextData = useContext( Context );
    // FetchAPI customHook
    const { fetchAPI } = useHttp();

    // States required for the UI
    const [ openFullScreenLoader, setOpenFullScreenLoader ]   = useState< boolean >( false );
    const [ plan, setPlan ] = useState< IPlan | null >( null );
    const [ features, setFeatures ] = useState< string[] >( [] );
    const [ tabs, setTabs ] = useState< ITab[] >( [] );

    useEffect(()=>{

        

        const getActivePlan = async() => {

            const projectId = contextData.project;

            if( contextData.token !== null ){
                
                try{

                    const response = await fetchAPI( `/accounts/${projectId}/`, 'get', null, contextData.token );
                    const users_plan: IPlan = response.users_plan;

                    setOpenFullScreenLoader( false );
                    
                    let aditionalUsers = '';

                    if( users_plan.max_users === 1 ){
                        aditionalUsers = '1 Usuario adicional';
                    }
                    else{
                        aditionalUsers = users_plan.max_users + ' Usuarios adicionales';
                    }

                    const features = [
                
                        aditionalUsers,
                        users_plan.storage+' GB de almacenamiento (Archivos).',
                        'Calendario de eventos.',
                        'Tareas logísticas.',
                        'Categorización.',
                        'Perfiles de Campaña/Gobierno.',
                        'Objetivos de Campaña/Gobierno.',
                        'Inteligencia Artificial.'
                
                    ];

                    const tabsOptions = [
                        {
                            name: "Mi perfil",
                            path: '/mi-cuenta',
                            isLink: true
                        }
                    ];

                    if( contextData.isOwnerAccount )
                        tabsOptions.push(
                            {
                                name: 'Mi suscripción',
                                path: '/suscripcion',
                                isLink: false
                            },
                            {
                                name: 'Politicoins',
                                path: '/politicoins',
                                isLink: true
                            }
                        );
                    
                    setTabs( tabsOptions );
                    setPlan( users_plan );
                    setFeatures( features );

                }
                catch( err ){
                }

            }

        }

        if( plan === null && contextData.project !== null ){

            setOpenFullScreenLoader( true );
            getActivePlan();

        }

    },[]);

    const createPaymentHandler = async () => {

        setOpenFullScreenLoader( true );

        try{

            const accountId = contextData.project;

            if( contextData.token !== null ){

                const paymentsResponse = await fetchAPI( `/payments/${accountId}/?search=is_completed=False`, 'get', null, contextData.token );

                if( paymentsResponse.status === 200 ){

                    const elements = paymentsResponse.data.elements;
                    
                    // Create payment
                    if( elements.length === 0 ){

                        const newElement = {

                            account: accountId,
                            title: plan?.title,
                            amount: plan?.price,
                            is_completed: false

                        }

                        const response = await fetchAPI( `/payments/${accountId}/`, 'post', newElement, contextData.token );

                        const stripeLink = plan?.stripe_link;

                        if( stripeLink !== undefined ){
                            window.location.href = stripeLink;
                        }

                    }
                    else{

                        const stripeLink = plan?.stripe_link;

                        if( stripeLink !== undefined ){
                            window.location.href = stripeLink;
                        }

                    }

                }
                else{
                    setOpenFullScreenLoader( false );
                }

            }
            //const response = await fetchAPI( `/payments/${account_id}/`, 'post', newPayment, contextData.token );

        }
        catch( err ){
            setOpenFullScreenLoader( false );
        }

    }

    let monthEnd = '';

    if( ( contextData.monthEnd !== null ) )
        monthEnd = String(contextData?.monthEnd);
        
    return (

        <FullWidthContainer>
            <Tabs items={ tabs } />
            <div className="px-4 py-6 sm:px-6 lg:px-8">
                <h2 className="font-bold text-3xl mb-1">Mi suscripción</h2>
                <h3 className="mb-8 text-gray-500">Plan vigente hasta el { createdAtFormatDate( monthEnd ) }</h3>
                <div className='w-full sm:w-11/12 md:w-8/12 xl:w-5/12 ring-1 ring-gray-200 rounded-3xl p-8 xl:p-10'>
                {
                    ( plan === null )
                        ? 
                            null
                        : 
                        <>
                            <div className="w-full py-4">
                                <div className="flex flex-col">
                                    <p className="font-semibold text-2xl">{plan.title}</p>
                                    <p className="text-indigo-600 text-lg">Plan actual.</p>
                                    
                                    <p className='mt-6 flex items-baseline gap-x-1'>
                                        <span className='text-4xl font-bold tracking-tight text-gray-900'>
                                            ${plan.price}
                                        </span>
                                        <span className='text-sm font-semibold leading-6 text-gray-600'>
                                            pesos + IVA
                                        </span>
                                    </p>
                                        <span
                                            aria-describedby={plan.id}
                                            className={classNames(
                                                'bg-indigo-600 text-white shadow-sm hover:bg-indigo-600 cursor-pointer',
                                                'mt-6 block rounded-md py-2 px-3 text-center text-sm leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                            )}
                                            onClick={ createPaymentHandler }
                                        >
                                            Extender 1 mes
                                        </span>
                                    <ul
                                        role='list'
                                        className='mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10'
                                    >
                                        {features.map((feature: any) => (
                                            <li
                                                key={feature}
                                                className='flex gap-x-3'
                                            >
                                                <CheckIcon
                                                    className='h-6 w-5 flex-none text-indigo-600'
                                                    aria-hidden='true'
                                                />
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>

                                </div>
                            </div>
                        </>
                    }
            </div>
        </div>
        <Loader fullScreen={ true } open={ openFullScreenLoader } />
        </FullWidthContainer>

    )

}

export default Suscription;