import 'moment/locale/es';
import 'moment-timezone';
import Moment from 'moment';

type TCreateFormatTypes = 'YYYY-MM-DD';

export const createdAtFormatDate = ( date: string ) => {
    
    let dateTime           = Moment( date );
    let mexicoCityTimeZone = dateTime.tz( 'America/Mexico_City' ).format( 'D MMM YYYY' );
    return mexicoCityTimeZone;

}

export const createMomentDate = ( date: string, format: TCreateFormatTypes ) => Moment( date, format ).format( format ); 

export const daysUntilDate = ( date: Date, format: TCreateFormatTypes ) => {

    const now        = Moment();
    const targetDate = Moment( date, format );
    const diffDays   = targetDate.diff( now, 'days' );

    return diffDays;

}