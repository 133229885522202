import useForm, { IValidationField } from '../../CustomHooks/useForm';
import { NavLink } from 'react-router-dom';

import FullWidthWithPaddingContainer from '../../Containers/FullWidthWithPaddingContainer/FullWidthWithPaddingContainer';
import Input from '../../Components/Forms/Input';
import Errors from '../../Components/Errors/Errors';
import politicsoft from '../../Images/logo.svg';

interface IProps {
    auth: Function;
}

const Login = (props: IProps) => {
    const auth = props.auth;

    const {
        values,
        formChangeHandler,
        formValidationHandler,
        errors,
        setErrors,
    } = useForm({
        email: {
            value: '',
            isValid: true,
        },
        password: {
            value: '',
            isValid: true,
        },
    });

    const loginHandler = async () => {
        const requiredFields: IValidationField[] = [
            {
                name: 'email',
                label: 'Email',
                type: 'email',
            },
            {
                name: 'password',
                label: 'Contraseña',
                type: 'text',
            },
        ];

        if (formValidationHandler(requiredFields)) {
            try {
                await auth(values.email.value, values.password.value);
            } catch (error: any) {
                setErrors([error.response.data.message]);
            }
        }
    };

    return (
        <FullWidthWithPaddingContainer>
            <div className='flex flex-col justify-center min-h-screen'>
                <div className='border-2 mx-auto p-8 rounded-md w-full md:w-8/12 lg:w-5/12'>
                    <form>
                        <img
                            className='mb-8'
                            alt='Logo de Politicsoft'
                            src={politicsoft}
                        />
                        <h2 className='text-2xl font-semibold'>
                            Inicia sesión en Politicsoft
                        </h2>
                        <p className='text-gray-500 mb-3 text-sm'>
                            Ingresa tus datos para iniciar sesión.
                        </p>
                        <Errors
                            title='Errores de Inicio de Sesión'
                            errors={errors}
                        />
                        <Input
                            type='email'
                            name='email'
                            label='Email'
                            placeholder='Email'
                            state={values.email.value}
                            isValid={values.email.isValid}
                            formChangeHandler={(evt) =>
                                formChangeHandler('email', evt.target.value)
                            }
                        />
                        <Input
                            type='password'
                            name='password'
                            label='Contraseña'
                            placeholder='Contraseña'
                            state={values.password.value}
                            isValid={values.password.isValid}
                            formChangeHandler={(evt) =>
                                formChangeHandler('password', evt.target.value)
                            }
                        />
                        {/* <NavLink
                            to='/recueperar-password'
                            className='text-indigo-600 text-sm'
                        >
                            ¿Olvidaste tu contraseña?
                        </NavLink> */}
                        <button
                            onClick={loginHandler}
                            type='button'
                            className='mt-6 text-center w-full rounded-md bg-indigo-600 p-2 text-sm text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                        >
                            Ingresar
                        </button>
                        <p className='mt-8 mb-4 text-center text-gray-500 text-sm'>
                            O si no tienes cuenta
                        </p>
                        <NavLink
                            to='/registrate'
                            type='button'
                            className='text-center w-full rounded-md border-2 p-2 text-sm shadow-sm'
                        >
                            Regístrate
                        </NavLink>
                    </form>
                </div>
                {/* <div className='w-full text-center mx-auto md:w-5/12'>
                    <p className='mt-5 text-sm text-gray-500'>
                        ¿Necesitas ayuda?
                        <span className='text-indigo-600'>
                            <NavLink to='/contacto'> Ingresa aquí</NavLink>
                        </span>
                    </p>
                </div> */}
            </div>
        </FullWidthWithPaddingContainer>
    );
};

export default Login;
