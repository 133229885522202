import Http from '../Handlers/Http';
import useAuthenticated from './useAuthenticated';

type TMethods = 'get' | 'post' | 'upload' | 'put' | 'patch' | 'delete';

const useHttp = () => {

    const { isValidUserAuthenticated, logout } = useAuthenticated();

    // Fetch the data from the API
    const fetchAPI = async( path: string, method: TMethods, body?: Object | null, token?: string ) => {

        let response = null;

        try{

            if( token !== undefined && token !== null ){
                if( !await isValidUserAuthenticated() )
                    logout();
            }

            // Get the response from the API
            if( method == 'get' )
                response = await Http.get( path, token );
            else if( method == 'post' )
                response = await Http.post( path, body, token );
            else if( method == 'upload' )
                response = await Http.upload( path, body, token );
            else if( method == 'put' )
                response = await Http.put( path, body, token );
            else if( method == 'patch' )
                response = await Http.patch( path, body, token );
            else if( method == 'delete' )
                response = await Http.delete( path, token );

            return response;

        }
        catch( err ){
            throw err;
        }
    }

    return {
        fetchAPI
    }

}

export default useHttp